import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  CheckboxGroup,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import DatePicker from 'component-library/components/date-picker/date-picker';
import { useState } from 'react';
import { storeFilter } from 'redux/slices/exemptions';
import { useAppDispatch } from 'redux/store';

export const ExemptionFilter = ({ onClose, filters }: any) => {
  const dispatch = useAppDispatch();
  const [accordionIndex, setAccordionIndex] = useState<number | number[]>([]);

  const [filter, setFilter] = useState<{
    status__in?: string;
    start_date?: string;
    end_date?: string;
  }>({ status__in: filters?.status__in, start_date: filters.start_date, end_date: filters.end_date });

  const handleSave = () => {
    dispatch(storeFilter({ ...filter }));
    onClose();
  };

  const handleClear = () => {
    setFilter({});
    dispatch(storeFilter({}));
    onClose();
  };

  const nofilter = !filter;

  return (
    <Stack spacing={'16px'}>
      <HStack justifyContent={'space-between'}>
        <Text fontSize={'18px'} fontWeight={500}>
          Filter by
        </Text>
        <Text fontSize={'12px'} color={'#4285F4'} cursor={'pointer'} onClick={handleClear}>
          Clear All Filters
        </Text>
      </HStack>
      <Accordion allowMultiple variant={'filter'} index={accordionIndex} onChange={index => setAccordionIndex(index)}>
        <AccordionItem>
          <AccordionButton>
            <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
              Date
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel gap={2}>
            <Box>
              <Text fontWeight={'medium'} fontSize={'sm'} mb={1}>
                Start Date
              </Text>
              <DatePicker
                placeholderText="Select"
                selected={filter.start_date}
                onChange={date => {
                  setFilter({
                    ...filter,
                    start_date: date,
                  });
                }}
              />
            </Box>
            <Box width={'100%'}>
              <Text fontWeight={'medium'} fontSize={'sm'} mb={1}>
                End Date
              </Text>
              <DatePicker
                placeholderText="Select"
                selected={filter.end_date}
                onChange={date => {
                  setFilter({
                    ...filter,
                    end_date: date,
                  });
                }}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <Stack>
          <AccordionItem>
            <AccordionButton>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Status
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Stack>
                <CheckboxGroup
                  value={filter.status__in?.split(',').filter(Boolean)}
                  onChange={values => {
                    setFilter({
                      ...filter,
                      status__in: values.join(','),
                    });
                  }}
                >
                  <Checkbox value="ACTIVE">Active</Checkbox>
                  <Checkbox value="INACTIVE">Inactive</Checkbox>
                  <Checkbox value="EXPIRED">Expired</Checkbox>
                </CheckboxGroup>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Stack>
      </Accordion>
      <HStack direction="row" justifyContent={'space-around'} spacing="18px">
        <Button variant="outline" color="secondary" onClick={onClose} width={'132px'} height={'32px'}>
          Cancel
        </Button>
        <Button
          variant="solid"
          color="primary"
          onClick={handleSave}
          width={'132px'}
          height={'32px'}
          isDisabled={nofilter}
        >
          Apply
        </Button>
      </HStack>
    </Stack>
  );
};
