import { Box, Popover, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import KSearchInput from 'components/SearchInput';
import { useEffect, useState } from 'react';
import { KCustomSelectProps } from 'types/kCustomSelectType';

import { useCustomSelect } from '../../hooks/useCustomSelect';
import { KCustomSelectOptions } from './KCustomSelectOptions';
import { MainInput } from './MainInput';

/**
 * Custom select dropdown component
 * @param name Name of the input field
 * @param placeholder Placeholder of the drop-down field
 * @param items (Options) items should be array of objects
 * @param itemTitle Key to display the option name
 * @param itemValue Key to select the value
 * @param value Selected option value
 * @param onChange function that handle the change option
 * @param searchBar  Default true, means searchBar visible in dropdown
 * @param isExternalSearch If false, data will be filtered with items data, if true data will be filtered from external source like-API data
 * @param searchQuery search query value
 * @param onChangeSearchQuery  function that handle the search query
 * @param renderItemContent  render customize option from parents
 * @param isDisabled  disabled the dropdown
 * @param isMatchWidth  if true the dropdown width will be same as options popover width
 */

const KCustomSelect = ({
  placeholder = 'Select',
  name,
  items,
  itemTitle,
  itemValue,
  value,
  onChange,
  // returnItem, // Return the selected item
  searchBar = true,
  isExternalSearch = false,
  searchQuery = '',
  onChangeSearchQuery,
  renderItemContent,
  isLoading = false,
  isDisabled = false,
  isMatchWidth = true,
  popoverContentStyle,
  ...rest
}: KCustomSelectProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [internalItems, setInternalItems] = useState<any[]>(items);
  const [internalSearchQuery, setInternalSearchQuery] = useState<string>(searchQuery);
  const { getTitle, getTitleFromValue, getValue, isItemSelected } = useCustomSelect({
    items,
    itemTitle,
    itemValue,
    value,
  });

  useEffect(() => {
    setInternalItems(items);
  }, [items]);

  const handleChangeOption = (item: any) => {
    if (onChange) {
      const value = getValue(item);
      onChange(value);
      onClose(); // Close the dropdown
    }
  };

  const onChangeQuery = (query: string) => {
    if (isExternalSearch && onChangeSearchQuery) {
      return onChangeSearchQuery(query);
    }
    if (!query) {
      return setInternalItems(items);
    }

    setInternalSearchQuery(query);
    setInternalItems(filterItemsByQuery(query));
  };

  const filterItemsByQuery = (query: string) => {
    if (!query || !internalItems?.length) {
      return internalItems;
    }

    return internalItems.filter(item => {
      const isObject = typeof item === 'object';

      if (isObject) {
        return item[itemTitle]?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      }
      return item?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  };

  return (
    <Box position="relative" w="100%">
      {/* MainInput display as dropdown */}
      <MainInput
        name={name}
        onOpen={onOpen}
        title={getTitleFromValue()}
        placeholder={placeholder}
        isOpen={isOpen}
        isLoading={isLoading}
        isDisabled={isDisabled}
        {...rest}
      />
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom-start"
        trigger="click"
        offset={[0, 0]}
        matchWidth={isMatchWidth}
        strategy="fixed"
        returnFocusOnClose={false}
      >
        <PopoverTrigger>
          <Box />
        </PopoverTrigger>

        <PopoverContent
          p={2}
          color="#262B47"
          bg="#FFFFFF"
          border="1px solid"
          borderColor="#E3E8EC"
          borderRadius="3"
          filter="drop-shadow(0px 8px 20px -4px rgba(23, 24, 24, 0.12))"
          width={'inherit'}
          height={'414px'}
          overflow={'auto'}
          {...popoverContentStyle}
        >
          <>
            {searchBar && (
              <KSearchInput query={isExternalSearch ? searchQuery : internalSearchQuery} setQuery={onChangeQuery} />
            )}
            <Box py={1} />
            <KCustomSelectOptions
              internalItems={internalItems}
              itemValue={itemValue}
              isItemSelected={isItemSelected}
              handleChangeOption={handleChangeOption}
              getTitle={getTitle}
              renderItemContent={renderItemContent}
            />
          </>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default KCustomSelect;
