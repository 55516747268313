// TO DO migrate to ts
import { useState } from 'react';

const useAsyncActionLoader = (asyncFn, initialState = false) => {
  const [loading, setLoading] = useState(initialState);
  const [error, setError] = useState(null);

  const executeAction = async (...args) => {
    try {
      setLoading(true);
      setError(null);

      const result = await asyncFn(...args);

      return result;
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, executeAction };
};

export default useAsyncActionLoader;
