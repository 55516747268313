import Button from 'component-library/components/ButtonTmp/button';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSearchParams } from 'react-router-dom';

const FilingHistoryButton = () => {
  const [, setSearchParams] = useSearchParams();
  useHotkeys('ctrl+h', () => setSearchParams({ sfh: 'true' }), []);
  return (
    <Tooltip shouldWrapChildren={true} label={'View Filing History'}>
      <Button
        width={'100px'}
        aria-label={'Filings history'}
        variant={'solid'}
        color={'secondary'}
        onClick={() => {
          setSearchParams({ sfh: 'true' });
        }}
      >
        History
      </Button>
    </Tooltip>
  );
};

export default FilingHistoryButton;
