import { CircularProgress, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

const AnimatedLogo = styled('div')(() => ({
  height: '50vh',
  width: '50vw',
  position: 'fixed',
  margin: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const AppLoader = ({ pastDelay = true }) => {
  if (pastDelay) {
    return (
      <AnimatedLogo>
        <Flex direction="column" gap={10} alignItems={'center'}>
          <CircularProgress isIndeterminate color="primary.600" />
        </Flex>
      </AnimatedLogo>
    );
  }
  return null;
};

export default AppLoader;
