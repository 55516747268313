import React, { forwardRef } from 'react';

import Select, { SelectProps } from './Select';

type KS_SelectProps = SelectProps & {
  options: { value: string; label: string }[];
};

const KS_SelectDropDown = ({ options, ...rest }: KS_SelectProps, ref: any) => {
  return (
    <Select ref={ref} {...rest}>
      {options.map(({ label, value }, i) => {
        return (
          <option key={i} value={value}>
            {label}
          </option>
        );
      })}
    </Select>
  );
};

export default forwardRef(KS_SelectDropDown);
