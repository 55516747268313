import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useTracking from './useTracking';

const usePageTracking = () => {
  const location = useLocation();
  const { pageLoad } = useTracking();

  useEffect(() => {
    pageLoad();
  }, [location]);
};

export default usePageTracking;
