/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttachmentRead } from '../models/AttachmentRead';
import type { Body_upload_exemption_certificate_v1_exemptions__exemption_id__attachments_post } from '../models/Body_upload_exemption_certificate_v1_exemptions__exemption_id__attachments_post';
import type { ExemptionCreate } from '../models/ExemptionCreate';
import type { ExemptionRead } from '../models/ExemptionRead';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ExemptionsService {
    /**
     * Get Exemptions
     * @returns ExemptionRead Successful Response
     * @throws ApiError
     */
    public static getExemptionsV1ExemptionsGet({
        xOrganizationId,
        statusIn = 'ACTIVE,INACTIVE,EXPIRED',
        startDate,
        endDate,
        customerId,
        orderBy = 'end_date,FEIN,sales_tax_id,status',
    }: {
        xOrganizationId: string,
        statusIn?: string,
        startDate?: string,
        endDate?: string,
        customerId?: string,
        orderBy?: string,
    }): CancelablePromise<Array<ExemptionRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/exemptions',
            headers: {
                'x-organization-id': xOrganizationId,
            },
            query: {
                'status__in': statusIn,
                'start_date': startDate,
                'end_date': endDate,
                'customer_id': customerId,
                'order_by': orderBy,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Exemption
     * @returns ExemptionRead Successful Response
     * @throws ApiError
     */
    public static createExemptionV1ExemptionsPost({
        xOrganizationId,
        requestBody,
    }: {
        xOrganizationId: string,
        requestBody: ExemptionCreate,
    }): CancelablePromise<ExemptionRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/exemptions',
            headers: {
                'x-organization-id': xOrganizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Exemption By Id
     * @returns ExemptionRead Successful Response
     * @throws ApiError
     */
    public static getExemptionByIdV1ExemptionsExemptionIdGet({
        exemptionId,
        xOrganizationId,
    }: {
        exemptionId: string,
        xOrganizationId: string,
    }): CancelablePromise<ExemptionRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/exemptions/{exemption_id}',
            path: {
                'exemption_id': exemptionId,
            },
            headers: {
                'x-organization-id': xOrganizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Attachments For Exemption
     * @returns AttachmentRead Successful Response
     * @throws ApiError
     */
    public static getAttachmentsForExemptionV1ExemptionsExemptionIdAttachmentsGet({
        exemptionId,
        xOrganizationId,
    }: {
        exemptionId: string,
        xOrganizationId: string,
    }): CancelablePromise<Array<AttachmentRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/exemptions/{exemption_id}/attachments',
            path: {
                'exemption_id': exemptionId,
            },
            headers: {
                'x-organization-id': xOrganizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Upload Exemption Certificate
     * @returns AttachmentRead Successful Response
     * @throws ApiError
     */
    public static uploadExemptionCertificateV1ExemptionsExemptionIdAttachmentsPost({
        exemptionId,
        xOrganizationId,
        formData,
    }: {
        exemptionId: string,
        xOrganizationId: string,
        formData: Body_upload_exemption_certificate_v1_exemptions__exemption_id__attachments_post,
    }): CancelablePromise<AttachmentRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/exemptions/{exemption_id}/attachments',
            path: {
                'exemption_id': exemptionId,
            },
            headers: {
                'x-organization-id': xOrganizationId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
