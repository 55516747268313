import { Flex, Skeleton, Stack, Text, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { CUSTOMERS_STATE_KEY, useGetCustomerById, useGetTransactionsById } from 'apis/customers';
import { getExemptionsForCustomer } from 'apis/exemption-apis';
import TableContainer from 'component-library/components/Table/table-container';
import { useOrg } from 'hooks/useOrg';
import { useParams } from 'react-router-dom';

import { CreateExemption } from './add-exemptions';
import { CustomerTable } from './customer-details/customer-info';
import { ExemptionTable } from './customer-details/exemptions';
import { TransactionTable } from './customer-details/transactions';

export const CustomerDetails = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { orgId } = useOrg();
  const { data: customerData, isPending: isCustomerDataPending } = useGetCustomerById(orgId, id);
  const { data: transactionData, isPending: isTransactionsDataPending } = useGetTransactionsById(orgId, id);

  const { data: customerExemptions, isPending: isExemptionsDataPending } = useQuery({
    queryKey: [CUSTOMERS_STATE_KEY, orgId, id, 'exemptions'],
    queryFn: async () => {
      return getExemptionsForCustomer(orgId, id);
    },
  });
  const isExemptionEmpty = !customerExemptions || customerExemptions.length === 0;

  if (isCustomerDataPending || isExemptionsDataPending || isTransactionsDataPending) {
    return (
      <VStack spacing={4}>
        {Array.from({ length: 10 }, (_, index) => (
          <Skeleton key={index} height="36px" width="full" />
        ))}
      </VStack>
    );
  }
  return (
    <Stack gap={4}>
      <CustomerTable data={customerData} />
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontWeight={500} fontSize={'16px'}>
          Exemptions
        </Text>
        <CreateExemption />
      </Flex>
      {isExemptionEmpty ? (
        <TableContainer display="flex" h={'154px'} justifyContent={'center'} alignItems={'center'}>
          <Text color={'#4B5169'}>Customer does not have exemption data added.</Text>
        </TableContainer>
      ) : (
        <ExemptionTable
          captions={['JURISDICTION', 'START DATE', 'END DATE', 'FEIN', 'SALES TAX ID', 'STATUS']}
          data={customerExemptions}
        />
      )}
      <Text fontWeight={500} fontSize={'16px'}>
        Transactions
      </Text>
      <TransactionTable
        captions={['ID', 'DESCRIPTION', 'DATE', 'State', 'AMOUNT', 'TAX AMOUNT', 'Status']}
        data={transactionData}
      />
    </Stack>
  );
};
