import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createRegistrationApi, getRegistrations, updateRegistrationApi } from 'apis/registration-apis';
import { RegistrationInstance } from 'types/shared-types';

const registrationsAdapter = createEntityAdapter<RegistrationInstance>();

const initialState = registrationsAdapter.getInitialState({
  loading: false,
  activePage: {
    pages: 0,
  },
});

export const fetchRegistrations = createAsyncThunk('registrations/fetchRegistrations', async (params: any) => {
  const response = await getRegistrations(params.orgId, params.page, params.size);
  return response?.data;
});

export const createRegistration = createAsyncThunk('registrations/createRegistrations', async (params: any) => {
  const response = await createRegistrationApi(params.orgId, params.values);
  return response?.data;
});

export const updateRegistration = createAsyncThunk('registrations/updateRegistration', async (params: any) => {
  const response = await updateRegistrationApi(params.id, params.orgId, params.values);
  return response?.data;
});

const registrationsSlice = createSlice({
  name: 'registrations',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRegistrations.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.items.length) {
          registrationsAdapter.upsertMany(state, payload.items);
          state.activePage = { ...payload, ids: payload.items.map(({ id }: any) => id) };
        }
      })
      .addCase(updateRegistration.fulfilled, (state, { payload }) => {
        if (payload) {
          registrationsAdapter.upsertOne(state, payload);
        }
      })
      .addCase(createRegistration.fulfilled, (state, { payload }) => {
        if (payload) {
          registrationsAdapter.upsertOne(state, payload);
        }
      });
  },
});

export default registrationsSlice.reducer;
