import { Badge } from '@chakra-ui/react';
import { ExemptionStatus } from 'types/shared-types';
import { Maybe } from 'types/utils';

type Props = {
  status: Maybe<string>;
};

export default function ExemptionBadge({ status }: Props) {
  if (status === ExemptionStatus.ACTIVE) {
    return <Badge variant={'green'}>Active</Badge>;
  }
  if (status === ExemptionStatus.INACTIVE) {
    return <Badge variant={'grey'}>Inactive</Badge>;
  }
  if (status === ExemptionStatus.EXPIRED) {
    return <Badge variant={'red'}>Expired</Badge>;
  }
  if (status === ExemptionStatus.DEACTIVATED) {
    return <Badge variant={'grey'}>Deactivated</Badge>;
  }
  if (status === ExemptionStatus.ARCHIVED) {
    return <Badge variant={'grey'}>Archived</Badge>;
  }
  return null;
}
