import { RegistrationInstance } from 'types/shared-types';

import api from './api';

const getNexus = (orgId: string): Promise<any> => {
  return api.get('/v1/nexus/', {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getNexusById = (nexusId: string, orgId: string): Promise<any> => {
  return api.get(`/v1/nexus/${nexusId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const registerNexus = (nexusId: string, orgId: string, payload: Partial<RegistrationInstance>) => {
  return api.put(`v1/nexus/${nexusId}/register`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

export { getNexus, getNexusById, registerNexus };
