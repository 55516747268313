import { loadChargebee } from 'chargebee-js';
import { OpenCheckoutOptions } from 'chargebee-js/types';

export const chargebee = async (isTestOrg: boolean) => {
  const site =
    isTestOrg || import.meta.env.DEV
      ? import.meta.env.VITE_CHARGEBEE_TEST_SITE
      : import.meta.env.VITE_CHARGEBEE_LIVE_SITE;
  const cbInstance = await loadChargebee({
    site,
    isItemsModel: true,
  });
  if (!cbInstance) {
    console.error('Chargebee instance not found');
    return null;
  }
  return cbInstance;
};

export type { OpenCheckoutOptions };
