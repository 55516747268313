import BigCommerceTab from './bigcommerce';
import ChargebeeTab from './chargebee';
import { HrisTab } from './hris';
import { DeelIcon } from './icons/deel';
import { GustoIcon } from './icons/gusto';
import { RipplingIcon } from './icons/rippling';
import QuickbooksTab from './quickbooks';
import ShopifyTab from './shopify';
import StripeTab from './stripe';

export const integrationTabs = [
  {
    title: 'All',
    cards: [
      <BigCommerceTab />,
      <ChargebeeTab />,
      <QuickbooksTab />,
      <StripeTab />,
      <ShopifyTab />,
      <HrisTab
        serviceId="deel"
        icon={<DeelIcon />}
        heading={'Deel'}
        subHeading={'Deel - Your forever people platform.'}
      />,
      <HrisTab
        serviceId="rippling"
        icon={<RipplingIcon />}
        heading={'Rippling'}
        subHeading={'Streamline HR and IT management.'}
      />,
      <HrisTab
        serviceId="gusto"
        icon={<GustoIcon />}
        heading={'Gusto'}
        subHeading={'Gusto simplifies team management.'}
      />,
    ],
  },
  {
    title: 'Billing',
    cards: [<ChargebeeTab />, <StripeTab />],
  },
  {
    title: 'E-commerce',
    cards: [<ShopifyTab />, <BigCommerceTab />],
  },
  {
    title: 'Accounting',
    cards: [<QuickbooksTab />],
  },
  {
    title: 'HRIS',
    cards: [
      <HrisTab
        serviceId="deel"
        icon={<DeelIcon />}
        heading={'Deel'}
        subHeading={'Deel - Your forever people platform.'}
      />,
      <HrisTab
        serviceId="rippling"
        icon={<RipplingIcon />}
        heading={'Rippling'}
        subHeading={'Streamline HR and IT management.'}
      />,
      <HrisTab
        serviceId="gusto"
        icon={<GustoIcon />}
        heading={'Gusto'}
        subHeading={'Gusto simplifies team management.'}
      />,
    ],
  },
];
