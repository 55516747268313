import { Customer } from '_api-client';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuList,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { CUSTOMERS_STATE_KEY, getCustomers } from 'apis/customers';
import MenuButton from 'component-library/components/Menu/MenuButton';
import { CloseIcon, SearchIcon } from 'components/icons';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useRef, useState } from 'react';
import { getHumanReadableString } from 'utils/enum-helpers';

type searchCustomerInputProps = {
  onCustomerSelect: (customer: any) => void;
};

export const SearchCustomerInput = ({ onCustomerSelect }: searchCustomerInputProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { orgId } = useOrg();
  const { handleFailNotification } = useHandleNotification();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef(null);

  const { data } = useQuery({
    queryKey: [CUSTOMERS_STATE_KEY, orgId],
    queryFn: async () => {
      try {
        const res = await getCustomers(orgId);
        return res.data;
      } catch (error) {
        handleFailNotification(error);
      }
    },
  });

  const customerData = data?.items || [];
  const filteredCustomerData = customerData?.filter(
    ({ name, email, id }: Customer) =>
      name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      id?.toString().includes(searchTerm)
  );

  useOutsideClick({
    ref: ref,
    handler: onClose,
  });

  return (
    <FormControl isRequired>
      <FormLabel htmlFor="customer">Customer</FormLabel>
      <Box ref={ref}>
        <Menu isOpen={isOpen} placement="bottom-start" defaultIsOpen closeOnBlur>
          <InputGroup>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <MenuButton visibility={'hidden'}></MenuButton>
            <Input
              placeholder={'Search'}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              onFocus={() => {
                onOpen();
              }}
            />
            <InputRightElement>
              {searchTerm && (
                <CloseIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSearchTerm('');
                    onCustomerSelect('');
                    onClose();
                  }}
                />
              )}
            </InputRightElement>
          </InputGroup>
          <MenuList width="620px" maxH="250px" overflowY="auto" mt="4px">
            <Table variant="unstyled">
              <Thead>
                <Tr style={{ borderBottom: 'none' }}>
                  <Th>ID</Th>
                  <Th>CUSTOMER NAME</Th>
                  <Th>Email</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredCustomerData.map((customer: Customer) => (
                  <Tr
                    key={customer.id}
                    onClick={() => {
                      setSearchTerm(customer.email ?? '');
                      onCustomerSelect(customer);
                      onClose();
                    }}
                    style={{ borderBottom: 'none', cursor: 'pointer' }}
                  >
                    <Td fontSize={'14px'}>{customer.id}</Td>
                    <Td fontSize={'14px'}>{customer.name}</Td>
                    <Td fontSize={'14px'}>{customer.email}</Td>
                    <Td fontSize={'14px'}>{getHumanReadableString(customer.status)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </MenuList>
        </Menu>
      </Box>
    </FormControl>
  );
};
