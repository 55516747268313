import { Input, InputGroup, InputGroupProps, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from 'components/icons';
import { debounce } from 'lodash';
import { useRef } from 'react';

type SearchInputProps = {
  placeholder?: string;
  query: string;
  setQuery: (query: string) => void;
  styles?: InputGroupProps;
};

const KSearchInput = ({ placeholder = 'Search', query, setQuery, styles }: SearchInputProps) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const handleQueryChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery((event.target as HTMLInputElement).value);
  }, 500);

  const handleEnter = debounce((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const inputValue = (event.target as HTMLInputElement).value;
      setQuery(inputValue);
    }
  }, 100);

  const handleQueryReset = () => {
    if (ref.current) {
      ref.current.value = '';
    }
    setQuery('');
  };

  return (
    <InputGroup {...styles}>
      <InputLeftElement>
        <SearchIcon />
      </InputLeftElement>
      <Input
        ref={ref}
        name="global-search-input"
        placeholder={placeholder}
        defaultValue={query}
        onKeyDown={handleEnter}
        onChange={handleQueryChange}
        required={false}
      />
      {!!query && (
        <InputRightElement>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={handleQueryReset} />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default KSearchInput;
