import { useDisclosure } from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import { PhysicalNexusModal } from 'pages/Presence/components/physical-nexus-modal';

export const PhysicalNexusToolbar = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant={'solid'} color={'primary'} width={'100px'} onClick={onOpen}>
        New
      </Button>
      {isOpen && <PhysicalNexusModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
};
