import { Box } from '@chakra-ui/react';
import { TaxDetailsFilterButton } from 'pages/TaxDetails/tax-details-filter-button';
import React from 'react';

export const TaxDetailsToolbar = () => {
  return (
    <Box>
      <TaxDetailsFilterButton />
    </Box>
  );
};
