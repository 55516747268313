import { Drawer, DrawerContent, Flex, IconButton, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { KintsugiLogo } from 'components/icons';
import { FiMenu } from 'react-icons/fi';

import { Sidebar } from '.';

export const MobileSubNav = ({ initialDrawerOpen }: { initialDrawerOpen: boolean }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: initialDrawerOpen,
  });

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={'space-between'}
    >
      <KintsugiLogo />
      <IconButton variant="outline" onClick={onOpen} aria-label="open menu" icon={<FiMenu />} />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerContent bg="transparent" boxShadow={'none'}>
          <Sidebar onClose={onClose} hideToggleBtn />
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
