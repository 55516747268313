import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  IconButton,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FILINGS_STATE_KEY, getFilingForId } from 'apis/filing-apis';
import { ArrowLeft } from 'components/icons';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';

import FilingDetails from './filing-details';

const FilingDetailsDrawer = () => {
  const [showFilingHistory, setSearchParams] = useQueryParam('fh');
  const [filingId] = useQueryParam('fid');
  const { orgId } = useOrg();
  const onClose = () => {
    setSearchParams({});
  };

  const { isPending, data, isError } = useQuery({
    enabled: !!showFilingHistory,
    queryKey: [FILINGS_STATE_KEY, filingId],
    queryFn: async () => {
      try {
        const res = await getFilingForId(orgId, filingId);
        return res.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
  });

  if (isError || !data) {
    return null;
  }

  return (
    <Drawer isOpen={showFilingHistory} onClose={onClose} size="md" placement="right">
      <DrawerContent>
        <DrawerHeader>
          <Flex gap={2} alignItems={'center'} justifyContent={'space-between'}>
            <Box>
              <IconButton rounded={'sm'} variant={'ghost'} aria-label="back-to-filings" onClick={onClose}>
                <ArrowLeft />
              </IconButton>
              Filing Details
            </Box>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {isPending ? (
            <VStack spacing={4}>
              {Array.from({ length: 3 }, (_, index) => (
                <Skeleton key={index} height="30px" width="full" />
              ))}
            </VStack>
          ) : (
            <FilingDetails data={data} />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default FilingDetailsDrawer;
