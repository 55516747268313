import { Colors } from '@chakra-ui/react';

export const colors: Colors = {
  gray: {
    50: '#EFEFF3',
    100: '#cfd0d8',
    200: '#b7bac5',
    300: '#a0a4b2',
    400: '#8a8e9f',
    500: '#74798d',
    600: '#5f647b',
    700: '#4b5169',
    800: '#383d58',
    900: '#262B47',
  },
  primary: {
    50: '#FFF8EC',
    100: '#FFE9C7',
    200: '#FFC976',
    300: '#FFC15D',
    400: '#FFB940',
    500: '#FFB000',
    600: '#DE9900',
    700: '#BE8200',
    800: '#805600',
    900: '#2E1C00',
  },
  secondary: {
    50: '#ECF3FE',
    100: '#D9E7FD',
    200: '#B3CEFB',
    300: '#8EB6F8',
    400: '#689DF6',
    500: '#4285F4',
    600: '#356AC3',
    700: '#285092',
    800: '#1A3562',
    900: '#070D18',
  },
  red: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },
  orange: {
    50: '#FFFAF0',
    100: '#FEEBCB',
    200: '#FBD38D',
    300: '#F6AD55',
    400: '#ED8936',
    500: '#DD6B20',
    600: '#C05621',
    700: '#9C4221',
    800: '#7B341E',
    900: '#652B19',
  },
  green: {
    50: '#F0FFF4',
    100: '#C6F6D5',
    200: '#9AE6B4',
    300: '#68D391',
    400: '#48BB78',
    500: '#38A169',
    600: '#25855A',
    700: '#276749',
    800: '#22543D',
    900: '#1C4532',
  },
  // TO DO: Add full set of colors commented as causing issue of transparent background
  // white: {
  //   // 50: '#FFFFFF',
  // },
  purple: {
    50: '#A373EB',
  },
};
