import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { useEffect } from 'react';

export const LiveDataSourceConnectModal = () => {
  const { orgId } = useOrg();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [, setSearchParams] = useQueryParam('switchOrg');
  const [liveSource, setLiveSource] = useQueryParam('liveSource');

  const handleClose = () => {
    onClose();
    setSearchParams({});
  };

  const handleClick = () => {
    handleClose();
    setSearchParams({ switchOrg: orgId });
  };

  useEffect(() => {
    if (!isOpen && liveSource) {
      onOpen();
    }
    () => {
      setLiveSource({});
    };
  }, [liveSource]);

  return (
    <ModalPopup
      isOpen={isOpen}
      onClose={handleClose}
      header={'Want to connect live data source?'}
      footer={
        <Flex gap={4}>
          <Button variant={'outline'} color={'secondary'} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant={'solid'} color={'primary'} onClick={handleClick}>
            Switch Organization
          </Button>
        </Flex>
      }
    >
      <Text>
        You are trying to connect a live data source. If you&apos;re ready to go live, switch to live organization from
        organization switcher.
      </Text>
    </ModalPopup>
  );
};
