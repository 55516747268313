import { InferType, mixed, object, string } from 'yup';

import { AddressStatus } from './shared-types';

export const customerResSchema = object().shape({
  name: string().nullable(),
  external_id: string().optional(),
  status: string().required(),
  email: string().email().nullable(),
  phone: string().nullable(),
  street_1: string().optional(),
  street_2: string().optional(),
  city: string().required('City is required'),
  county: string().nullable(),
  state: string().required('State is required'),
  postal_code: string().required('Postal code is required'),
  country: string().required('Country is required'),
  address_status: mixed<AddressStatus>().oneOf(Object.values(AddressStatus)).required(),
  id: string().required(),
});
export const customerUpdateSchema = customerResSchema.omit(['id']);
export type Customer = InferType<typeof customerResSchema>;
export type CustomerUpdate = InferType<typeof customerUpdateSchema>;
