import { Box } from '@chakra-ui/react';
import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

import AppLoader from './app-loader';

type AppLoaderWrapperContext = {
  isLoading: boolean;
  showLoader: () => void;
  hideLoader: () => void;
};

export const AppLoaderWrapperContext = createContext<AppLoaderWrapperContext>({
  isLoading: false,
  showLoader: () => {},
  hideLoader: () => {},
});
export const useAppLoaderWrapper = () => useContext(AppLoaderWrapperContext);

export const AppLoaderWrapperProvider = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(false);
  const showLoader = () => setIsLoading(true);
  const hideLoader = () => setIsLoading(false);
  const contextValue = useMemo(
    () => ({
      isLoading,
      showLoader,
      hideLoader,
    }),
    [isLoading]
  );
  return <AppLoaderWrapperContext.Provider value={contextValue}>{children}</AppLoaderWrapperContext.Provider>;
};

export const AppLoaderWrapper = React.memo(({ children }: PropsWithChildren) => {
  const { isLoading } = useAppLoaderWrapper();
  return (
    <>
      <Box visibility={isLoading ? 'hidden' : 'visible'}>{children}</Box>
      <AppLoader pastDelay={isLoading} />
    </>
  );
});
AppLoaderWrapper.displayName = 'AppLoaderWrapper';
