import { Box, Flex, List, ListItem, Text } from '@chakra-ui/react';
import { MdCheck } from 'react-icons/md';
import { KCustomSelectOptionsProps } from 'types/kCustomSelectType';

export const KCustomSelectOptions = ({
  internalItems,
  itemValue,
  isItemSelected,
  handleChangeOption,
  getTitle,
  renderItemContent,
}: KCustomSelectOptionsProps) => {
  return (
    <List>
      {internalItems?.length === 0 && (
        <Text textAlign={'center'} p={2} mt={4}>
          No items found
        </Text>
      )}
      {internalItems?.map(item => (
        <ListItem
          p={2}
          _hover={{ bgColor: '#EFEFF3' }}
          color={isItemSelected(item) ? '#4285F4' : '#262B47'}
          key={item[itemValue]}
          onClick={() => handleChangeOption(item)}
          role="group"
        >
          <Flex alignItems={'center'} gap={1}>
            <Box width={4} height={4}>
              {isItemSelected(item) ? <MdCheck fontSize={14} fontWeight={'bold'} /> : ''}
            </Box>
            {renderItemContent ? (
              renderItemContent({ title: getTitle(item), item, isSelected: isItemSelected(item) }) // display options from parent
            ) : (
              <Text color={isItemSelected(item) ? '#4285F4' : '#383D58'} display="block">
                {getTitle(item)}
              </Text>
            )}
          </Flex>
        </ListItem>
      ))}
    </List>
  );
};
