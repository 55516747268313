/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttachmentRead } from '../models/AttachmentRead';
import type { Body_upload_filing_attachment_v1_filings__filing_id__attachments_post } from '../models/Body_upload_filing_attachment_v1_filings__filing_id__attachments_post';
import type { FilingCreate } from '../models/FilingCreate';
import type { FilingDetailsRead } from '../models/FilingDetailsRead';
import type { FilingRead } from '../models/FilingRead';
import type { FilingUpdate } from '../models/FilingUpdate';
import type { Page_FilingRead_ } from '../models/Page_FilingRead_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FilingsService {
    /**
     * Create Filing
     * @returns FilingRead Successful Response
     * @throws ApiError
     */
    public static createFilingV1FilingsPost({
        xOrganizationId,
        requestBody,
    }: {
        xOrganizationId: string,
        requestBody: FilingCreate,
    }): CancelablePromise<FilingRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/filings/',
            headers: {
                'x-organization-id': xOrganizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Filings
     * @returns Page_FilingRead_ Successful Response
     * @throws ApiError
     */
    public static getFilingsV1FilingsGet({
        xOrganizationId,
        stateName,
        statusIn = 'FILED,PROCESSING,UNFILED',
        startDate,
        endDate,
        orderBy = 'start_date,end_date,amount,amount_due,status',
        page = 1,
        size = 50,
    }: {
        xOrganizationId: string,
        stateName?: string,
        statusIn?: string,
        startDate?: string,
        endDate?: string,
        orderBy?: string,
        page?: number,
        size?: number,
    }): CancelablePromise<Page_FilingRead_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/filings',
            headers: {
                'x-organization-id': xOrganizationId,
            },
            query: {
                'state_name': stateName,
                'status__in': statusIn,
                'start_date': startDate,
                'end_date': endDate,
                'order_by': orderBy,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Filing By Id
     * @returns FilingDetailsRead Successful Response
     * @throws ApiError
     */
    public static getFilingByIdV1FilingsFilingIdGet({
        filingId,
        xOrganizationId,
    }: {
        filingId: string,
        xOrganizationId: string,
    }): CancelablePromise<FilingDetailsRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/filings/{filing_id}',
            path: {
                'filing_id': filingId,
            },
            headers: {
                'x-organization-id': xOrganizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Filing
     * @returns FilingRead Successful Response
     * @throws ApiError
     */
    public static updateFilingV1FilingsFilingIdPut({
        filingId,
        xOrganizationId,
        requestBody,
    }: {
        filingId: string,
        xOrganizationId: string,
        requestBody: FilingUpdate,
    }): CancelablePromise<FilingRead> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/filings/{filing_id}',
            path: {
                'filing_id': filingId,
            },
            headers: {
                'x-organization-id': xOrganizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Filings By Registration Id
     * @returns Page_FilingRead_ Successful Response
     * @throws ApiError
     */
    public static getFilingsByRegistrationIdV1FilingsRegistrationRegistrationIdGet({
        registrationId,
        xOrganizationId,
        page = 1,
        size = 50,
    }: {
        registrationId: string,
        xOrganizationId: string,
        page?: number,
        size?: number,
    }): CancelablePromise<Page_FilingRead_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/filings/registration/{registration_id}',
            path: {
                'registration_id': registrationId,
            },
            headers: {
                'x-organization-id': xOrganizationId,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Export Filings
     * Export the filings report for the organization, and send an email to the user
     * with the report
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportFilingsV1FilingsReportsExportPost({
        xOrganizationId,
        unpaidOnly = false,
    }: {
        xOrganizationId: string,
        unpaidOnly?: boolean,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/filings/reports/export',
            headers: {
                'x-organization-id': xOrganizationId,
            },
            query: {
                'unpaid_only': unpaidOnly,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Filing Details Report By Id
     * Export the filings report for the organization, and send an email to the user
     * with the report
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getFilingDetailsReportByIdV1FilingsFilingIdReportsExportPost({
        filingId,
        xOrganizationId,
    }: {
        filingId: string,
        xOrganizationId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/filings/{filing_id}/reports/export',
            path: {
                'filing_id': filingId,
            },
            headers: {
                'x-organization-id': xOrganizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Upload Filing Attachment
     * @returns AttachmentRead Successful Response
     * @throws ApiError
     */
    public static uploadFilingAttachmentV1FilingsFilingIdAttachmentsPost({
        filingId,
        xOrganizationId,
        formData,
    }: {
        filingId: string,
        xOrganizationId: string,
        formData: Body_upload_filing_attachment_v1_filings__filing_id__attachments_post,
    }): CancelablePromise<AttachmentRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/filings/{filing_id}/attachments',
            path: {
                'filing_id': filingId,
            },
            headers: {
                'x-organization-id': xOrganizationId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
