import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { convertPxToRem } from '../foundations/typography';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    border: '1px solid rgba(207, 208, 216, 0.60)',
    background: 'var(--gray-0, #FFF)',
    borderRadius: '2px',
    paddingX: 2,
    paddingY: '6px',
    fontSize: convertPxToRem(14),
    lineHeight: '20px',
    _focus: {
      border: '1px solid var(--secondary-500, #4285F4)',
      color: '#4285F4',
    },
  },
  icon: {
    color: '#383D58',
    //TODO: icon color on focus
  },
});

export const selectTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    wordBreak: {
      field: {
        height: 'auto',
        whiteSpace: 'pre-wrap',
      },
    },
    ellipsis: {
      field: {
        height: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    spaced: {
      field: {
        border: '1px solid rgba(207, 208, 216, 0.60)',
        background: 'var(--gray-0, #FFF)',
        borderRadius: '2px',
        px: 2,
        py: '6px',
        fontSize: convertPxToRem(14),
        lineHeight: '20px',
        _focus: {
          border: '1px solid var(--secondary-500, #4285F4)',
          color: '#4285F4',
        },
      },
      icon: {
        right: 1,
      },
    },
  },
  defaultProps: { variant: 'ellipsis' },
});
