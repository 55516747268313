import { Flex, Icon, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import TableContainer from 'component-library/components/Table/table-container';
import { SortAsc, SortDesc } from 'components/icons';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useCallback } from 'react';
import { FilingInstance } from 'types/shared-types';

import FilingHistoryTableRow from './history-filing-row';

type TableHeadData = {
  name: string;
  key?: string;
};

export const FilingHistoryTable = ({
  data,
  tableFilters,
}: {
  data: FilingInstance[];
  tableFilters: UseTableFiltersType;
}) => {
  const isDataEmpty = !data || data.length === 0;
  const { order_by: order, setFilters } = tableFilters;
  const identifySortOrderSelector = useCallback(
    (field: string) => {
      const order_by = order ?? '';
      if (order_by.includes(field)) {
        return 'asc';
      }

      return 'desc';
    },
    [order]
  );

  const startDateOrder = identifySortOrderSelector('start_date');
  const endDateOrder = identifySortOrderSelector('end_date');
  const amountSortOder = identifySortOrderSelector('total_tax_liability');
  const dateFiledOrder = identifySortOrderSelector('date_filed');

  const handleSort = useCallback(
    (field: string) => {
      const order_by = order?.split(',') ?? [];
      if (order_by.includes(field)) {
        setFilters({ order_by: order_by.filter(key => key !== field) });
      } else {
        setFilters({ order_by: [field, ...order_by] });
      }
    },
    [order, setFilters]
  );

  const TABLE_HEAD: TableHeadData[] = [
    { name: 'Jurisdiction' },
    { name: 'start date', key: 'start_date' },
    { name: 'end date', key: 'end_date' },
    { name: 'filed date', key: 'date_filed' },
    { name: 'Total Tax Liability', key: 'total_tax_liability' },
  ];

  if (isDataEmpty) {
    return <TableEmptyState tableName="Filing History" />;
  }

  return (
    <TableContainer>
      <Table variant="unstyled">
        <Thead>
          <Tr>
            {TABLE_HEAD.map(({ name, key }, idx) => {
              return (
                <Th key={idx} isNumeric={['Total Tax Liability'].includes(name)} whiteSpace={'nowrap'}>
                  <Flex
                    alignItems="center"
                    justifyContent={['Total Tax Liability'].includes(name) ? 'flex-end' : 'flex-start'}
                    gap={1}
                    onClick={() => {
                      if (key) {
                        handleSort(key);
                      }
                    }}
                  >
                    {name}
                    {key && (
                      <Icon
                        as={
                          {
                            start_date: startDateOrder === 'asc' ? SortAsc : SortDesc,
                            end_date: endDateOrder === 'asc' ? SortAsc : SortDesc,
                            total_tax_liability: amountSortOder === 'asc' ? SortAsc : SortDesc,
                            date_filed: dateFiledOrder === 'asc' ? SortAsc : SortDesc,
                          }[key]
                        }
                      />
                    )}
                  </Flex>
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>{data && data.map((row: FilingInstance) => <FilingHistoryTableRow key={row.id} {...row} />)}</Tbody>
      </Table>
    </TableContainer>
  );
};
