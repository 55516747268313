import { IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { ExemptionsForm } from 'pages/Customers/components/exemptions-form';
import { MdMoreVert } from 'react-icons/md';

type TransactionDetailActionProps = {
  customerId: string;
  transactionId: string;
};
export const TransactionDetailAction = ({ customerId, transactionId }: TransactionDetailActionProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Menu>
      <MenuButton as={IconButton} aria-label="Options" icon={<MdMoreVert size={'24px'} />} variant="unstyled">
        Actions
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onOpen}>Add Exemption</MenuItem>
        {isOpen && (
          <ExemptionsForm isOpen={isOpen} onClose={onClose} customerId={customerId} transactionId={transactionId} />
        )}
      </MenuList>
    </Menu>
  );
};
