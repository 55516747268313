import { Flex, HStack, IconButton } from '@chakra-ui/react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { getFormattedDate } from 'utils/dates';

import Text from '../Text';

interface CalendarHeaderProps extends ReactDatePickerCustomHeaderProps {}

export const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <Flex p={4} pt={1} pb={1} w="100%" justifyContent={'space-between'} align={'center'}>
      <Text align={'end'} fontSize={'md'} fontWeight={600}>
        {getFormattedDate(date, 'MMMM yyyy')}
      </Text>
      <HStack gap={2}>
        <IconButton
          size="sm"
          variant={'ghost'}
          aria-label="prev-month"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <MdChevronLeft fontSize={'1.5rem'} color="#000" />
        </IconButton>
        <IconButton
          size="sm"
          aria-label="next-month"
          variant={'ghost'}
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <MdChevronRight fontSize={'1.5rem'} color="#000" />
        </IconButton>
      </HStack>
    </Flex>
  );
};

export default CalendarHeader;
