import { Skeleton, SkeletonProps, TableContainerProps, VStack } from '@chakra-ui/react';

import TableContainer from '../Table/table-container';

type TableSkeletonProps = {
  items?: number;
  skeletonProps?: SkeletonProps;
  tableProps?: TableContainerProps;
};
export const TableSkeleton = ({ items = 10, skeletonProps = {}, tableProps = {} }: TableSkeletonProps) => {
  return (
    <TableContainer {...tableProps}>
      <VStack spacing={4} py={4}>
        {Array.from({ length: items }, (_, index) => (
          <Skeleton key={index} height={10} width="full" {...skeletonProps} />
        ))}
      </VStack>
    </TableContainer>
  );
};
