import { BillingDetail, BillingMatrix, Invoice, OrganizationBillingDetails } from 'types/billings';

import api from './api';

export const BILLING_STATE_KEY = 'billing';

export const getBillingCheckoutHostedPage = async (orgId: string, billingPlan: 'FREE' | 'GROWTH' | 'PREMIUM') => {
  const { data } = await api.post(
    '/v1/billing/checkout_new_url',
    {
      billing_plan: billingPlan,
    },
    {
      headers: {
        'x-organization-id': orgId,
      },
    }
  );
  return data;
};

export const generateChargebeePortalSession = async (orgId: string) => {
  const { data } = await api.post(
    '/v1/billing/generate_portal_session',
    {},
    {
      headers: {
        'x-organization-id': orgId,
      },
    }
  );
  return data;
};

export const getOrgBillingInfo = async (orgId: string): Promise<BillingDetail> => {
  const { data } = await api.get(`/v1/billing`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
  return data;
};

export const updateBillingPlan = async (orgId: string, billingPlan: 'PREMIUM') => {
  const { data } = await api.post(
    '/v1/billing/update_billing_plan',
    {
      billing_plan: billingPlan,
    },
    {
      headers: {
        'x-organization-id': orgId,
      },
    }
  );
  return data;
};

export const getOrgInvoicesFromChargebee = async (orgId: string): Promise<Invoice[]> => {
  const { data } = await api.get(`/v1/billing/invoices`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
  return data;
};

export const getOrgBillingDetails = async (orgId: string): Promise<OrganizationBillingDetails> => {
  const { data } = await api.get(`/v1/billing_details`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
  return data;
};

export const getOrgBillingMatrix = async (orgId: string): Promise<BillingMatrix> => {
  const { data } = await api.get(`/v1/billing/matrices`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
  return data;
};
