import { Stack, useDisclosure } from '@chakra-ui/react';
import { AddressCard } from 'components/Address/AddressCard';
import { useState } from 'react';
import { AddressInstance } from 'types/shared-types';

import { EditTransactionAddressForm } from './edit-transaction-address-modal';

export const AddressesCards = ({ billing, shipping }: { billing: AddressInstance; shipping: AddressInstance }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [payload, setPayload] = useState({});

  const openEditForm = (payload: object) => {
    setPayload(payload);
    onOpen();
  };

  return (
    <Stack flexDirection={{ base: 'column', md: 'row' }} gap={6}>
      <EditTransactionAddressForm isOpen={isOpen} onClose={onClose} payload={payload} />
      <AddressCard
        size="lg"
        title="Bill To"
        address={billing}
        addressStatus={billing?.status}
        onClickEdit={() => openEditForm(billing)}
      />
      <AddressCard
        size="lg"
        title="Ship To"
        address={shipping}
        addressStatus={shipping?.status}
        onClickEdit={() => openEditForm(shipping)}
      />
    </Stack>
  );
};
