import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    border: '1px solid rgba(207, 208, 216, 0.60)',
    borderRadius: '2px',
    background: 'var(--gray-0, #FFF)',
    paddingX: '8px',
    paddingY: '6px',
    height: '32px',
    _focus: {
      border: '1px solid var(--secondary-500, #4285F4) !important',
    },
  },
  element: {
    color: 'white',
  },
});

export const inputTheme = defineMultiStyleConfig({ baseStyle });
