import { HStack } from '@chakra-ui/react';
import ExportTransactions from 'pages/Transactions/components/export-transaction';
import { TransactionPopover } from 'pages/Transactions/components/transaction-filter/transaction-popover';
import TransactionSearch from 'pages/Transactions/components/TransactionSearch';

export const TransactionTrackingToolbar = () => {
  return (
    <HStack>
      <TransactionSearch />
      <TransactionPopover />
      <ExportTransactions />
    </HStack>
  );
};
