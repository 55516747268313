import { Flex } from '@chakra-ui/react';
import { ExemptionPopover } from 'pages/Exemptions/components/exemption-filter/exemption-popover';
import { ImportExemptionButton } from 'pages/Exemptions/components/import-exemption/import-exemption';
import React from 'react';

export const ExemptionTrackingToolbar = () => {
  return (
    <Flex gap={3} alignItems={'top'}>
      <ExemptionPopover />
      <ImportExemptionButton />
    </Flex>
  );
};
