import { Box, HStack } from '@chakra-ui/react';
import MarkdownView from 'react-showdown';

import BotAvatar from './bot-avatar';

export default function AnswerMsg({ payload }: any) {
  const { message = 'No answer found for this' } = payload;

  return (
    <HStack w="100%" direction={'column'} justifyContent={'top'} alignItems={'top'} gap={2} mb={3}>
      <BotAvatar />
      <Box
        bgColor="#f1f1f1"
        borderRadius={8}
        p={3}
        __css={{
          color: '#262B47',
          fontSize: '0.9rem',
          fontWeight: 400,
          lineHeight: '21.6px',
          '& ul': {
            marginLeft: 8,
          },
          '& ol': {
            marginLeft: 8,
          },
        }}
        overflowX={'auto'}
      >
        <MarkdownView markdown={message} options={{ tables: true, emoji: true }} />
      </Box>
    </HStack>
  );
}
