import { Box, useDisclosure } from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';

import { ImportExemptionModal } from './import-exemption-form';

export const ImportExemptionButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <Button variant={'solid'} color={'primary'} width={'100px'} onClick={onOpen}>
        Import
      </Button>
      {isOpen && <ImportExemptionModal isOpen={isOpen} onClose={onClose} />}
    </Box>
  );
};
