import { useToast as chakraToast, UseToastOptions } from '@chakra-ui/react';
import ToastContainer from 'component-library/components/Toast/toast';
import React, { useRef } from 'react';

const defaultOptions: UseToastOptions = {
  duration: 2000,
  isClosable: true,
  position: 'top',
};

const useToast = () => {
  const toast = chakraToast();
  const toastIdRef = useRef<string | number | null>(null);

  const showToast = (toastOptions: UseToastOptions) => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }

    const mergedOptions = {
      ...defaultOptions,
      ...toastOptions,
      render: toastOptions.render || (props => <ToastContainer {...props} />),
    };

    toastIdRef.current = toast(mergedOptions);
  };

  return showToast;
};

export default useToast;
