import api from './api';

const getPhysicalNexus = (orgId: string, page: number, size: number) => {
  return api.get(`/v1/nexus/physical_nexus`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      page: page,
      size: size,
    },
  });
};

const deletePhysicalNexus = (orgId: string, id: string) => {
  return api.delete(`/v1/nexus/physical_nexus/${id}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const addPhysicalNexus = (orgId: string, payload: object) => {
  return api.post(`/v1/nexus/physical_nexus`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updatePhysicalNexus = (orgId: string, id: string, payload: object) => {
  return api.put(`/v1/nexus/physical_nexus/${id}`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const PHYSICAL_NEXUS_KEY = 'physicalNexus';

export { addPhysicalNexus, deletePhysicalNexus, getPhysicalNexus, PHYSICAL_NEXUS_KEY, updatePhysicalNexus };
