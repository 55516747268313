const getAccessToken = async (code: string, redirectUri: string) => {
  const body = new FormData();
  body.append('grant_type', 'authorization_code');
  body.append('code', code);
  body.append('redirect_uri', redirectUri);

  const headers = new Headers();
  headers.append(
    'Authorization',
    `Basic ${btoa(`${import.meta.env.VITE_RIPPLING_CLIENT_ID}:${import.meta.env.VITE_RIPPLING_CLIENT_SECRET}`)}`
  );
  // NOTE: fetch is used here for adding unintentional headers to the request we set by axios interceptor
  const response = await fetch('https://api.rippling.com/api/o/token/', {
    method: 'POST',
    headers,
    body,
  });
  return response.json();
};

export const markKintsugiAppAsInstalled = async (code: string) => {
  const response = await getAccessToken(code, `${import.meta.env.VITE_OAUTH_FRONTEND_URL}?service_id=rippling`);
  if (response.access_token) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${response.access_token}`);
    // NOTE: fetch is used here for adding unintentional headers to the request we set by axios interceptor
    return await fetch('https://api.rippling.com/platform/api/mark_app_installed', {
      method: 'POST',
      headers,
    });
  }
};
