import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ProductInstance } from 'types/shared-types';

const productsAdapter = createEntityAdapter<ProductInstance>();

const initialState = productsAdapter.getInitialState({
  loading: false,
  query: '',
  filter: {
    query: {},
  },
  order_by: {
    order_by: '',
  },
  metadata: {},
});

const productsSlice = createSlice({
  name: 'products',
  initialState: initialState,
  reducers: {
    storeFilter(state, action): void {
      state.filter.query = action.payload;
    },
    storeOrder(state, action): any {
      state.order_by = action.payload;
    },
    storeQuery(state, action): void {
      state.query = action.payload;
    },
  },
});

export const { storeFilter, storeOrder, storeQuery } = productsSlice.actions;
export default productsSlice.reducer;
