import debounce from 'lodash/debounce';
import { Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import AppLayoutMd from './app-layout.md';
import AppLayoutSm from './app-layout.sm';

export const AppLayout = () => {
  const [matches, setMatches] = useState(window.matchMedia('(min-width: 768px)').matches);

  useEffect(() => {
    window.matchMedia('(min-width: 768px)').addEventListener(
      'change',
      debounce(e => setMatches(e.matches), 500)
    );
  }, []);

  return !matches ? (
    <AppLayoutSm>
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </AppLayoutSm>
  ) : (
    <AppLayoutMd>
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </AppLayoutMd>
  );
};
