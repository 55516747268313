import 'react-datepicker/dist/react-datepicker.css';
import './app-fonts';

import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider, RedirectToLogin, useAuthInfo } from '@propelauth/react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { theme } from 'component-library/theme/theme';
import { NotificationProvider } from 'components/error/notification-context';
import useIsEmbeddedInIframe from 'hooks/useIsEmbeddedInIframe';
import { StrictMode, useEffect } from 'react';
import { Provider } from 'react-redux';
import store from 'redux/store';

import { ACLProvider } from './acl/acl';
import { PayWallProvider } from './acl/paywall';
import AppLoader from './app-loader';
import { AppLoaderWrapper, AppLoaderWrapperProvider } from './app-loader-wrapper';
import AppRoutes from './app-routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      networkMode: 'online',
    },
    mutations: {
      networkMode: 'online',
    },
  },
  queryCache: new QueryCache({
    onError: () => {
      // TODO: handle react-query api error globally here
    },
  }),
});

const App = () => {
  // If the app is embedded in an iframe, this means the app is opened in Shopify
  // Then, we will open it in a new tab for authentication if needed
  // ToDo: we should open new tab only when the user is not authenticated

  const AppContent = () => {
    const isEmbeddedInIframe = useIsEmbeddedInIframe();
    const { isLoggedIn, loading } = useAuthInfo();

    const openInNewTab = () => {
      const newTab = window.open(window.location.href, '_blank');
      if (newTab) {
        newTab.focus();
      }
    };

    useEffect(() => {
      if (isEmbeddedInIframe && !isLoggedIn && !loading) {
        openInNewTab();
      }
    }, [isEmbeddedInIframe, isLoggedIn, loading]);

    if (loading) {
      return <AppLoader />;
    }

    if (!isLoggedIn) {
      return isEmbeddedInIframe ? (
        <div>
          <p>Please log into Kintsugi in the new tab that has been opened.</p>
          <p>Once you logged in, you can close this tab and return to Shopify.</p>
        </div>
      ) : (
        <RedirectToLogin />
      );
    }

    return (
      <Provider store={store}>
        <ACLProvider>
          <PayWallProvider>
            <AppLoaderWrapperProvider>
              <AppLoaderWrapper>
                <NotificationProvider>
                  <AppRoutes />
                </NotificationProvider>
              </AppLoaderWrapper>
            </AppLoaderWrapperProvider>
          </PayWallProvider>
        </ACLProvider>
      </Provider>
    );
  };

  return (
    <StrictMode>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider authUrl={import.meta.env.VITE_AUTH_URL}>
            <AppContent />
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ChakraProvider>
    </StrictMode>
  );
};

export default App;
