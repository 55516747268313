/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Page_PhysicalNexusRead_ } from '../models/Page_PhysicalNexusRead_';
import type { PhysicalNexusCreate } from '../models/PhysicalNexusCreate';
import type { PhysicalNexusRead } from '../models/PhysicalNexusRead';
import type { PhysicalNexusUpdate } from '../models/PhysicalNexusUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { NexusInstance } from 'types/shared-types';
export class NexusService {
  /**
   * Get Nexus For Org
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getNexusForOrgV1NexusGet({
    xOrganizationId,
  }: {
    xOrganizationId: string;
  }): CancelablePromise<NexusInstance[]> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/nexus/',
      headers: {
        'x-organization-id': xOrganizationId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Physical Nexus
   * @returns Page_PhysicalNexusRead_ Successful Response
   * @throws ApiError
   */
  public static getPhysicalNexusV1NexusPhysicalNexusGet({
    xOrganizationId,
    countryCode,
    stateCode,
    page = 1,
    size = 50,
  }: {
    xOrganizationId: string;
    countryCode?: string;
    stateCode?: string;
    page?: number;
    size?: number;
  }): CancelablePromise<Page_PhysicalNexusRead_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/nexus/physical_nexus',
      headers: {
        'x-organization-id': xOrganizationId,
      },
      query: {
        country_code: countryCode,
        state_code: stateCode,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create Physical Nexus
   * @returns PhysicalNexusRead Successful Response
   * @throws ApiError
   */
  public static createPhysicalNexusV1NexusPhysicalNexusPost({
    xOrganizationId,
    requestBody,
  }: {
    xOrganizationId: string;
    requestBody: PhysicalNexusCreate;
  }): CancelablePromise<PhysicalNexusRead> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/nexus/physical_nexus',
      headers: {
        'x-organization-id': xOrganizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Physical Nexus
   * @returns PhysicalNexusRead Successful Response
   * @throws ApiError
   */
  public static updatePhysicalNexusV1NexusPhysicalNexusPhysicalNexusIdPut({
    physicalNexusId,
    xOrganizationId,
    requestBody,
  }: {
    physicalNexusId: string;
    xOrganizationId: string;
    requestBody: PhysicalNexusUpdate;
  }): CancelablePromise<PhysicalNexusRead> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/nexus/physical_nexus/{physical_nexus_id}',
      path: {
        physical_nexus_id: physicalNexusId,
      },
      headers: {
        'x-organization-id': xOrganizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Physical Nexus
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deletePhysicalNexusV1NexusPhysicalNexusPhysicalNexusIdDelete({
    physicalNexusId,
    xOrganizationId,
  }: {
    physicalNexusId: string;
    xOrganizationId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/nexus/physical_nexus/{physical_nexus_id}',
      path: {
        physical_nexus_id: physicalNexusId,
      },
      headers: {
        'x-organization-id': xOrganizationId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Refresh Nexus
   * @returns any Successful Response
   * @throws ApiError
   */
  public static refreshNexusV1NexusRefreshPost({
    xOrganizationId,
  }: {
    xOrganizationId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/nexus/refresh',
      headers: {
        'x-organization-id': xOrganizationId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Nexus Report
   * Export the nexus report for the organization, and send an email to the user
   * with the report
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getNexusReportV1NexusExportGet({
    xOrganizationId,
  }: {
    xOrganizationId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/nexus/export',
      headers: {
        'x-organization-id': xOrganizationId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
