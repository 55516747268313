import {
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ONBOARDING_STATE_KEY } from 'apis/dashboard-apis';
import { addPhysicalNexus, PHYSICAL_NEXUS_KEY, updatePhysicalNexus } from 'apis/physical-nexus-apis';
import { AxiosError } from 'axios';
import Button from 'component-library/components/ButtonTmp/button';
import DatePicker from 'component-library/components/date-picker/date-picker';
import KSTooltip from 'component-library/components/Tooltiptmp/tooltip';
import KCustomSelect from 'components/KCustomSelect';
import { CountryOptions, USStates } from 'constants/app-constants';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { MdInfo } from 'react-icons/md';
import { CountryCodeEnum, PhysicalNexusInstance } from 'types/shared-types';
import * as Yup from 'yup';
import { date, object, string } from 'yup';

const validationSchema = object().shape({
  country_code: string().required('Country is required'),
  state: string().required('State is required'),
  start_date: date().required('Start date is required'),
  end_date: date()
    .nullable()
    .min(Yup.ref('start_date'), "End date can't be before start date")
    .max(new Date(), 'End date can not be greater than today.')
    .optional(),
});

type PhysicalNexusModalProps = {
  isOpen: boolean;
  onClose: () => void;
  physicalNexus?: PhysicalNexusInstance;
};

export const PhysicalNexusModal = ({ isOpen, onClose, physicalNexus }: PhysicalNexusModalProps) => {
  const { orgId } = useOrg();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const { track } = useTracking();
  const queryClient = useQueryClient();

  const { isPending: isCreationPending, mutateAsync: doCreatePhysicalNexus } = useMutation({
    mutationFn: (payload: any) => {
      return addPhysicalNexus(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PHYSICAL_NEXUS_KEY] });
      queryClient.invalidateQueries({ queryKey: [ONBOARDING_STATE_KEY] });
      handleSuccessNotification(
        "Physical Presence Successfully Added. You can check the 'Nexus' tab in a few minutes to see updates to your Nexus"
      );
    },
    onError: (err: Error | AxiosError) => {
      handleFailNotification(err);
    },
  });

  const { isPending, mutateAsync: doUpdatePhysicalNexus } = useMutation({
    mutationFn: (payload: { id: string; data: object }) => {
      return updatePhysicalNexus(orgId, payload.id, payload.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PHYSICAL_NEXUS_KEY] });
      queryClient.invalidateQueries({ queryKey: [ONBOARDING_STATE_KEY] });
      handleSuccessNotification('Physical Nexus added successfully.');
    },
    onError: (err: Error | AxiosError) => {
      handleFailNotification(err);
    },
  });

  const initialValues = physicalNexus
    ? {
        country_code: physicalNexus.country_code,
        state: physicalNexus.state_code,
        start_date: physicalNexus.start_date,
        end_date: physicalNexus.end_date,
      }
    : {
        country_code: CountryCodeEnum.US,
        state: '',
        start_date: '',
        end_date: '',
      };

  const isDateValid = (dateStr: string) => {
    return !isNaN(new Date(dateStr).getTime());
  };

  const { errors, touched, handleChange, resetForm, values, handleSubmit, setFieldValue, isValid, dirty } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async ({ country_code, state, start_date, end_date }) => {
      if (physicalNexus) {
        await doUpdatePhysicalNexus({
          id: physicalNexus.id,
          data: {
            country_code,
            state_code: state,
            start_date,
            end_date: end_date && isDateValid(end_date) ? values.end_date : undefined,
          },
        });
        track('update presence', { action: 'physical_nexus' });
      } else {
        await doCreatePhysicalNexus({
          country_code,
          state_code: state,
          start_date,
          end_date: end_date && isDateValid(end_date) ? values.end_date : undefined,
        });
        track('adds presence', { action: 'physical_nexus' });
      }
      resetForm({});
      onClose();
    },
  });

  const isEditModal = !!physicalNexus;
  const modalSubHeader = isEditModal
    ? "Once you update the details, you'll need to contact support to make further changes. If you are looking to test, please use a test site."
    : 'Once you add them, you will need to contact support to edit or delete them. If you are looking to test, please use a test site.';

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isEditModal ? 'Edit' : 'New'} Presence</ModalHeader>
        <ModalCloseButton />

        <ModalBody display={'grid'} flexDirection={'column'} gap={2}>
          <Text mb={2}>{modalSubHeader}</Text>
          <SimpleGrid columns={2} gap={4}>
            <FormControl isRequired>
              <FormLabel htmlFor="country_code">Country</FormLabel>
              <KCustomSelect
                name="country_code"
                placeholder="Select Country"
                items={CountryOptions}
                itemTitle="label"
                itemValue="value"
                onChange={value => {
                  setFieldValue('country_code', value);
                  setFieldValue('state', '');
                }}
                value={values.country_code}
                isDisabled={isEditModal}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="state">Jurisdiction</FormLabel>
              {values.country_code === CountryCodeEnum.US ? (
                <KCustomSelect
                  name="state"
                  placeholder="Select State"
                  items={USStates}
                  itemTitle="label"
                  itemValue="value"
                  onChange={value => setFieldValue('state', value)}
                  value={values.state}
                  isDisabled={isEditModal}
                />
              ) : (
                <Input
                  name="state"
                  placeholder="Enter State"
                  onChange={handleChange}
                  value={values.state}
                  defaultValue={''}
                  disabled={isEditModal}
                />
              )}
              {errors.state && touched.state && <Text color={'red.500'}>{errors.state}</Text>}
            </FormControl>
          </SimpleGrid>
          <SimpleGrid columns={2} gap={4}>
            <FormControl isRequired>
              <FormLabel htmlFor="start_date">Start Date</FormLabel>
              <DatePicker
                selected={values.start_date}
                onChange={date => {
                  setFieldValue('start_date', date);
                }}
                disabled={isEditModal}
              />
              {errors.start_date && touched.start_date && <Text color={'red.500'}>{errors.start_date}</Text>}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="end_date">
                End Date{' '}
                {(!isEditModal || (isEditModal && !values.end_date)) && (
                  <KSTooltip
                    hasArrow={false}
                    label="You can optionally add an end date to a presence if you no longer
                        have an employee, contractor, or other form of presence in a jurisdiction."
                    shouldWrapChildren
                  >
                    <Icon as={MdInfo} />
                  </KSTooltip>
                )}
              </FormLabel>
              <DatePicker
                selected={values.end_date}
                onChange={date => {
                  setFieldValue('end_date', date);
                }}
                disabled={!!values.end_date && isEditModal}
              />
              {errors.end_date && touched.end_date && <Text color={'red.500'}>{errors.end_date}</Text>}
            </FormControl>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={isPending || isCreationPending}
              isDisabled={!isValid || !dirty}
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
