import {
  Avatar,
  Badge,
  Button,
  ButtonProps,
  Flex,
  Icon,
  Image,
  Menu,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useAuthInfo, useLogoutFunction, useRedirectFunctions } from '@propelauth/react';
import { ACLCheck } from 'app/acl/aclcheck';
import MenuButton from 'component-library/components/Menu/MenuButton';
import { NavLink } from 'component-library/components/nav-link';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { APP_ROUTES, ModuleInfo } from 'constants/app-constants';
import { storeJWT } from 'cookie-handler';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useState } from 'react';
import { IconType } from 'react-icons';
import { FiLogOut, FiUser } from 'react-icons/fi';
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';

import logoSm from './components/logo.sm.svg';
import logo from './components/logo.svg';
import { OrganizationSelector } from './components/org-selector';

type NavItemProps = {
  href: string;
  title: string;
  icon: IconType;
  isExpanded: boolean;
} & ButtonProps;

const NavItem = ({ title, href, icon, isExpanded = true, ...rest }: NavItemProps) => {
  const { children, ...btnProps } = rest;

  return (
    <Tooltip label={isExpanded ? null : title} placement={'right'}>
      <Button
        as={NavLink}
        to={href}
        flexWrap={'nowrap'}
        gap={2}
        variant="nav"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        {...btnProps}
        leftIcon={icon && <Icon boxSize="24px" as={icon} />}
        style={isExpanded ? {} : { width: 'unset' }}
      >
        {isExpanded ? children : null}
      </Button>
    </Tooltip>
  );
};

type SidebarProps = {
  hideToggleBtn?: boolean;
  isExpanded?: boolean;
  onSidebarExpand?: () => void;
  onClose?: () => void;
};
export const Sidebar = ({ hideToggleBtn = false, isExpanded = true, onSidebarExpand, onClose }: SidebarProps) => {
  const { externalId, isTest } = useOrg();
  const { user } = useAuthInfo();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { redirectToOrgPage } = useRedirectFunctions();
  const propelLogout = useLogoutFunction();

  const active = APP_ROUTES.find(({ href }: ModuleInfo) => href === pathname);
  const [activeButton, setActiveButton] = useState<string | undefined>(active?.name);

  useEffect(() => {
    const newActive = APP_ROUTES.find(({ href }: ModuleInfo) => href === pathname);
    setActiveButton(newActive?.name);
  }, [pathname]);

  const logoutFunction = () => {
    localStorage.clear();
    storeJWT('');
    propelLogout(true);
  };

  const handleRedirect = (item: ModuleInfo) => {
    navigate(item.href);
    setActiveButton(item.name);
    onClose && onClose();
  };

  const renderLogo = isExpanded ? (
    <Flex gap={1} justify={'center'} align={'center'}>
      <Image src={logo} height={'26px'} />
      {isTest && (
        <Badge bg={'#DD6B20'} color={'white'} textTransform={'uppercase'} px={1} h={'20px'} fontSize={'12px'}>
          Test
        </Badge>
      )}
    </Flex>
  ) : (
    <Image src={logoSm} height={'26px'} onClick={onSidebarExpand} cursor={'pointer'} />
  );

  return (
    <Flex
      as={'nav'}
      data-onboarding="sidebar"
      bgColor={'#262B47'}
      h={'100%'}
      flexDir="column"
      justifyContent="space-between"
      py={'16px'}
      overflow="auto"
    >
      <Flex px="5%" flexDirection="column">
        <Flex ml={isExpanded ? '10px' : 4} justify={'space-between'} align={'center'} mb={2}>
          {renderLogo}
          {!hideToggleBtn && (
            <Button
              variant={'unstyled'}
              color={'gray.300'}
              onClick={onSidebarExpand}
              style={
                isExpanded
                  ? {
                      left: '1.2rem',
                    }
                  : {
                      background: '#262B47',
                      position: 'absolute',
                      left: '3rem',
                      minWidth: 'unset',
                    }
              }
            >
              {isExpanded ? (
                <MdOutlineKeyboardDoubleArrowLeft size={24} />
              ) : (
                <MdOutlineKeyboardDoubleArrowRight size={24} />
              )}
            </Button>
          )}
        </Flex>

        <Flex flexDirection={'column'} justifyContent={'space-between'}>
          {APP_ROUTES.map(
            (link: ModuleInfo) =>
              !link.isBottomToolbar && (
                <ACLCheck key={link.name} role={link.minRole}>
                  <NavItem
                    title={link.name}
                    data-onboarding={link.name}
                    isActive={activeButton === link.name}
                    icon={link.icon}
                    onClick={() => handleRedirect(link)}
                    href={link.href}
                    isExpanded={isExpanded}
                  >
                    {link.name}
                  </NavItem>
                </ACLCheck>
              )
          )}
        </Flex>
      </Flex>
      <Flex px="5%" flexDirection={'column'}>
        {APP_ROUTES.map(
          (link: ModuleInfo) =>
            link.isBottomToolbar && (
              <ACLCheck key={link.name} role={link.minRole}>
                <NavItem
                  title={link.name}
                  isActive={activeButton === link.name}
                  icon={link.icon}
                  onClick={() => handleRedirect(link)}
                  isExpanded={isExpanded}
                  href={link.href}
                >
                  <Text textColor={'white'} isTruncated>
                    {link.name}
                  </Text>
                </NavItem>
              </ACLCheck>
            )
        )}
        <Menu placement="top">
          <MenuButton my={3} px={4}>
            <Flex gap={2}>
              <Avatar size="xs" src={user?.pictureUrl || ''} />
              <Text isTruncated textColor={'white'}>
                {user?.firstName + ' ' + user?.lastName}
              </Text>
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem icon={<FiUser />} onClick={() => redirectToOrgPage(externalId)}>
              Account
            </MenuItem>
            <MenuItem icon={<FiLogOut />} onClick={logoutFunction}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
        <OrganizationSelector isExpanded={isExpanded} />
      </Flex>
    </Flex>
  );
};
