import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

const filter = definePartsStyle({
  container: {
    border: 'none',
  },
  button: {
    fontWeight: 500,
    px: 0,
    _hover: {
      bg: 'none',
    },
  },
  panel: {
    p: 0,
    display: 'flex',
    flexDirection: 'column',
  },
});

export const accordionTheme = defineMultiStyleConfig({
  variants: { filter },
});
