import { defineStyleConfig } from '@chakra-ui/react';

export const tooltipStyles = defineStyleConfig({
  baseStyle: {
    backgroundColor: 'gray.900',
    borderRadius: 'md',
    padding: 2,
    fontSize: 'sm',
    fontWeight: 'normal',
  },
});
