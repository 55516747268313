import { defineStyleConfig } from '@chakra-ui/react';

import { convertPxToRem } from '../foundations/typography';

export const datePicker = defineStyleConfig({
  baseStyle: {
    fontFamily: 'body',
    boxShadow: '0px 4px 8px 0px rgba(24, 36, 60, 0.15)',
    backgroundColor: 'white',
    borderRadius: '4px',
  },
  sizes: {
    sm: {
      '& .react-datepicker__day, .react-datepicker__day-name': {
        width: '40px',
        height: '36px',
      },
      '& .react-datepicker__day': {
        lineHeight: '36px',
        fontSize: convertPxToRem(14),
      },
      '& .react-datepicker__day-name': {
        lineHeight: '36px',
        fontSize: convertPxToRem(12),
        color: 'gray.600',
        fontWeight: 400,
      },
    },
  },
  variants: {
    default: {
      '& .react-datepicker__header, .react-datepicker__month': {
        fontFamily: 'body',
        background: '#fff',
      },
      '& .react-datepicker': {
        border: 'none',
        background: '#fff',
      },
      '& .react-datepicker__day--selected': {
        backgroundColor: 'secondary.500',
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'default',
  },
});
