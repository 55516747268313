import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

type NavLinkProps = { to: string } & LinkProps;

const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(({ to, ...props }, ref) => (
  <Link to={to} {...props} ref={ref} />
));
NavLink.displayName = 'NavLink';
export { NavLink };
