import { Text, TextProps } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

// TODO write test
const KS_Text = ({ children, ...rest }: TextProps, ref: any) => {
  return (
    <Text ref={ref} {...rest}>
      {children}
    </Text>
  );
};

export default forwardRef(KS_Text);
