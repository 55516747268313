import { Grid, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import getValue from 'lodash/get';
import { Fragment } from 'react';

import { integrationTabs } from './tabs';

const Integrations = () => {
  // TODO: remove after prod release of HRIS apps
  const { user } = useAuthInfo();
  const hrisEnabled = getValue(user, 'properties.metadata.hris');

  const tabHeaders = integrationTabs.filter(tab => (tab.title === 'HRIS' ? hrisEnabled : true)).map(tab => tab.title);
  const tabContents = integrationTabs.filter(tab => (tab.title === 'HRIS' ? hrisEnabled : true));

  return (
    <Tabs isLazy>
      <TabList>
        {tabHeaders.map((header, i) => {
          return (
            <Tab key={i} fontSize={'sm'}>
              {header}
            </Tab>
          );
        })}
      </TabList>
      <TabPanels mt={4}>
        {tabContents.map(({ cards, title }) => (
          <TabPanel maxWidth={'1244px'} p={0} key={title}>
            <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }} gap={6} alignItems={'stretch'}>
              {cards.map((content, i) => (
                <Fragment key={`${title}-${i}`}>{content}</Fragment>
              ))}
            </Grid>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
export default Integrations;
