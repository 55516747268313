import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import addresses from './slices/addresses';
import customers from './slices/customers';
import exemptions from './slices/exemptions';
import filings from './slices/filings';
import orgDetails from './slices/orgDetails';
import orgSlice from './slices/orgSlice';
import physicalNexus from './slices/physicalNexus';
import products from './slices/products';
import registrations from './slices/registrations';
import transactions from './slices/transactions';

/**
 * TODO :
 * 2. integrate query lib
 * 3. integrate logger
 * 4. persistReducer
 * 5. handle user via redux
 */
const store = configureStore({
  devTools: import.meta.env.VITE_ENVIRONMENT !== 'prod',
  reducer: {
    org: orgSlice,
    registrations,
    products,
    orgDetails,
    physicalNexus,
    filings,
    customers,
    transactions,
    exemptions,
    addresses,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
