import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getCustomerForId, getCustomers } from 'apis/customers';
import { CustomerInstance } from 'types/shared-types';

const customersAdapter = createEntityAdapter<CustomerInstance>();

const initialState = customersAdapter.getInitialState({
  loading: false,
  query: '',
  activePage: {
    pages: 0,
  },
  filter: {
    query: {},
    resultIds: [],
  },
  order_by: {
    order_by: 'street_1,city,state,postal_code,country,status',
  },
});

export const fetchCustomers = createAsyncThunk('customers/fetchCustomers', async (params: any) => {
  const response = await getCustomers(params.orgId, params.page, params.size, params.status, params.order);
  return response?.data;
});

export const fetchCustomersforId = createAsyncThunk('customers/fetchCustomersforId', async (params: any) => {
  const response = await getCustomerForId(params.orgId, params.customerId);
  return response?.data;
});

const customersSlice = createSlice({
  name: 'customers',
  initialState: initialState,
  reducers: {
    storeFilter(state, action): any {
      state.filter.query = action.payload;
    },
    storeOrder(state, action): any {
      state.order_by = action.payload;
    },
    storeQuery(state, action): void {
      state.query = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCustomers.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (Array.isArray(payload.items)) {
          customersAdapter.upsertMany(state, payload.items);
          state.activePage = { ...payload };
          state.filter.resultIds = payload.items.map(({ id }: { id: string }) => id);
        }
      })
      .addCase(fetchCustomersforId.fulfilled, (state, { payload }) => {
        if (payload) {
          customersAdapter.upsertOne(state, payload);
        }
      });
  },
});

export const { storeFilter, storeOrder, storeQuery } = customersSlice.actions;
export default customersSlice.reducer;
