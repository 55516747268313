import { Flex, HStack } from '@chakra-ui/react';
import Text from 'component-library/components/Text';
import { ArrowLeft } from 'components/icons';
import { APP_ROUTES, ModuleInfo, ROUTES_WITH_NO_NAV } from 'constants/app-constants';
import React, { useEffect, useState } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';

export const AppHeaderSm = () => {
  const [activeModule, setActiveModule] = useState<ModuleInfo | undefined>({
    name: '',
    icon: null,
    href: '',
    label: '',
    minRole: 'Member',
  });
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const allRoutes = [...ROUTES_WITH_NO_NAV, ...APP_ROUTES];

  const goToPreviousPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    setActiveModule(allRoutes[0]);
  }, []);

  useEffect(() => {
    const module = allRoutes.find(({ href }) => {
      const routes = [{ path: href }];
      return !!matchRoutes(routes, location);
    });

    setActiveModule(module);
  }, [pathname]);

  const hideHeader = pathname === '/invalid-addresses' || pathname === '/blank-addresses';

  if (hideHeader) {
    return null;
  }

  const labelsWithBackButton = [
    'Customer Details',
    'Transaction Details',
    'Cleanup Invalid Addresses',
    'Blank Addresses',
    'Integrations',
  ];

  return (
    <Flex height={'3rem'} justify={'space-between'} alignItems="center">
      <HStack>
        {labelsWithBackButton.includes(activeModule?.label as string) && (
          <button onClick={goToPreviousPage}>
            <ArrowLeft />
          </button>
        )}
        <Text fontWeight={'500'} fontSize={'20px'}>
          {activeModule?.label}
        </Text>
      </HStack>
    </Flex>
  );
};

export default AppHeaderSm;
