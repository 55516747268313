import { FilterPopover } from 'components/filter';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { CustomerFilter } from './customer-filter';

export const CustomerPopover = () => {
  const hasFilter = useSelector(({ customers: { filter } }: RootState) => Object.values(filter.query).some(Boolean));
  const filters = useSelector(({ customers }: RootState) => customers.filter.query);

  return <FilterPopover hasFilter={hasFilter} filters={filters} content={CustomerFilter} />;
};
