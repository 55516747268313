import { useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getAllRegistrations, REGISTRATION_STATE_KEY } from 'apis/registration-apis';
import { usePaywall } from 'app/acl/paywall';
import Button from 'component-library/components/ButtonTmp/button';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { useOrg } from 'hooks/useOrg';
import { RegistrationStatusEnum } from 'schema/types-schema.d';
import { FilingStateOption } from 'types/shared-types';

import { FilingForm } from './filing-form';

export const CreateFiling = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { orgId } = useOrg();
  const { isSubscriptionPausedOrCanceled } = usePaywall();

  const { isLoading, data = [] } = useQuery({
    queryKey: [REGISTRATION_STATE_KEY, 'filing', 'registered', orgId],
    queryFn: async () => {
      const res = await getAllRegistrations({
        orgId,
        filter: {
          status: [RegistrationStatusEnum.REGISTERED],
        },
      });
      return res;
    },
    select: data => {
      return data.map(
        ({ id, state_name, state_code, registration_date }) =>
          ({
            registration_id: id,
            state_name,
            state_code,
            registration_date,
          }) as FilingStateOption
      );
    },
    enabled: !isSubscriptionPausedOrCanceled,
  });

  const isDataEmpty = data?.length === 0;

  return (
    <>
      <Tooltip
        shouldWrapChildren
        label={
          isSubscriptionPausedOrCanceled
            ? 'You need to activate subscription for de-register'
            : 'Please register in a jurisdiction to start filing'
        }
        isDisabled={!isSubscriptionPausedOrCanceled && !isDataEmpty}
      >
        <Button
          isLoading={isLoading}
          variant={'solid'}
          color={'primary'}
          width={'100px'}
          onClick={onOpen}
          isDisabled={isDataEmpty || isSubscriptionPausedOrCanceled}
        >
          New
        </Button>
      </Tooltip>
      {!isLoading && !isSubscriptionPausedOrCanceled && (
        <FilingForm isOpen={isOpen} onClose={onClose} registrationStates={data} />
      )}
    </>
  );
};
