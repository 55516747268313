import { createCustomMessage } from 'react-chatbot-kit';
import IConfig from 'react-chatbot-kit/build/src/interfaces/IConfig';

import AnswerMsg from './components/answer-msg';
import BotAvatar from './components/bot-avatar';
import UserAvatar from './components/user-avatar';
import WelcomeMsg from './components/welcome-msg';

const botName = 'TaxGPT';

const config: IConfig = {
  initialMessages: [
    createCustomMessage('', 'welcome', {
      loading: false,
      delay: 0,
    }),
  ],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: '#f1f1f1',
    },
    chatButton: {
      backgroundColor: '#4285F4',
    },
  },
  state: {
    gist: '',
    infoBox: '',
  },
  customMessages: {
    welcome: props => <WelcomeMsg {...props} />,
    answer: props => <AnswerMsg {...props} />,
  },
  customComponents: {
    header: () => (
      <div
        style={{
          color: 'white',
          background: 'linear-gradient(90deg, #04C3CC -0.81%, #527BE4 40.47%, #A373EB 100%)',
          padding: '12px 16px',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          fontWeight: '500',
        }}
      >
        TaxGPT
      </div>
    ),
    botAvatar: (props: any) => <BotAvatar {...props} />,
    userAvatar: props => <UserAvatar {...props} />,
  },
};

export default config;
