import { useQuery } from '@tanstack/react-query';

import api from './api';

export const CUSTOMERS_STATE_KEY = 'customers';

export const useGetCustomerById = (orgId: string, customerId: string) => {
  return useQuery({
    queryKey: [CUSTOMERS_STATE_KEY, orgId, customerId],
    queryFn: async () => {
      try {
        const { data } = await api.get(`/v1/customers/${customerId}`, {
          headers: {
            'x-organization-id': orgId,
          },
        });
        return data;
      } catch (error) {
        console.error('Error fetching customer details:', error);
        return [];
      }
    },
  });
};

export const useGetTransactionsById = (orgId: string, customerId: string) => {
  return useQuery({
    queryKey: [CUSTOMERS_STATE_KEY, orgId, customerId, 'transactions'],
    queryFn: async () => {
      try {
        const { data } = await api.get(`/v1/customers/${customerId}/transactions`, {
          headers: {
            'x-organization-id': orgId,
          },
        });
        return data;
      } catch (error) {
        console.error('Error fetching transactions:', error);
        return [];
      }
    },
  });
};

export const getCustomers = (
  orgId: string,
  page?: number,
  size?: number,
  filter?: object,
  order?: object,
  search_query?: string
) => {
  return api.get(`/v1/customers`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      page: page,
      size: size,
      ...filter,
      ...order,
      search_query,
    },
  });
};

export const getCustomerForId = (orgId: string, customerId: string) => {
  return api.get(`/v1/customers/${customerId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};
