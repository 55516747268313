import { Alert, Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';

const StyledBox = styled(Box)(() => ({
  maxHeight: '50vh',
  overflow: 'auto',
}));

type Props = {
  error: Error;
  componentStack: string;
};

export const ErrorFallback = ({ error, componentStack }: Props) => {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <StyledBox>
      <Alert status="error">Something went wrong!!!</Alert>
      <code>
        {import.meta.env.DEV && (
          <>
            <Box p={4}>{error?.message}</Box>
            <Box p={4}>{componentStack}</Box>
          </>
        )}
      </code>
    </StyledBox>
  );
};

export const { ErrorBoundary } = Sentry;
