import { Checkbox, HStack, Stack, Text } from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import DatePicker from 'component-library/components/date-picker/date-picker';
import Select from 'component-library/components/Select/Select';
import { CountryOptions, USStates } from 'constants/app-constants';
import useQueryParam from 'hooks/useQueryParam';
import { useEffect, useState } from 'react';
import { TaxDetailsFilterProps } from 'types/shared-types';

const TaxDetailsFilter = ({ onClose, isOpen }: any) => {
  const [filter, setFilter] = useState<TaxDetailsFilterProps>({
    state: '',
    country: '',
    startDate: '',
    endDate: '',
    marketplace: false,
  });
  const { state, country, startDate, endDate, marketplace } = filter || {};
  const [qState, setSearchParams] = useQueryParam('state', '');
  const [qCountry, setCountryParams] = useQueryParam('country', '');
  const [qStartDate] = useQueryParam('startDate', '');
  const [qEndDate] = useQueryParam('endDate', '');
  const [qMarketplace] = useQueryParam('marketplace');

  useEffect(() => {
    setFilter({
      state: qState,
      country: qCountry,
      startDate: qStartDate,
      endDate: qEndDate,
      marketplace: qMarketplace === 'true',
    });
  }, [isOpen]);

  const handleSave = () => {
    setSearchParams({ ...filter });
    setCountryParams({ ...filter });
    setFilter({
      state: qState,
      country: qCountry,
      startDate: qStartDate,
      endDate: qEndDate,
      marketplace: qMarketplace === 'true',
    });
    onClose();
  };

  const handleClear = () => {
    setSearchParams({});
    setFilter({
      state: '',
      country: '',
      startDate: '',
      endDate: '',
      marketplace: false,
    });
    onClose();
  };

  const setSelectedState = (newSelectedState: string) => {
    setFilter({ ...filter, state: newSelectedState });
  };

  const setSelectedCountry = (newSelectedCountry: string) => {
    setFilter({ ...filter, country: newSelectedCountry });
  };

  const setStartDate = (newStartDate: string) => {
    setFilter({ ...filter, startDate: newStartDate });
  };

  const setEndDate = (newEndDate: string) => {
    setFilter({ ...filter, endDate: newEndDate });
  };

  const setMarketPlace = (newMarketPlace: boolean) => {
    setFilter({ ...filter, marketplace: newMarketPlace });
  };

  return (
    <Stack spacing={'16px'}>
      <HStack justifyContent={'space-between'}>
        <Text fontSize={'18px'} fontWeight={500}>
          Filters
        </Text>
        <Text fontSize={'12px'} color={'#4285F4'} cursor={'pointer'} onClick={handleClear}>
          Clear All Filters
        </Text>
      </HStack>
      <Stack align="start" spacing={4}>
        <Text fontWeight={500}>Country</Text>
        <Select
          isDisabled={false}
          placeholder="Select Country"
          value={country || ''}
          onChange={e => setSelectedCountry(e.target.value)}
        >
          {CountryOptions.map(({ label, value }) => (
            <>
              <option key={value} value={value}>
                {label}
              </option>
            </>
          ))}
        </Select>
      </Stack>
      <Stack align="start" spacing={4}>
        <Text fontWeight={500}>Jurisdiction</Text>
        <Select value={state || ''} onChange={e => setSelectedState(e.target.value)} placeholder="Select an option">
          {USStates.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </Stack>
      <Stack display="none">
        <Text fontWeight={500}>Date</Text>
        <Text fontWeight={500}>Start Date</Text>
        <DatePicker
          selected={startDate}
          onChange={date => {
            setStartDate(date);
          }}
        />
        <Text fontWeight={500}>End Date</Text>
        <DatePicker
          selected={endDate}
          onChange={date => {
            setEndDate(date);
          }}
        />
      </Stack>
      <Stack>
        <Checkbox isChecked={marketplace} onChange={e => setMarketPlace(e.target.checked)}>
          Hide Marketplace
        </Checkbox>
      </Stack>
      <HStack alignItems={'center'} direction="row" justifyContent={'space-around'} spacing="18px">
        <Button variant="outline" color="secondary" onClick={onClose} width={'132px'} height={'32px'}>
          Cancel
        </Button>
        <Button variant="solid" color="primary" onClick={handleSave} width={'132px'} height={'32px'}>
          Apply
        </Button>
      </HStack>
    </Stack>
  );
};

export default TaxDetailsFilter;
