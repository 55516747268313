import {
  AccountingModel,
  EntityType,
  ExemptionStatus,
  FilingFrequencyEnum,
  ProductCategoryEnum,
  ProductStatusEnum,
  Source,
} from 'types/shared-types';
import { capitalizeFirstLetter, replaceUnderscoreWithSpace } from 'utils';

export const getFilingFreq = (category: FilingFrequencyEnum): string => {
  switch (category) {
    case FilingFrequencyEnum.MONTHLY:
      return 'Monthly';
    case FilingFrequencyEnum.QUARTERLY:
      return 'Quarterly';
    case FilingFrequencyEnum.SEMI_ANNUALLY:
      return 'Semi-annually';
    case FilingFrequencyEnum.ANNUALLY:
      return 'Annually';
    default:
      return '';
  }
};

export const getSource = (category: Source): string => {
  switch (category) {
    case Source.CHARGEBEE:
      return 'Chargebee';
    case Source.SHOPIFY:
      return 'Shopify';
    case Source.STRIPE:
      return 'Stripe';
    case Source.QUICKBOOKS:
      return 'QuickBooks';
    default:
      return category;
  }
};

export const getCategory = (category: ProductCategoryEnum): string => {
  switch (category) {
    case ProductCategoryEnum.UNKNOWN:
      return 'Unknown';
    case ProductCategoryEnum.DIGITAL:
      return 'Digital';
    case ProductCategoryEnum.SERVICE:
      return 'Service';
    case ProductCategoryEnum.MISCELLANEOUS:
      return 'Miscellaneous';
    default:
      return 'Physical';
  }
};

export const getProductStatus = (category: ProductStatusEnum): string => {
  switch (category) {
    case ProductStatusEnum.APPROVED:
      return 'Approved';
    case ProductStatusEnum.PARTIALLY_APPROVED:
      return 'Partially Approved';
    case ProductStatusEnum.PENDING:
      return 'Pending';
    default:
      return 'null';
  }
};

export const getExemption = (category: ExemptionStatus): string => {
  switch (category) {
    case ExemptionStatus.ACTIVE:
      return 'ACTIVE';
    case ExemptionStatus.INACTIVE:
      return 'INACTIVE';
    case ExemptionStatus.EXPIRED:
      return 'EXPIRED';
    default:
      return 'NULL';
  }
};

/**
 * Convert enum to human readable string
 * @param status
 * @returns string
 */
export const getHumanReadableString = <T>(status: T): string => {
  if (!status || Array.isArray(status) || status === 'object') return '';
  if (/_/.test(status as string)) {
    return capitalizeFirstLetter(replaceUnderscoreWithSpace(status as string));
  }
  return capitalizeFirstLetter(status as string);
};

type EntityTypeOptions = { label: string; value: string };

export const entityTypes: EntityTypeOptions[] = [
  { value: EntityType.C_CORPORATION, label: 'C Corporation (C Corp)' },
  { value: EntityType.COOPERATIVE_CO_OP, label: 'Cooperative (Co-op)' },
  { value: EntityType.GENERAL_PARTNERSHIP, label: 'General Partnership (GP)' },
  { value: EntityType.HYBRID_LLC, label: 'Hybrid LLC' },
  { value: EntityType.JOINT_VENTURE, label: 'Joint Venture' },
  { value: EntityType.LIMITED_LIABILITY_LIMITED_PARTNERSHIP, label: 'Limited Liability Limited Partnership (LLLP)' },
  { value: EntityType.LIMITED_LIABILITY_PARTNERSHIP, label: 'Limited Liability Partnership (LLP)' },
  { value: EntityType.LIMITED_PARTNERSHIP, label: 'Limited Partnership (LP)' },
  { value: EntityType.LLC_TAXED_AS_C_CORPORATION, label: 'LLC taxed as a C Corporation' },
  { value: EntityType.LLC_TAXED_AS_S_CORPORATION, label: 'LLC taxed as a S Corporation' },
  { value: EntityType.MULTI_MEMBER_LLC, label: 'Multi-Member LLC' },
  { value: EntityType.NONPROFIT, label: 'Nonprofit' },
  { value: EntityType.PROFESSIONAL_LLC, label: 'Professional LLC (PLLC)' },
  { value: EntityType.SINGLE_MEMBER_LLC, label: 'Single-Member LLC' },
  { value: EntityType.S_CORPORATION, label: 'S Corporation (S Corp)' },
  { value: EntityType.SOLE_PROPRIETORSHIP, label: 'Sole Proprietorship' },
  { value: EntityType.LLC, label: 'Limited Liability Company (LLC)' },
];

type AccountingModelTypeOptions = { label: string; value: string };

export const accountingModel: AccountingModelTypeOptions[] = [
  { value: AccountingModel.ACCRUAL, label: 'Accrual' },
  { value: AccountingModel.CASH, label: 'Cash' },
];
