import { useDisclosure } from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import React from 'react';
import { useParams } from 'react-router-dom';

import { ExemptionsForm } from './exemptions-form';

export const CreateExemption = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id = '' } = useParams<{ id: string }>();

  return (
    <>
      <Button variant={'solid'} color={'primary'} onClick={onOpen}>
        Add Exemption
      </Button>
      {isOpen && <ExemptionsForm isOpen={isOpen} onClose={onClose} customerId={id} />}
    </>
  );
};
