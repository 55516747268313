import { Badge, Box, Heading, HStack, IconButton, Stack, Tooltip } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getTransactionForId, TRANSACTION_STATE_KEY } from 'apis/transaction-api';
import Text from 'component-library/components/Text';
import { ArrowLeft, MarketplaceIcon } from 'components/icons';
import { transactionStatusVariantMap } from 'constants/transactions';
import { useOrg } from 'hooks/useOrg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toDateShort } from 'utils/dates';

export const TransactionDetailsHeaderLabel = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const { orgId } = useOrg();

  const {
    isPending,
    data: transactionDetails,
    isError,
  } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, id, orgId],
    queryFn: async () => {
      const { data } = await getTransactionForId(orgId, id);
      return data;
    },
  });

  if (isPending || !transactionDetails) {
    return null;
  }

  if (isError) {
    console.log('Error fetching transaction details');
    return null;
  }

  const status = transactionDetails.status;
  const { external_id, marketplace, date, customer_id, type } = transactionDetails;
  const statusVariant = transactionStatusVariantMap[status];

  if (!statusVariant?.variant) {
    return null;
  }

  return (
    <HStack alignItems={'center'} mt={'16px'}>
      <Stack gap={1}>
        <HStack alignContent={'center'}>
          <IconButton aria-label="back" variant={'ghost'} rounded={4} onClick={() => navigate('/transactions')}>
            <ArrowLeft />
          </IconButton>
          <Heading as={'h1'} fontSize={'20px'}>
            Transaction Details
          </Heading>
          {type !== 'SALE' && <Badge variant={'secondary'}>Credit Note</Badge>}
          <Badge variant={statusVariant.variant}>{statusVariant.title}</Badge>
          {marketplace && (
            <Tooltip
              label={`A Marketplace transactions is a transaction where the tax is paid by the marketplace directly`}
              shouldWrapChildren
              hasArrow
            >
              <MarketplaceIcon cursor={'pointer'} />
            </Tooltip>
          )}
        </HStack>
        <HStack direction={'row'} alignItems={'center'} ml={'50px'}>
          <Text fontSize={'sm'} color={'gray.500'}>
            ID: {external_id}
          </Text>
          <Box boxSize={'4px'} rounded={'xl'} bgColor={'gray.500'} />
          <Link to={`/customers/${customer_id}`}>
            <Text fontSize={'sm'} color={'gray.500'} textDecoration={'underline'} textUnderlineOffset={2}>
              {customer_id}
            </Text>
          </Link>
          <Box boxSize={'4px'} rounded={'xl'} bgColor={'gray.500'} />
          <Text fontSize={'sm'} color={'gray.500'}>
            {toDateShort(new Date(date))}
          </Text>
        </HStack>
      </Stack>
    </HStack>
  );
};
