import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getExemptions } from 'apis/exemption-apis';
import { ExemptionInstance } from 'types/shared-types';

const exemptionsAdapter = createEntityAdapter<ExemptionInstance>();

const initialState = exemptionsAdapter.getInitialState({
  loading: false,
  // move activePage into react component as its an ui state
  activePage: {
    pages: 0,
  },
  // find some generic way to store pagination data
  // https://redux-toolkit.js.org/rtk-query/usage/pagination
  filter: {
    query: {},
    resultIds: [],
  },
  order_by: {
    order_by: 'end_date,FEIN,sales_tax_id,status',
  },
});

export const fetchExemptions = createAsyncThunk('exemptions/fetchExemptions', async (params: any) => {
  const response = await getExemptions(params.orgId, params.status, params.page, params.size);
  return response?.data;
});

const exemptionsSlice = createSlice({
  name: 'exemptions',
  initialState: initialState,
  reducers: {
    storeFilter(state, action): any {
      state.filter.query = action.payload;
    },
    storeOrder(state, action): any {
      state.order_by = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchExemptions.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (Array.isArray(payload.items)) {
        exemptionsAdapter.upsertMany(state, payload.items);
        state.activePage = { ...payload };
        state.filter.resultIds = payload.items.map(({ id }: { id: string }) => id);
      }
    });
  },
});

export const { storeFilter, storeOrder } = exemptionsSlice.actions;
export default exemptionsSlice.reducer;
