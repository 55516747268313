import { useLogoutFunction } from '@propelauth/react';
import { useEffect, useState } from 'react';

const SELECTED_ORG_KEY = 'selectedOrg';

type Organization = {
  orgId: string;
  name: string;
  externalId: string;
  isTest: boolean;
};

const useOrg = () => {
  const forceLogout = useLogoutFunction();
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(() => {
    try {
      const storedOrg = localStorage.getItem(SELECTED_ORG_KEY);
      return storedOrg ? (JSON.parse(storedOrg) as Organization) : null;
    } catch (error) {
      localStorage.clear();
      forceLogout(true);
      return null;
    }
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const newStoredOrg = localStorage.getItem(SELECTED_ORG_KEY);
      const newOrg = newStoredOrg ? (JSON.parse(newStoredOrg) as Organization) : null;
      setSelectedOrg(newOrg);
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    selectedOrg ?? {
      orgId: '',
      name: '',
      externalId: '',
      isTest: false,
    }
  );
};

const setOrg = (org: Organization) => {
  localStorage.setItem(SELECTED_ORG_KEY, JSON.stringify(org));
  window.dispatchEvent(new Event('storage'));
};

export { type Organization, SELECTED_ORG_KEY, setOrg, useOrg };
