import { Button, MenuProps } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

import Menu from './';
import MenuButton from './MenuButton';
import MenuList from './MenuList';

type MenuDropDownProps = Omit<MenuProps, 'children'> & {
  trigger: React.ReactNode;
  itemList: React.ReactNode[];
};

const MenuDropDown = ({ trigger, itemList, ...rest }: MenuDropDownProps) => {
  return (
    <Menu {...rest}>
      <MenuButton width={6} height={6} as={Button} variant={'transparent-with-icon'}>
        {trigger}
      </MenuButton>
      <MenuList>{itemList}</MenuList>
    </Menu>
  );
};

export default forwardRef(MenuDropDown);
