import api from './api';

const getExemptions = (orgId: string, page: number, size: number, filter: object, order: object) => {
  return api.get(`/v1/exemptions`, {
    method: 'GET',
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      page: page,
      size: size,
      ...filter,
      ...order,
    },
  });
};

const EXEMPTIONS_STATE_KEY = 'exemptions';

export { EXEMPTIONS_STATE_KEY, getExemptions };
