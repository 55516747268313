import { Input, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react';
import { MdArrowDropDown } from 'react-icons/md';
import { KCustomSelectMainInputProps } from 'types/kCustomSelectType';

export const MainInput = ({
  title = '',
  isOpen,
  onOpen,
  placeholder,
  isLoading,
  isDisabled,
  ...rest
}: KCustomSelectMainInputProps) => {
  const activeColor = '#4285F4';
  let dropdownLeftIconColor = '#383D58';
  if (isDisabled) {
    dropdownLeftIconColor = '#B7BAC5'; // disabled color
  } else if (isOpen) {
    dropdownLeftIconColor = activeColor; // active color
  }

  return (
    <InputGroup>
      <Input
        placeholder={placeholder}
        value={title}
        onClick={onOpen}
        onChange={() => {}}
        cursor={'default'}
        borderColor={isOpen ? activeColor : '#CFD0D899'}
        disabled={isLoading || isDisabled}
        {...rest}
      />
      <InputRightElement>
        {isLoading ? (
          <Spinner size="xs" color="#4285F4" />
        ) : (
          <MdArrowDropDown color={dropdownLeftIconColor} fontSize={'20px'} />
        )}
      </InputRightElement>
    </InputGroup>
  );
};
