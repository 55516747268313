import { HStack, Stack, Text } from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import DatePicker from 'component-library/components/date-picker/date-picker';
import Select from 'component-library/components/Select/Select';
import { CountryOptions, USStates } from 'constants/app-constants';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';

type FilingFilterProps = {
  tableFilters: UseTableFiltersType;
  onClose: () => void;
};

const FilingFilter = ({ onClose, tableFilters }: FilingFilterProps) => {
  const { start_date, end_date, state_code, country_code, setFilters, resetFilters } = tableFilters;

  const [startDate, setStartDate] = useState<string | undefined>(start_date);
  const [endDate, setEndDate] = useState<string | undefined>(end_date);
  const [selectedState, setSelectedState] = useState<string | undefined>(state_code);
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(country_code);

  const handleSave = () => {
    setFilters({
      state_code: selectedState || undefined,
      start_date: startDate || undefined,
      end_date: endDate || undefined,
      country_code: selectedCountry || undefined,
    });

    onClose();
  };

  const handleClear = () => {
    resetFilters();
    onClose();
  };

  const hasFilter = selectedState || startDate || endDate || selectedCountry;

  return (
    <Stack spacing={'16px'}>
      <HStack justifyContent={'space-between'}>
        <Text fontSize={'18px'} fontWeight="medium">
          Filters
        </Text>
        <Text fontSize={'12px'} color={'#4285F4'} cursor={'pointer'} onClick={handleClear}>
          Clear All Filters
        </Text>
      </HStack>
      <Stack align="start" spacing={4}>
        <Text fontWeight="medium">Country</Text>
        <Select
          value={selectedCountry}
          onChange={e => setSelectedCountry(e.target.value)}
          placeholder="Select an option"
        >
          {CountryOptions.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </Stack>
      <Stack align="start" spacing={4}>
        <Text fontWeight="medium">Jurisdiction</Text>
        <Select value={selectedState} onChange={e => setSelectedState(e.target.value)} placeholder="Select an option">
          {USStates.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Stack>
      <Stack>
        <Text fontWeight="medium">Date</Text>
        <Text fontWeight="medium">Start Date</Text>
        <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
        <Text fontWeight="medium">End Date</Text>
        <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
      </Stack>

      <HStack direction="row" justifyContent={'space-around'} spacing="18px">
        <Button variant="outline" color="secondary" onClick={onClose} width={'132px'} height={'32px'}>
          Cancel
        </Button>
        <Button
          variant="solid"
          color="primary"
          onClick={handleSave}
          width={'132px'}
          height={'32px'}
          isDisabled={!hasFilter}
        >
          Apply
        </Button>
      </HStack>
    </Stack>
  );
};

export default FilingFilter;
