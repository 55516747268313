import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FilingInstance } from 'types/shared-types';

const filingsAdapter = createEntityAdapter<FilingInstance>();

const initialState = filingsAdapter.getInitialState({
  loading: false,
  activePage: {
    pages: 0,
  },
  filter: {
    query: {},
    resultIds: [],
  },
  order_by: {
    order_by: 'start_date,end_date,amount',
  },
});

const filingsSlice = createSlice({
  name: 'filings',
  initialState: initialState,
  reducers: {
    storeFilter(state, action): any {
      state.filter.query = action.payload;
    },
    storeOrder(state, action): any {
      state.order_by = action.payload;
    },
  },
});

export const { storeFilter, storeOrder } = filingsSlice.actions;
export default filingsSlice.reducer;
