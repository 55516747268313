import { extendTheme, ThemeComponents, type ThemeConfig } from '@chakra-ui/react';

import { breakpoints } from './foundations/breakpoints';
import { colors } from './foundations/colors';
import { shadows } from './foundations/shadows';
import { typography } from './foundations/typography';
import { globalStyles } from './styles';
import { accordionTheme } from './styles/accordion';
import { alertTheme } from './styles/alert';
import { badgeStyles } from './styles/badge';
import { buttonStyles } from './styles/button';
import { cardTheme } from './styles/card';
import { checkboxTheme } from './styles/checkbox';
import { datePicker } from './styles/date-picker';
import { formLabelTheme } from './styles/formLabel';
import { headingStyles } from './styles/heading';
import { inputTheme } from './styles/input';
import { menuTheme } from './styles/menu';
import { modalTheme } from './styles/modal';
import { popoverTheme } from './styles/popover';
import { ProgressStyles } from './styles/progress';
import { selectTheme } from './styles/select';
import { switchTheme } from './styles/switch';
import { tabTheme } from './styles/tab';
import { tableTheme } from './styles/table';
import { textStyles } from './styles/text';
import { tooltipStyles } from './styles/tooltip';

export const theme = extendTheme({
  ...typography,
  colors,
  breakpoints,
  globalStyles,
  shadows,
  components: {
    DatePicker: datePicker,
    Badge: badgeStyles,
    Button: buttonStyles,
    Card: cardTheme,
    Heading: headingStyles,
    Text: textStyles,
    Progress: ProgressStyles,
    Menu: menuTheme,
    Modal: modalTheme,
    Table: tableTheme,
    Input: inputTheme,
    Select: selectTheme,
    Switch: switchTheme,
    FormLabel: formLabelTheme,
    Tabs: tabTheme,
    Popover: popoverTheme,
    Accordion: accordionTheme,
    Checkbox: checkboxTheme,
    Alert: alertTheme,
    Tooltip: tooltipStyles,
  } as ThemeComponents,
  config: {
    cssVarPrefix: 'ks',
    initialColorMode: 'light',
    useSystemColorMode: false,
  } as ThemeConfig,
});
