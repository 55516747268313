import { Flex, Skeleton, useDisclosure, VStack } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FILINGS_STATE_KEY, getFilingForId, updateFilingStatus } from 'apis/filing-apis';
import Button from 'component-library/components/ButtonTmp/button';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import React, { useEffect } from 'react';
import { FilingDetailsInstance, FilingStatus } from 'types/shared-types';

import FilingDetails from './filing-details';

const FilingRegistration: React.FC = () => {
  const { isOpen, onOpen, onClose: closeModal } = useDisclosure();
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const [filingId, setSearchParams] = useQueryParam('filingId');
  const [editFiling] = useQueryParam('editFiling');
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();

  const onClose = () => {
    setSearchParams({});
    closeModal();
  };

  const { isLoading, data, refetch } = useQuery({
    enabled: false,
    queryKey: [FILINGS_STATE_KEY, filingId],
    queryFn: async () => {
      try {
        const res = await getFilingForId(orgId, filingId);
        return res?.data || {};
      } catch (error) {
        handleFailNotification(error);
      }
    },
  });

  useEffect(() => {
    if (!isOpen && editFiling && filingId) {
      onOpen();
      refetch();
    }
  }, [editFiling]);

  const { id, start_date, end_date, can_file } = (data || {}) as FilingDetailsInstance;

  const mutation = useMutation({
    mutationFn: () => {
      return updateFilingStatus(orgId, id || '', {
        start_date,
        end_date,
        status: FilingStatus.PROCESSING,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FILINGS_STATE_KEY] });
      onClose();
      handleSuccessNotification('Filing updated successfully.');
    },
    onError: (error: any) => {
      handleFailNotification(error);
    },
  });

  return (
    <ModalPopup
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      size={'lg'}
      isOpen={isOpen}
      onClose={onClose}
      header="Filing Details"
      footer={
        <Flex gap={4}>
          <Button variant={'outline'} color={'secondary'} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={!can_file}
            isLoading={mutation.isPending}
            variant={'solid'}
            color={'primary'}
            width={'90px'}
            onClick={() => mutation.mutate()}
          >
            File
          </Button>
        </Flex>
      }
    >
      {isLoading ? (
        <VStack spacing={4}>
          {Array.from({ length: 3 }, (_, index) => (
            <Skeleton key={index} height="30px" width="full" />
          ))}
        </VStack>
      ) : (
        <FilingDetails data={data} shouldShowSubheader={true} />
      )}
    </ModalPopup>
  );
};

export default FilingRegistration;
