import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import TableContainer from 'component-library/components/Table/table-container';
import { USStatesByCode } from 'constants/app-constants';
import ExemptionBadge from 'pages/Exemptions/components/exemptionStateBadge';
import { toDateShort } from 'utils/dates';

import { ExemptionAction } from '../../../Exemptions/components/exemption-action';

export const ExemptionTable = ({ captions, data }: any) => {
  return (
    <TableContainer>
      <Table variant="unstyled">
        <Thead>
          <Tr my=".8rem" pl="0px">
            {captions.map((caption: any, idx: any) => {
              return <Th key={idx}>{caption}</Th>;
            })}
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.map((row: any) => (
              <ExemptionTableRow
                key={row.id}
                id={row.id !== null ? row.id : ''}
                jurisdiction={row.jurisdiction !== null ? USStatesByCode[row.jurisdiction].label : ''}
                start_date={row.start_date !== null ? row.start_date : ''}
                end_date={row.end_date !== null ? row.end_date : ''}
                FEIN={row.FEIN !== null ? row.FEIN : ''}
                sales_tax_id={row.sales_tax_id !== null ? row.sales_tax_id : ''}
                status={row.status !== null ? row.status : ''}
                exemption={row}
              />
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const ExemptionTableRow = ({ jurisdiction, start_date, end_date, FEIN, sales_tax_id, status, exemption }: any) => {
  return (
    <Tr>
      <Td width={{ sm: '150px' }} pl="20px">
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{jurisdiction}</Text>
        </Flex>
      </Td>
      <Td width={{ sm: '150px' }} pl="20px">
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{toDateShort(start_date)}</Text>
        </Flex>
      </Td>
      <Td width={{ sm: '150px' }} pl="20px">
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{toDateShort(end_date)}</Text>
        </Flex>
      </Td>
      <Td width={{ sm: '150px' }} pl="20px">
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{FEIN}</Text>
        </Flex>
      </Td>
      <Td width={{ sm: '80px' }} pl="20px">
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{sales_tax_id}</Text>
        </Flex>
      </Td>
      <Td width={{ sm: '80px' }} pl="20px">
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap" justifyContent={'space-between'}>
          <ExemptionBadge status={status} />
          <ExemptionAction exemption={exemption} />
        </Flex>
      </Td>
    </Tr>
  );
};
