import { Flex } from '@chakra-ui/react';
import { CreateFiling } from 'pages/Filing/components/create-filing';
import { DownloadFiling } from 'pages/Filing/components/download-filing';
import FilingHistoryButton from 'pages/Filing/components/history-filings/filing-history-button';

export const FilingTrackingToolbar = () => {
  return (
    <Flex gap={3} alignItems={'top'}>
      <DownloadFiling />
      <FilingHistoryButton />
      <CreateFiling />
    </Flex>
  );
};
