import { HStack, Stack, Text } from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import Select from 'component-library/components/Select/Select';
import { CountryOptions, USStates } from 'constants/app-constants';
import { useState } from 'react';
import { storeFilter } from 'redux/slices/customers';
import { useAppDispatch } from 'redux/store';

export const CustomerFilter = ({ onClose, filters }: any) => {
  const dispatch = useAppDispatch();
  const [selectedState, setSelectedState] = useState<string>(filters?.state || '');
  const [selectedCountry, setSelectedCountry] = useState<string>(filters?.country || '');

  const handleSave = () => {
    const filterParams = {
      country: selectedCountry || null,
      state: selectedState || null,
    };
    dispatch(storeFilter({ ...filterParams }));
    onClose();
  };

  const handleClear = () => {
    setSelectedCountry('');
    setSelectedState('');
    dispatch(storeFilter({}));
    onClose();
  };

  return (
    <Stack spacing={'16px'}>
      <HStack justifyContent={'space-between'}>
        <Text fontSize={'18px'} fontWeight={500}>
          Filters
        </Text>
        <Text fontSize={'12px'} color={'#4285F4'} cursor={'pointer'} onClick={handleClear}>
          Clear All Filters
        </Text>
      </HStack>
      <Stack>
        <Text fontWeight={500}>Country</Text>
        <Select id="country" name="country" value={selectedCountry} onChange={e => setSelectedCountry(e.target.value)}>
          <>
            <option value="">Select country</option>
            {CountryOptions.map(({ label, value }) => (
              <>
                <option key={value} value={value}>
                  {label}
                </option>
              </>
            ))}
          </>
        </Select>
      </Stack>
      <Stack>
        <Text fontWeight={500}>State</Text>
        <Select
          id="state_code"
          name="state_code"
          value={selectedState}
          onChange={e => setSelectedState(e.target.value)}
          isDisabled={selectedCountry !== '' && selectedCountry !== 'US'}
        >
          <>
            <option value="">Select state</option>
            {USStates.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </>
        </Select>
      </Stack>

      <HStack direction="row" justifyContent={'space-around'} spacing="18px">
        <Button variant="outline" color="secondary" onClick={onClose} width={'132px'} height={'32px'}>
          Cancel
        </Button>
        <Button variant="solid" color="primary" onClick={handleSave} width={'132px'} height={'32px'}>
          Apply
        </Button>
      </HStack>
    </Stack>
  );
};
