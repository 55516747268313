import Text from 'component-library/components/Text';

const FormErrorMessage = ({ message = '' }: { message?: string }) => {
  return (
    <Text color="#E53E3E" fontSize="sm" pt={2}>
      {message}
    </Text>
  );
};

export default FormErrorMessage;
