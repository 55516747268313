import { ProductCategoryRead } from 'types/products';

import api from './api';

const getProductById = (product_id: string, orgId: string) => {
  return api.get(`/v1/products/${product_id}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getProducts = ({
  orgId,
  page,
  size,
  filter,
  order,
  query,
}: {
  orgId: string;
  page?: number;
  size?: number;
  filter?: object;
  order?: object;
  query?: string;
}) => {
  return api.get(`/v1/products/`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      page,
      size,
      ...filter,
      ...order,
      query,
    },
  });
};

const createProduct = (orgId: string, payload: any) => {
  return api.post(`/v1/products/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateProducts = (product_id: string, orgId: string, payload: Record<any, any>) => {
  return api.put(`/v1/products/${product_id}`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getProductCategories = async (orgId: string) => {
  return api.get<ProductCategoryRead[]>(`/v1/products/categories/`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const bulkClassifyProducts = (orgId: string) => {
  return api.post(`/v1/products/bulk_classify/`, null, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const bulkApproveProducts = (orgId: string, payload: any) => {
  return api.put(`/v1/products/bulk_approve/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateProductClassificationConfig = (orgId: string, payload: any) => {
  return api.put(`/v1/products/config/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getProductClassificationConfig = (orgId: string) => {
  return api.get(`/v1/products/config/`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getProductClassificationStatus = (orgId: string) => {
  return api.get(`/v1/products/classification_status/`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const exportProducts = (orgId: string) => {
  return api.post(`/v1/products/reports/export`, null, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const PRODUCT_STATE_KEY = 'product';
const PRODUCT_CATEGORIES_STATE_KEY = 'product-categories';
const PRODUCT_CLASSIFICATION_STATE_KEY = 'product-classification';

export {
  bulkApproveProducts,
  bulkClassifyProducts,
  createProduct,
  exportProducts,
  getProductById,
  getProductCategories,
  getProductClassificationConfig,
  getProductClassificationStatus,
  getProducts,
  PRODUCT_CATEGORIES_STATE_KEY,
  PRODUCT_CLASSIFICATION_STATE_KEY,
  PRODUCT_STATE_KEY,
  updateProductClassificationConfig,
  updateProducts,
};
