import React from 'react';

const MessageParser = ({ children, actions }: any) => {
  const parse = (question: string) => {
    if (!question) {
      actions.handleEmptyMessage(question);
    } else {
      actions.handleQuestion(question);
    }
  };

  return React.Children.map(children, child => {
    return React.cloneElement(child, {
      parse: parse,
      actions,
    });
  });
};

export default MessageParser;
