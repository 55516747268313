import { Flex, Skeleton, Text, VStack } from '@chakra-ui/react';
import { PaginationButtons } from 'components/pagination/pagination';
import { Organization } from 'hooks/useOrg';

import OrgItem from './org-item';

type OrganizationSelectorModalBodyProps = {
  orgId: string;
  isLoading: boolean;
  query: string | undefined;
  organizations: any[];
  onOrgSwitch: (org: Organization) => void;
  pagination: {
    page: number;
    pages: number;
    size: number;
    onPaginate: (page: number, size: number) => void;
  };
};

export const OrganizationSelectorModalBody = ({
  isLoading,
  orgId,
  query,
  organizations,
  onOrgSwitch,
  pagination: { pages, page, size, onPaginate },
}: OrganizationSelectorModalBodyProps) => {
  const sortedOrgs = [...organizations].sort((a, b) => {
    if (a.id === orgId) return -1; // Current organization comes first
    if (b.id === orgId) return 1; // Current organization comes first
    return 0; // Otherwise maintain the order
  });

  if (isLoading)
    return (
      <VStack gap={4} mt={4}>
        {Array.from({ length: pages > 1 ? size : 3 }, (_, index) => (
          <Skeleton key={index} height={20} width="full" />
        ))}
      </VStack>
    );

  if (!organizations || organizations?.length === 0) {
    return (
      <Flex
        data-testid="org-modal-empty-state"
        align="center"
        justify="center"
        direction="column"
        h="100%"
        w="100%"
        py="4rem"
        pb={'623px'}
        mt={4}
      >
        {query ? (
          <>
            <Text fontSize={22} fontWeight="semibold">
              No Results Found
            </Text>

            <Text mt={4} color={'#5A5E77'}>
              We couldn&apos;t find any matches. Try modifying your search or check for typos.
            </Text>
          </>
        ) : (
          <Text fontSize={22} fontWeight="semibold">
            Looks like there are no Organizations here
          </Text>
        )}
      </Flex>
    );
  }

  return (
    <>
      {organizations.length > 0 &&
        sortedOrgs.map((org: any, idx: number) => (
          <OrgItem key={`${org.id}_${idx}`} isCurrent={orgId === org.id} org={org} onOrgSwitch={onOrgSwitch} />
        ))}

      {pages > 1 && (
        <PaginationButtons
          size={size}
          currentPage={page}
          totalPages={pages}
          onPageClick={onPaginate}
          onSizeChange={onPaginate}
          styles={{
            justifyContent: 'center',
          }}
          options={[
            { value: 10, label: '10' },
            { value: 25, label: '25' },
            { value: 50, label: '50' },
          ]}
        />
      )}
    </>
  );
};
