import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    mr: '30px',
    p: '8px',
    w: '154px',
    borderRadius: '4px',
    border: 'none',
    bg: '#FFFFFF',
    boxShadow: '0px 8px 20px -4px rgba(23, 24, 24, 0.12), 0px 3px 6px -3px rgba(23, 24, 24, 0.08)',
  },
  item: {
    p: '8px',
    color: '#262B47',
    _hover: {
      bg: '#EFEFF3',
    },
    _disabled: {
      color: '#8A8E9F',
    },
  },
});

export const menuTheme = defineMultiStyleConfig({ baseStyle });
