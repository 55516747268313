import { Box } from '@chakra-ui/react';
import { CreateRegistration } from 'pages/Registrations/components/import-registration';

export const RegistrationTrackingToolbar = () => {
  return (
    <Box>
      <CreateRegistration />
    </Box>
  );
};
