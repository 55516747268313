import { defineStyleConfig } from '@chakra-ui/react';

export const headingStyles = defineStyleConfig({
  baseStyle: {
    fontSize: '20px',
    fontWeight: '500',
    color: '#262B47',
  },
  sizes: {
    xs: {
      fontSize: '18px',
      fontWeight: '500',
    },
    sm: {
      fontSize: '20px',
      fontWeight: '600',
    },
    md: {
      fontSize: '28px',
      fontWeight: '600',
    },
    lg: {
      fontSize: '36px',
      fontWeight: '600',
    },
    xl: {
      fontSize: '40px',
      fontWeight: '600',
    },
    '2xl': {
      fontSize: '48px',
      fontWeight: '600',
    },
    '3xl': {
      fontSize: '58px',
      fontWeight: '600',
    },
    '4xl': {
      fontSize: '64px',
      fontWeight: '600',
    },
  },
});
