import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const smSizeModal = definePartsStyle({
  header: {
    padding: '16px',
  },
  body: {
    padding: '0 16px 16px 16px',
  },
  footer: {
    padding: '16px',
  },
});

const mdSizeModal = definePartsStyle({
  header: {
    padding: '16px',
  },
  body: {
    padding: '0 16px 16px 16px',
  },
  footer: {
    padding: '16px',
  },
});

const lgSizeModal = definePartsStyle({
  header: {
    padding: '16px',
  },
  body: {
    padding: '0 16px 16px 16px',
  },
  footer: {
    padding: '16px',
  },
});

const xlSizeModal = definePartsStyle({
  header: {
    padding: '16px',
  },
  body: {
    padding: '0 16px 16px 16px',
  },
  footer: {
    padding: '16px',
  },
});

const sizes = {
  sm: smSizeModal,
  md: mdSizeModal,
  xl: xlSizeModal,
  lg: lgSizeModal,
};

const baseStyle = definePartsStyle({
  header: {
    bg: '#FFFFFF',
    borderTopRadius: '4px',
  },
  body: {
    bg: '#FFFFFF',
  },
  footer: {
    bg: '#FFFFFF',
    borderBottomRadius: '4px',
    borderTop: '1px solid #EFEFF3',
  },
  overlay: {
    opacity: '0.8 !important',
    background: 'var(--Gray-900, #262B47)',
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: {
    rounded: {
      dialog: {
        borderRadius: 'base',
      },
    },
  },
  defaultProps: {
    variant: 'rounded',
  },
});
