import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const secondaryVariant = defineStyle({
  background: 'secondary.50',
  color: 'secondary.600',
  fontWeight: 'normal',

  _dark: {
    background: 'secondary.600',
    color: 'secondary.50',
  },
});

const sm = defineStyle({
  fontSize: '0.875rem',
  padding: '2px 8px',
});

export const badgeStyles = defineStyleConfig({
  baseStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '2px',
    padding: '3px 10px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    textTransform: 'none',
  },
  sizes: { sm },
  variants: {
    grey: {
      bgColor: '#F7F7FB',
      textColor: '#4B5169',
    },
    green: {
      bgColor: '#F0FFF4',
      textColor: '#1C4532',
    },
    blue: {
      bgColor: '#ECF3FE',
      textColor: '#4285F4',
    },
    yellow: {
      bgColor: '#FFF1CD',
      textColor: '#7F3D00',
    },
    lightyellow: {
      bgColor: '#FFF8EC',
      textColor: '#652B19',
    },
    red: {
      bgColor: '#FFF5F5',
      textColor: '#63171B',
    },
    secondary: secondaryVariant,
    partial: {
      textColor: '#383D58',
      bgGradient:
        'linear-gradient(90deg, rgba(9, 192, 206, 0.15) 0%, rgba(98, 122, 230, 0.15) 42.96%, rgba(161, 116, 235, 0.15) 100%)',
    },
  },
});
