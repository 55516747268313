import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

type TableOptions = {
  initialActivePage: number;
  initialPageSize: number;
};

// TO DO: plan to add sorting and filtering and other table options
const useTable = (options: TableOptions = { initialActivePage: 1, initialPageSize: 25 }, filters?: object) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const size = searchParams.get('size');
  const activePage = page && Number.isInteger(parseInt(page)) ? parseInt(page) : options.initialActivePage;
  const pageSize = size && Number.isInteger(parseInt(size)) ? parseInt(size) : options.initialPageSize;

  const changeActivePage = (page: number, size: number) => {
    setSearchParams({ page: `${page}`, size: `${size}` });
  };

  const changePageSize = (page = 1, size: number) => {
    setSearchParams({ page: `${page}`, size: `${size}` });
  };

  useEffect(() => {
    if (activePage !== 1) {
      changeActivePage(1, pageSize);
    }
  }, [filters]);

  return { activePage, pageSize, changeActivePage, changePageSize };
};

export default useTable;
