import { Tooltip, TooltipProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

const KSTooltip = forwardRef(({ children, label, ...rest }: TooltipProps, ref: any) => {
  return (
    <Tooltip
      tabIndex={-1}
      hasArrow
      borderRadius={4}
      p={2}
      ref={ref}
      label={label}
      backgroundColor={'#262B47'}
      {...rest}
    >
      {children}
    </Tooltip>
  );
});

KSTooltip.displayName = 'KSTooltip';

export default KSTooltip;
