import 'react-chatbot-kit/build/main.css';

import { Button, Icon, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Popover from 'component-library/components/Popover/popover';
import { ArrowDown, StarsIcon } from 'components/icons';
import flagsmith from 'flagsmith';
import { useState } from 'react';
import ChatBot from 'react-chatbot-kit';
import { useHotkeys } from 'react-hotkeys-hook';

import chatActionHandler from './action-handler';
import config from './config';
import messageParser from './message-parser';

const ChatBotContainer = styled(PopoverContent)(() => ({
  '& .react-chatbot-kit-chat-inner-container, .react-chatbot-kit-chat-container': {
    borderRadius: '16px',
  },
  '& .react-chatbot-kit-chat-inner-container': {
    display: 'flex',
    flexDirection: 'column',
  },
  '& .react-chatbot-kit-chat-container': {
    width: '100%',
    height: '100%',
  },
  '& .react-chatbot-kit-chat-bot-message, .react-chatbot-kit-user-chat-message': {
    maxWidth: '70%',
    margin: '0',
    width: 'auto',
    color: '#262B47',
    minHeight: '2.5rem',
    minWidth: '2.5rem',
  },
  '& .react-chatbot-kit-chat-bot-message-container, .react-chatbot-kit-user-chat-message-container': {
    gap: '16px',
    alignItems: 'center',
  },
  '& .react-chatbot-kit-chat-bot-message-container': {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  '& .react-chatbot-kit-chat-message-container': {
    height: '390px',
  },
  '& .react-chatbot-kit-chat-message-container, .react-chatbot-kit-chat-input-container': {
    padding: '12px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  '& .react-chatbot-kit-chat-input-form': {
    backgroundColor: '#FFFFFF',
    gap: '8px',
    '& .react-chatbot-kit-chat-input': {
      border: '1px solid rgba(207, 208, 216, 0.60)',
      borderRadius: '2px',
      '&.disabled': {
        pointerEvents: 'none',
      },
    },
    '& .react-chatbot-kit-chat-btn-send': {
      background: 'linear-gradient(90deg, #04C3CC -0.81%, #527BE4 40.47%, #A373EB 100%)',
      borderRadius: '2px',
      width: '50px',
      '&.disabled': {
        pointerEvents: 'none',
        background: '#EFEFF3 !important',
      },
      '& .react-chatbot-kit-chat-btn-send-icon': {
        width: '20px',
        height: '20px',
      },
    },
  },
}));

const TaxGPT = () => {
  const [isOpen, setIsOpen] = useState(false);
  useHotkeys('ctrl+k', () => setIsOpen(!isOpen), [isOpen]);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  if (!flagsmith.hasFeature('taxbot')) {
    return null;
  }

  const validator = (input: string) => {
    if (input.trim().length > 1) return true;
    return false;
  };

  return (
    <Popover placement="top-start" isOpen={isOpen} onOpen={onToggle} onClose={onToggle}>
      <PopoverTrigger>
        <Button
          boxSize={'50px'}
          borderRadius={'full'}
          bgGradient="linear-gradient(90deg, #04C3CC -0.81%, #527BE4 40.47%, #A373EB 100%)"
          _hover={{
            bgGradient: 'linear-gradient(90deg, #04C3CC -0.81%, #527BE4 40.47%, #A373EB 100%)',
          }}
          color="#fff"
        >
          {isOpen ? <Icon as={ArrowDown} color="#fff" /> : <StarsIcon />}
        </Button>
      </PopoverTrigger>
      <ChatBotContainer p={0} shadow={'xl'} h="50vh" minH="500px" w="24vw" minW="300px">
        <ChatBot
          config={config}
          messageParser={messageParser}
          actionProvider={chatActionHandler}
          placeholderText="Ask me"
          validator={validator}
        />
      </ChatBotContainer>
    </Popover>
  );
};

export default TaxGPT;
