import KSearchInput from 'components/SearchInput';
import { useDispatch, useSelector } from 'react-redux';
import { storeQuery } from 'redux/slices/customers';
import { RootState } from 'redux/store';

export const CustomerSearch = () => {
  const query = useSelector(({ customers }: RootState) => customers.query);
  const dispatch = useDispatch();

  const handleInputChange = (value: string) => {
    dispatch(storeQuery(value));
  };

  return <KSearchInput query={query} setQuery={handleInputChange} styles={{ width: '280px' }} />;
};
