import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';

export const toNormalCase = (str: string) => {
  return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
};

export const replaceUnderscoreWithSpace = (str: string) => {
  if (!str) return str;
  return str.replace(/_/g, ' ');
};

/**
 * Format text to capitalize every first letter
 * @param text
 * @returns string
 */
export const capitalizeFirstLetter = (text: string) => {
  if (!text) return text;
  return text.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
};

/**
 * Convert null value to empty string. Supported type object/ArrayOfObject only.
 * @param value object/ArrayOfObject
 */
export const replaceNullWithEmptyString = (values: any): any => {
  if (!values) {
    return;
  }

  if (typeof values === 'object' && values.constructor === Object) {
    return mapValues(values, v => (isNil(v) ? '' : v));
  }

  if (Array.isArray(values)) {
    return values.map(value => mapValues(value, v => (isNil(v) ? '' : v)));
  }
};

/**
 * Generate 6 digit random number
 */
export const generateRandomNumber = () => {
  return Math.floor(100000 + Math.random() * 900000);
};
