import { Alert, AlertDescription, AlertIcon, AlertProps } from '@chakra-ui/react';
import { MdInfo } from 'react-icons/md';

type AlertBannerProps = {
  message: string;
} & AlertProps;

const AlertBanner = ({ message = '', ...rest }: AlertBannerProps) => {
  return (
    <Alert status="info" {...rest}>
      <AlertIcon as={MdInfo} mr={2}></AlertIcon>
      <AlertDescription>{message}</AlertDescription>
    </Alert>
  );
};

export default AlertBanner;
