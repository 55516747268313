import { Card } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import Text from 'component-library/components/Text';

import BotAvatar from './bot-avatar';

export default function WelcomeMsg() {
  const { user } = useAuthInfo();

  return (
    <Card w="100%" direction={'column'} justifyContent={'center'} alignItems={'center'} gap={2}>
      <BotAvatar />
      <Text fontWeight={500} fontSize={'xl'}>{`Hey ${user?.firstName},`}</Text>
      <Text align={'center'}>{`I'm TaxGPT. I’m here to answer your questions regarding taxes.`}</Text>
    </Card>
  );
}
