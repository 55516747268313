export const globalStyles = {
  styles: {
    global: () => ({
      body: {
        fontFamily: "'Roboto', sans-serif",
      },
      html: {
        fontFamily: "'Roboto', sans-serif",
      },
    }),
  },
};
