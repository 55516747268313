import { ProductCategoryEnum, ProductStatus, ProductStatusVariant, ProductSubCategoryEnum } from 'types/products';

export const productStatusVariantMap = {
  APPROVED: {
    variant: 'approved',
    title: 'Approved',
  },
  PENDING: {
    variant: 'pending',
    title: 'Pending',
  },
  PARTIALLY_APPROVED: {
    variant: 'partial',
    title: 'Partially Approved',
  },
} satisfies Record<ProductStatus, ProductStatusVariant>;

export const PRODUCT_CATEGORIES_MAP: {
  [key in ProductCategoryEnum]: {
    title: string;
    value: ProductCategoryEnum;
  };
} = {
  [ProductCategoryEnum.PHYSICAL]: {
    title: 'Physical',
    value: ProductCategoryEnum.PHYSICAL,
  },
  [ProductCategoryEnum.DIGITAL]: {
    title: 'Digital',
    value: ProductCategoryEnum.DIGITAL,
  },
  [ProductCategoryEnum.SERVICE]: {
    title: 'Service',
    value: ProductCategoryEnum.SERVICE,
  },
  [ProductCategoryEnum.UNKNOWN]: {
    title: 'Unknown',
    value: ProductCategoryEnum.UNKNOWN,
  },
  [ProductCategoryEnum.MISCELLANEOUS]: {
    title: 'Miscellaneous',
    value: ProductCategoryEnum.MISCELLANEOUS,
  },
};

export const PRODUCT_SUBCATEGORIES_MAP: {
  [key in ProductSubCategoryEnum]: {
    title: string;
    value: ProductSubCategoryEnum;
  };
} = {
  [ProductSubCategoryEnum.UNKNOWN]: {
    title: 'Unknown',
    value: ProductSubCategoryEnum.UNKNOWN,
  },
  [ProductSubCategoryEnum.SAAS]: {
    title: 'SaaS',
    value: ProductSubCategoryEnum.SAAS,
  },
  [ProductSubCategoryEnum.DIGITAL_GENERAL]: {
    title: 'General Digital',
    value: ProductSubCategoryEnum.DIGITAL_GENERAL,
  },
  [ProductSubCategoryEnum.B2B_SAAS]: {
    title: 'B2B SaaS',
    value: ProductSubCategoryEnum.B2B_SAAS,
  },
  [ProductSubCategoryEnum.SOFTWARE_ON_PERSONAL_PROPERTY]: {
    title: 'Software on Personal Property',
    value: ProductSubCategoryEnum.SOFTWARE_ON_PERSONAL_PROPERTY,
  },
  [ProductSubCategoryEnum.SOFTWARE_DOWNLOADED]: {
    title: 'Software Downloaded',
    value: ProductSubCategoryEnum.SOFTWARE_DOWNLOADED,
  },
  [ProductSubCategoryEnum.CUSTOM_SOFTWARE_ON_PERSONAL_PROPERTY]: {
    title: 'Custom Software on Personal Property',
    value: ProductSubCategoryEnum.CUSTOM_SOFTWARE_ON_PERSONAL_PROPERTY,
  },
  [ProductSubCategoryEnum.CUSTOM_SOFTWARE_DOWNLOADED]: {
    title: 'Custom Software Downloaded',
    value: ProductSubCategoryEnum.CUSTOM_SOFTWARE_DOWNLOADED,
  },
  [ProductSubCategoryEnum.CUSTOMIZATION_OF_SOFTWARE]: {
    title: 'Customization of Software',
    value: ProductSubCategoryEnum.CUSTOMIZATION_OF_SOFTWARE,
  },
  [ProductSubCategoryEnum.B2C_SAAS]: {
    title: 'B2C SaaS',
    value: ProductSubCategoryEnum.B2C_SAAS,
  },
  [ProductSubCategoryEnum.SERVICE_GENERAL]: {
    title: 'General Services',
    value: ProductSubCategoryEnum.SERVICE_GENERAL,
  },
  [ProductSubCategoryEnum.PROFESSIONAL_SERVICE]: {
    title: 'Professional Services',
    value: ProductSubCategoryEnum.PROFESSIONAL_SERVICE,
  },
  [ProductSubCategoryEnum.TANGIBLE_PROPERTY_SERVICE]: {
    title: 'Tangible Property Services',
    value: ProductSubCategoryEnum.TANGIBLE_PROPERTY_SERVICE,
  },
  [ProductSubCategoryEnum.REAL_PROPERTY_SERVICE]: {
    title: 'Real Property Services',
    value: ProductSubCategoryEnum.REAL_PROPERTY_SERVICE,
  },
  [ProductSubCategoryEnum.BUSINESS_SERVICE]: {
    title: 'Business Services',
    value: ProductSubCategoryEnum.BUSINESS_SERVICE,
  },
  [ProductSubCategoryEnum.PERSONAL_SERVICE]: {
    title: 'Personal Services',
    value: ProductSubCategoryEnum.PERSONAL_SERVICE,
  },
  [ProductSubCategoryEnum.AMUSEMENT_SERVICE]: {
    title: 'Amusement Services',
    value: ProductSubCategoryEnum.AMUSEMENT_SERVICE,
  },
  [ProductSubCategoryEnum.MEDICAL_SERVICES]: {
    title: 'Medical Services',
    value: ProductSubCategoryEnum.MEDICAL_SERVICES,
  },
  [ProductSubCategoryEnum.PHYSICAL_GENERAL]: {
    title: 'General Physical',
    value: ProductSubCategoryEnum.PHYSICAL_GENERAL,
  },
  [ProductSubCategoryEnum.GENERAL_CLOTHING]: {
    title: 'General Clothing',
    value: ProductSubCategoryEnum.GENERAL_CLOTHING,
  },
  [ProductSubCategoryEnum.CATERING]: {
    title: 'Catering',
    value: ProductSubCategoryEnum.CATERING,
  },
  [ProductSubCategoryEnum.GROCERY_FOOD]: {
    title: 'Grocery Food',
    value: ProductSubCategoryEnum.GROCERY_FOOD,
  },
  [ProductSubCategoryEnum.LEASES_AND_RENTALS_MOTOR_VEHICLES]: {
    title: 'Leases and Rentals Motor Vehicles',
    value: ProductSubCategoryEnum.LEASES_AND_RENTALS_MOTOR_VEHICLES,
  },
  [ProductSubCategoryEnum.LEASES_AND_RENTALS_TANGIBLE_MEDIA_PROPERTY]: {
    title: 'Leases and Rentals Tangible Media Property',
    value: ProductSubCategoryEnum.LEASES_AND_RENTALS_TANGIBLE_MEDIA_PROPERTY,
  },
  [ProductSubCategoryEnum.MACHINERY]: {
    title: 'Machinery',
    value: ProductSubCategoryEnum.MACHINERY,
  },
  [ProductSubCategoryEnum.RAW_MATERIALS]: {
    title: 'Raw Materials',
    value: ProductSubCategoryEnum.RAW_MATERIALS,
  },
  [ProductSubCategoryEnum.UTILITIES_FUEL]: {
    title: 'Utilities & Fuel',
    value: ProductSubCategoryEnum.UTILITIES_FUEL,
  },
  [ProductSubCategoryEnum.MEDICAL_DEVICES]: {
    title: 'Medical Devices',
    value: ProductSubCategoryEnum.MEDICAL_DEVICES,
  },
  [ProductSubCategoryEnum.MEDICINES]: {
    title: 'Medicines',
    value: ProductSubCategoryEnum.MEDICINES,
  },
  [ProductSubCategoryEnum.NEWSPAPERS]: {
    title: 'Newspapers',
    value: ProductSubCategoryEnum.NEWSPAPERS,
  },
  [ProductSubCategoryEnum.PERIODICALS]: {
    title: 'Periodicals',
    value: ProductSubCategoryEnum.PERIODICALS,
  },
  [ProductSubCategoryEnum.GENERAL_OCCASIONAL_SALES]: {
    title: 'General Occasional Sales',
    value: ProductSubCategoryEnum.GENERAL_OCCASIONAL_SALES,
  },
  [ProductSubCategoryEnum.MOTOR_VEHICLES_OCCASIONAL_SALES]: {
    title: 'Motor Vehicles Occasional Sales',
    value: ProductSubCategoryEnum.MOTOR_VEHICLES_OCCASIONAL_SALES,
  },
  [ProductSubCategoryEnum.GENERAL_OPTIONAL_MAINTENANCE_CONTRACTS]: {
    title: 'General Optional Maintenance Contracts',
    value: ProductSubCategoryEnum.GENERAL_OPTIONAL_MAINTENANCE_CONTRACTS,
  },
  [ProductSubCategoryEnum.PARTS_PURCHASED_OPTIONAL_MAINTENANCE_CONTRACTS]: {
    title: 'Parts Purchased for Use in Performing Service Under Optional Maintenance Contracts',
    value: ProductSubCategoryEnum.PARTS_PURCHASED_OPTIONAL_MAINTENANCE_CONTRACTS,
  },
  [ProductSubCategoryEnum.GENERAL_POLLUTION_CONTROL_EQUIPMENT]: {
    title: 'General Pollution Control Equipment',
    value: ProductSubCategoryEnum.GENERAL_POLLUTION_CONTROL_EQUIPMENT,
  },
  [ProductSubCategoryEnum.GENERAL_TRADE_INS]: {
    title: 'General Trade-Ins',
    value: ProductSubCategoryEnum.GENERAL_TRADE_INS,
  },
  [ProductSubCategoryEnum.FOOD_VENDING_MACHINE]: {
    title: 'Food Vending Machine',
    value: ProductSubCategoryEnum.FOOD_VENDING_MACHINE,
  },
  [ProductSubCategoryEnum.MERCHANDISE_VENDING_MACHINE]: {
    title: 'Merchandise Vending Machine',
    value: ProductSubCategoryEnum.MERCHANDISE_VENDING_MACHINE,
  },
  [ProductSubCategoryEnum.SUPPLEMENTS]: {
    title: 'Supplements',
    value: ProductSubCategoryEnum.SUPPLEMENTS,
  },
  [ProductSubCategoryEnum.SHIPPING]: {
    title: 'Shipping',
    value: ProductSubCategoryEnum.SHIPPING,
  },
  [ProductSubCategoryEnum.GIFT_CARD]: {
    title: 'Gift Card',
    value: ProductSubCategoryEnum.GIFT_CARD,
  },
  [ProductSubCategoryEnum.CREDIT_CARD_SURCHARGES]: {
    title: 'Credit Card Surcharges',
    value: ProductSubCategoryEnum.CREDIT_CARD_SURCHARGES,
  },
  [ProductSubCategoryEnum.CREDIT_CARD_FEES]: {
    title: 'Credit Card Fees',
    value: ProductSubCategoryEnum.CREDIT_CARD_FEES,
  },
  [ProductSubCategoryEnum.MISCELLANEOUS_EXEMPT]: {
    title: 'Miscellaneous Tax Exempt',
    value: ProductSubCategoryEnum.MISCELLANEOUS_EXEMPT,
  },
  [ProductSubCategoryEnum.DISCOUNT]: {
    title: 'Discounts',
    value: ProductSubCategoryEnum.DISCOUNT,
  },
};
