import Intercom, { show } from '@intercom/messenger-js-sdk';
import { useAuthInfo } from '@propelauth/react';
import Button from 'component-library/components/ButtonTmp/button';
import CryptoJS from 'crypto-js';
import { useOrg } from 'hooks/useOrg';
import React from 'react';
import { IoMdHelp } from 'react-icons/io';

export default function IntercomWidget() {
  const { user } = useAuthInfo();
  const { orgId } = useOrg();
  const chatLauncher = document.querySelector('#open_web_chat');
  if (chatLauncher) {
    chatLauncher.addEventListener('click', function () {
      if (user && orgId) {
        const hash = CryptoJS.HmacSHA256(user.userId, import.meta.env.VITE_INTERCOM_SECRET_KEY).toString(
          CryptoJS.enc.Hex
        );
        Intercom({
          custom_launcher_selector: '#open_web_chat',
          app_id: import.meta.env.VITE_INTERCOM_APP_ID,
          user_id: user.userId,
          name: user.username,
          email: user.email,
          created_at: user.createdAt,
          Organization: orgId,
          user_hash: hash,
        });
        setTimeout(() => {
          show();
        }, 500);
      }
    });
  } else {
    console.warn('Chat launcher element not found!');
  }

  return (
    <Button id="open_web_chat" boxSize={'50px'} borderRadius={'full'} bgColor="#ffb000" _hover={{ bg: '#ffb000' }}>
      <IoMdHelp size={'20px'} />
    </Button>
  );
}
