import { Heading, HStack, IconButton } from '@chakra-ui/react';
import { ArrowLeft } from 'components/icons';
import { useNavigate } from 'react-router-dom';

export const TaxDetailsHeaderLabel = () => {
  const navigate = useNavigate();

  return (
    <HStack>
      <IconButton size="sm" aria-label="back" variant={'ghost'} rounded={4} onClick={() => navigate(-1)}>
        <ArrowLeft />
      </IconButton>
      <Heading as={'h1'} fontSize={'20px'}>
        Tax Details by Year
      </Heading>
    </HStack>
  );
};
