import { HStack, IconButton } from '@chakra-ui/react';
import { exportNexus } from 'apis/dashboard-apis';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { ExcelIcon } from 'components/icons';
import { useHandleNotification } from 'hooks/useApiNotification';
import useAsyncActionLoader from 'hooks/useAsyncActionLoader';
import { useOrg } from 'hooks/useOrg';

const NexusHeaderToolbar = () => {
  const { orgId } = useOrg();
  const { loading, executeAction } = useAsyncActionLoader(exportNexus);
  const { handleSuccessNotification, handleFailNotification } = useHandleNotification();
  const handleExportNexus = async () => {
    try {
      await executeAction(orgId);
      handleSuccessNotification('Nexus Report Export Started. Check your email for a download link.');
    } catch (error) {
      handleFailNotification(error);
    }
  };

  return (
    <HStack>
      <Tooltip placement="bottom-end" shouldWrapChildren label={'Export Excel'}>
        <IconButton
          isLoading={loading}
          aria-label={'Export Nexus'}
          mr={1}
          variant={'transparent-with-icon'}
          onClick={handleExportNexus}
        >
          <ExcelIcon />
        </IconButton>
      </Tooltip>
    </HStack>
  );
};

export default NexusHeaderToolbar;
