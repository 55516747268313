import { Box, Flex, Skeleton, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {
  getRelatedTransactionForId,
  getTransactionForId,
  RELATED_TRANSACTION_STATE_KEY,
  TRANSACTION_STATE_KEY,
} from 'apis/transaction-api';
import AlertBanner from 'components/alert/alert';
import { useOrg } from 'hooks/useOrg';
import { useParams } from 'react-router-dom';
import { AddressInstance, ProcessingStatusEnum } from 'types/shared-types';

import { RelatedTransactionTable } from './related-transaction-table';
import { AddressesCards } from './transaction-details/address-cards';
import { TransactionDetailAction } from './transaction-details/transaction-detail-action';
import { TransactionItemsTable } from './transaction-details/transaction-items';

const RELATED_TRANSACTION_TABLE_HEAD = ['ID', 'DESCRIPTION', 'DATE', 'SALES', 'CALCULATED TAX', 'STATUS'];

const TRANSACTION_ITEM_TABLE_HEAD = ['PRODUCT', 'DESCRIPTION', 'QUANTITY', 'CALCULATED TAX', 'SALES'];

type TransactionDetailsProps = {
  transactionId?: string;
};
export const TransactionDetails = ({ transactionId }: TransactionDetailsProps) => {
  const { id: idFromParams } = useParams<{ id: string }>();
  const { orgId } = useOrg();
  const id = transactionId || idFromParams;
  const {
    isPending,
    data: transactionData,
    isError,
  } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, id],
    queryFn: async () => {
      const { data } = await getTransactionForId(orgId, id!);
      return data;
    },
    enabled: !!id,
  });

  const { isPending: isRelatedDataPending, data: relatedTransactionData } = useQuery({
    queryKey: [RELATED_TRANSACTION_STATE_KEY, id],
    queryFn: async () => {
      const { data } = await getRelatedTransactionForId(orgId, id!);
      return data;
    },
    enabled: !!id,
  });

  if (isPending || isRelatedDataPending) {
    return Array.from({ length: 10 }, (_, index) => (
      <Box key={index} mt={6}>
        <Skeleton height="30px" width="full" />
      </Box>
    ));
  }

  if (!transactionData || !relatedTransactionData || !id) {
    return null;
  }

  if (isError) {
    console.log('error while fetching data');
    return null;
  }

  const billingAddress = transactionData.addresses?.find((address: AddressInstance) => address.type === 'BILL_TO');
  const shippingAddress = transactionData.addresses?.find((address: AddressInstance) => address.type === 'SHIP_TO');
  const transactionItems = transactionData.transaction_items;
  const subtotal = {
    totalAmount: Number(transactionData.total_amount),
    totalTaxAmount: Number(transactionData.total_tax_amount_calculated),
  };

  return (
    <Flex direction={'column'} gap={4} mt={'32px'}>
      {transactionData.processing_status === ProcessingStatusEnum.QUEUED && (
        <AlertBanner
          message="We're processing this recently imported transaction. Please check back in a few minutes for the latest tax numbers."
          width={'839px'}
        />
      )}
      {billingAddress && shippingAddress && <AddressesCards billing={billingAddress} shipping={shippingAddress} />}

      {relatedTransactionData && Object.keys(relatedTransactionData).length > 0 && (
        <>
          <Text fontSize={'18px'} fontWeight={500}>
            Related Transaction
          </Text>
          <RelatedTransactionTable captions={RELATED_TRANSACTION_TABLE_HEAD} data={relatedTransactionData} />
        </>
      )}

      <Flex height={'28px'} justifyContent={'space-between'}>
        <Text fontSize="large" fontWeight="medium" paddingTop={'10px'}>
          Transaction Items
        </Text>
        <Box zIndex={5} width={'20px'}>
          <TransactionDetailAction customerId={transactionData.customer_id} transactionId={id} />
        </Box>
      </Flex>

      {transactionData.exemptions.length > 0 && (
        <AlertBanner message="This transaction has a one-time exemption certificate applied. " width={'766px'} />
      )}

      <TransactionItemsTable captions={TRANSACTION_ITEM_TABLE_HEAD} data={transactionItems} subtotal={subtotal} />
    </Flex>
  );
};
