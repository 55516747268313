import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { OrgDetailsInstance } from 'types/shared-types';

const orgDetailsAdapter = createEntityAdapter<OrgDetailsInstance>();

const initialState = orgDetailsAdapter.getInitialState({
  loading: false,
});

const orgDetails = createSlice({
  name: 'orgDetails',
  initialState: initialState,
  reducers: {},
});

export default orgDetails.reducer;
