import { Box, CloseButton, Flex, Text, UseToastOptions } from '@chakra-ui/react';
import { ToastError, ToastSuccess } from 'components/icons';
import React from 'react';

interface RenderProps extends UseToastOptions {
  /**
   * Function to close the toast
   */
  onClose(): void;
}
const ToastContainer: React.FC<RenderProps> = ({ status, title, description, onClose }) => {
  const backgroundColor = status === 'success' ? '#C6F6D5' : '#FED7D7';

  return (
    <Box
      width={'405px'}
      py={3}
      px={4}
      bg={backgroundColor}
      justifyContent={'space-between'}
      display="flex"
      alignItems="center"
      position="fixed"
      top={4}
      right={4}
    >
      <Flex gap={'8px'}>
        <Box>{status === 'success' ? <ToastSuccess /> : <ToastError />}</Box>
        <Box>
          <Text fontSize={'16px'} fontWeight={500}>
            {title}
          </Text>
          <Text fontSize={'14px'} fontWeight={400} pt={1}>
            {description}
          </Text>
        </Box>
      </Flex>
      <CloseButton onClick={onClose} />
    </Box>
  );
};

export default ToastContainer;
