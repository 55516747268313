/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DashboardData } from '../models/DashboardData';
import type { DashboardDetailsData } from '../models/DashboardDetailsData';
import type { OnboardingData } from '../models/OnboardingData';
import type { OnBoardingStepStatusData } from '../models/OnBoardingStepStatusData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ExperienceService {
    /**
     * Get Dashboard Data Route
     * @returns DashboardData Successful Response
     * @throws ApiError
     */
    public static getDashboardDataRouteV1DashboardGet({
        xOrganizationId,
    }: {
        xOrganizationId: string,
    }): CancelablePromise<DashboardData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dashboard/',
            headers: {
                'x-organization-id': xOrganizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Onboarding Status Route
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateOnboardingStatusRouteV1OnboardingPut({
        requestBody,
    }: {
        requestBody: OnboardingData,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/onboarding',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Dashboard Details
     * Implementing new endpoint
     * @returns DashboardDetailsData Successful Response
     * @throws ApiError
     */
    public static getDashboardDetailsV1DashboardDetailsAggregationsAggregationTypeGet({
        aggregationType,
        xOrganizationId,
        country,
        state,
        marketplace = true,
    }: {
        aggregationType: string,
        xOrganizationId: string,
        /**
         * Country for filtering
         */
        country?: string,
        /**
         * State for filtering
         */
        state?: string,
        /**
         * Marketplace for filtering
         */
        marketplace?: boolean,
    }): CancelablePromise<DashboardDetailsData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dashboard/details/aggregations={aggregation_type}',
            path: {
                'aggregation_type': aggregationType,
            },
            headers: {
                'x-organization-id': xOrganizationId,
            },
            query: {
                'country': country,
                'state': state,
                'marketplace': marketplace,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Onboarding Steps Status
     * @returns OnBoardingStepStatusData Successful Response
     * @throws ApiError
     */
    public static getOnboardingStepsStatusV1OnboardingStepsStatusGet({
        xOrganizationId,
    }: {
        xOrganizationId: string,
    }): CancelablePromise<OnBoardingStepStatusData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/onboarding/steps-status',
            headers: {
                'x-organization-id': xOrganizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
