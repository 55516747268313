import axios, { AxiosError } from 'axios';
import { getToken } from 'cookie-handler';
import { SELECTED_ORG_KEY } from 'hooks/useOrg';
import isArray from 'lodash/isArray';

axios.defaults.baseURL = import.meta.env.VITE_API_URL;
axios.defaults.withCredentials = false;

const api = axios;

/**
 * axios request interceptors
 */
axios.interceptors.request.use(function (config) {
  const accessToken = getToken();
  config.headers.Authorization = `Bearer ${accessToken}`;
  if (import.meta.env.VITE_ENVIRONMENT === 'local') {
    config.headers['ngrok-skip-browser-warning'] = '1234';
  }
  return config;
});

/**
 * axios response interceptors
 */
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // reload so propelauth can try to fetch new access token or redirect to login
      window.location.reload();
    }

    if (
      error.response &&
      error.response.status === 403 &&
      // being more specific here to avoid removing the selected org when 403 (FORBIDDEN)
      // is returned for other reasons
      error.response.data?.detail === 'User does not have access to the specified organization'
    ) {
      localStorage.removeItem(SELECTED_ORG_KEY);
      window.location.reload();
    }
    if (error.response && error.response.status === 405) {
      error.response['data'] = {
        detail: 'Method Not Allowed. Try with a different method.',
      };
    }
    return Promise.reject(error);
  }
);

export type ApiError = AxiosError;

export default api;

export const handleApiErrorMessage = (err: any) => {
  try {
    if (axios.isCancel(err) || err.name === 'AbortError') {
      // xhr's Promise was cancelled, probably by our code with AbortController.abort()
      return;
    }
    return parseMessageFromError(err);
  } catch (error) {
    console.error('apiError', error);
  }
};

export const parseMessageFromError = (error: any): { title: string; message: string } => {
  let title = '';
  let message = '';
  const errorData = error?.response?.data;
  title = error?.message;

  if (errorData?.detail && isArray(errorData?.detail)) {
    message = errorData.detail[0].msg;
  } else if (errorData?.detail?.msg) {
    message = errorData.detail.msg;
  } else if (errorData?.detail) {
    message = errorData.detail;
  }

  // please add more cases here if needed
  return { title, message };
};

export const isAxiosError = (error: any): error is AxiosError => {
  return !!error?.response;
};

/**
 * Check the error is an Axios error and it matches the specified status codes.
 *
 * @param {any} error - The error object.
 * @param {...number[]} statusCodes - The status codes to check against.
 * @returns {boolean} True if the error is an Axios error with any of the specified status codes, false otherwise.
 */
export const isAxiosErrorMatchedStatus = (error: any, ...statusCodes: number[]) => {
  return isAxiosError(error) && statusCodes.includes(error.response?.status || 0);
};

export const staticErrorMessages = {
  400: 'Bad request. Please check your input.',
  401: 'Unauthorized. Please log in.',
  403: 'Forbidden. You do not have permission to perform this action.',
  404: 'Resource not found.',
  500: 'Internal server error. Please try again later.',
};
