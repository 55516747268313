import { setOnboarding } from 'apis/user';
import { APP_ROUTES, ModuleInfo } from 'constants/app-constants';
import useOnboarding, { isValidOnboardingStatus, OnboardingStatus, onboardingSteps, Step } from 'hooks/useOnboarding';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const AppOnboarding = ({ status }: { status: string }) => {
  const location = useLocation();
  const { onboardingState, setInitialOnboardingState, setCurrentOnboardingStep } = useOnboarding();

  useEffect(() => {
    // initial onboarding status from backend
    setInitialOnboardingState(status);
  }, [status]);

  useEffect(() => {
    if (onboardingState && status !== onboardingState && isValidOnboardingStatus(onboardingState)) {
      // update onboarding status to backend
      setOnboarding(onboardingState);
    }
  }, [onboardingState]);

  useEffect(() => {
    if (status !== OnboardingStatus.SKIPPED && status !== OnboardingStatus.FINISHED) {
      const newActive = APP_ROUTES.find((link: ModuleInfo) => link.href === location.pathname);
      if (newActive?.name) {
        const step = onboardingSteps.findIndex((step: Step) => step?.metadata?.routeTo === newActive.href);
        step >= 0 && setCurrentOnboardingStep(step + 1);
      }
    }
  }, [location]);

  return null;
};
