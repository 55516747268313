import api from './api';

const getTransactions = (
  orgId: string,
  page: number,
  size: number,
  filter?: object,
  order?: object,
  search_query?: string
) => {
  return api.get(`/v1/transactions`, {
    method: 'GET',
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      page: page,
      size: size,
      ...filter,
      ...order,
      search_query,
    },
  });
};

const TRANSACTION_STATE_KEY = 'transactions';
export { getTransactions, TRANSACTION_STATE_KEY };
