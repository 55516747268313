import { ProductCategoryEnum } from '_api-client';
import { PRODUCT_CATEGORIES_MAP, PRODUCT_SUBCATEGORIES_MAP } from 'constants/products';
import { camelCase, startCase } from 'lodash';
import { ProductSubCategoryEnum } from 'schema/types-schema.d';

const humanizeProductSubcategory = (subCategory: string): string => startCase(camelCase(subCategory));

const getProductSubcategoryTitle = (subCategory: ProductSubCategoryEnum) =>
  PRODUCT_SUBCATEGORIES_MAP[subCategory]
    ? PRODUCT_SUBCATEGORIES_MAP[subCategory].title
    : humanizeProductSubcategory(subCategory);

const getProductCategoryTitle = (category: ProductCategoryEnum) =>
  PRODUCT_CATEGORIES_MAP[category] ? PRODUCT_CATEGORIES_MAP[category].title : humanizeProductSubcategory(category);

const getTaxExemptTextBySubCategory = (subCategory?: ProductSubCategoryEnum) => {
  if (!subCategory) {
    return '';
  }

  switch (subCategory) {
    case ProductSubCategoryEnum.UNKNOWN:
      return '';
    case ProductSubCategoryEnum.MISCELLANEOUS_EXEMPT:
      return 'Yes';
    default:
      return 'No';
  }
};

const getTaxExemptTextByTaxExempt = (taxExempt: boolean) => (taxExempt ? 'Yes' : 'No');

const isValidValue = (enumType: object, value: string) => {
  return value !== '' && value !== 'UNKNOWN' && Object.values(enumType).includes(value as unknown as typeof enumType);
};

const isValidCategory = (value: string) => isValidValue(ProductCategoryEnum, value);

const isValidSubcategory = (value: string) => isValidValue(ProductSubCategoryEnum, value);

export {
  getProductCategoryTitle,
  getProductSubcategoryTitle,
  getTaxExemptTextBySubCategory,
  getTaxExemptTextByTaxExempt,
  humanizeProductSubcategory,
  isValidCategory,
  isValidSubcategory,
};
