import {
  Badge,
  //   Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  //   Flex,
  Heading,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getTransactionForId, TRANSACTION_STATE_KEY } from 'apis/transaction-api';
import { ArrowRight } from 'components/icons';
import { transactionStatusVariantMap } from 'constants/transactions';
import { useOrg } from 'hooks/useOrg';
import { TransactionDetails } from 'pages/Transactions/components/transaction-details';
import { useSearchParams } from 'react-router-dom';

export const TransactionDetailsDrawer = () => {
  const { orgId } = useOrg();
  const [searchParams, setSearchParams] = useSearchParams();
  const transactionId = searchParams.get('transaction_id');
  const { data: transactionDetails } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, transactionId],
    queryFn: async () => {
      const { data } = await getTransactionForId(orgId, transactionId!);
      return data;
    },
    enabled: !!transactionId,
  });

  const onClose = () => {
    setSearchParams({});
  };

  if (!transactionDetails) {
    return null;
  }

  const statusVariant = transactionDetails?.status
    ? transactionStatusVariantMap[transactionDetails.status]
    : { variant: 'secondary', title: 'Loading' };

  return (
    <Drawer isOpen={!!transactionId} onClose={onClose} size="xl" placement="right">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <HStack>
            <IconButton rounded={'sm'} variant={'ghost'} aria-label="back-to-filings" onClick={onClose}>
              <ArrowRight />
            </IconButton>

            <Heading as={'h1'} fontSize={'xl'}>
              Transaction Details
            </Heading>
            <Badge variant={statusVariant.variant}>{statusVariant.title}</Badge>
          </HStack>
        </DrawerHeader>
        <DrawerBody>
          <TransactionDetails transactionId={transactionDetails?.id} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
