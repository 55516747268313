/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum ProductCategoryEnum {
    PHYSICAL = 'PHYSICAL',
    DIGITAL = 'DIGITAL',
    SERVICE = 'SERVICE',
    UNKNOWN = 'UNKNOWN',
    MISCELLANEOUS = 'MISCELLANEOUS',
}
