import { HStack, Text, TextProps } from '@chakra-ui/react';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { AddressValidation, AddressValidationOutline } from 'components/icons';
import { Customer } from 'types/customers';

type AddressStatusProps = {
  title: string;
  customer: Customer;
  titleProps?: TextProps;
};

const getStatusText = (status: string): string => {
  switch (status) {
    case 'VERIFIED':
      return 'Verified';
    case 'UNVERIFIED':
      return 'Unverified';
    default:
      return status;
  }
};

export const AddressStatus = ({ title, customer, titleProps = {} }: AddressStatusProps) => {
  return (
    <HStack align={'center'}>
      <Text fontWeight={500} {...titleProps}>
        {title}
      </Text>
      <Tooltip label={getStatusText(customer.address_status)} hasArrow>
        <span>
          {customer.address_status === 'VERIFIED' ? (
            <AddressValidation />
          ) : (
            <AddressValidationOutline fill={customer.address_status === 'UNVERIFIED' ? '#CFD0D8' : '#38A169'} />
          )}
        </span>
      </Tooltip>
    </HStack>
  );
};
