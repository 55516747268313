import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { ArrowRight } from 'components/icons';
import { endOfMonth } from 'date-fns/endOfMonth';
import useQueryParam from 'hooks/useQueryParam';
import { useTableFilters } from 'hooks/useTableFilters';
import { FilingStatus } from 'types/shared-types';
import { getFormattedDate } from 'utils/dates';

import { FilingFilterButton } from '../FilingFilter/FilingPopover';
import FilingsHistoryTable from './filings-history';

const FilingHistoryDrawer = () => {
  const [showFilingHistory, setSearchParams] = useQueryParam('sfh');

  const onClose = () => {
    setSearchParams({});
  };

  const currentMonthEnd = getFormattedDate(endOfMonth(new Date()), 'yyyy-MM-dd');
  const filingHistoryFilters = useTableFilters({
    status__in: [FilingStatus.FILED],
    date_filed__lte: currentMonthEnd,
    order_by: ['start_date', 'end_date', 'amount', 'date_filed'],
  });

  return (
    <Drawer isOpen={showFilingHistory} onClose={onClose} size="xl" placement="right">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex gap={2} alignItems={'center'} justifyContent={'space-between'}>
            <Box>
              <IconButton rounded={'sm'} variant={'ghost'} aria-label="back-to-filings" onClick={onClose}>
                <ArrowRight />
              </IconButton>
              History
            </Box>
            <FilingFilterButton tableFilters={filingHistoryFilters} />
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <FilingsHistoryTable tableFilters={filingHistoryFilters} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default FilingHistoryDrawer;
