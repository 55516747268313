import { CountryCodeEnum } from '_api-client';
import { Flex, FormControl, HStack, Stack, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createFilingApi, FILINGS_STATE_KEY } from 'apis/filing-apis';
import axios, { AxiosError } from 'axios';
import Button from 'component-library/components/ButtonTmp/button';
import DatePicker from 'component-library/components/date-picker/date-picker';
import FormLabel from 'component-library/components/FormLabel';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Select from 'component-library/components/Select/Select';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { subDays } from 'date-fns';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { FilingInstanceCreate, FilingStateOption, FilingStatus } from 'types/shared-types';
import { isValidDate } from 'utils/dates';
import { date, object, ref, string } from 'yup';

const validationSchema = object().shape({
  registration_id: string().required('State is required'),
  start_date: date().required('Start date is required'),
  end_date: date().min(ref('start_date'), "End date can't be before start date").required('End date is required'),
  status: string().oneOf(Object.values(FilingStatus)),
  state_name: string().required('State Name is required'),
  state_code: string().required('State Code is required'),
  country_code: string().required('Country Code is required'),
});

type FilingTypeProps = {
  isOpen: boolean;
  onClose: () => void;
  registrationStates: FilingStateOption[];
};

export const FilingForm = ({ isOpen, onClose, registrationStates }: FilingTypeProps) => {
  const { orgId } = useOrg();
  const { track } = useTracking();
  const queryClient = useQueryClient();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();

  const mutation = useMutation({
    mutationFn: (payload: FilingInstanceCreate) => {
      return createFilingApi(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FILINGS_STATE_KEY] });
      onClose();
      handleSuccessNotification('Filing added successfully.');
    },
    onError: (err: Error | AxiosError) => {
      if (axios.isAxiosError(err) && err.response?.data) {
        handleFailNotification(err);
        return;
      }
      handleFailNotification(err);
    },
  });

  const formik = useFormik({
    initialValues: {
      registration_id: '',
      status: FilingStatus.UNFILED,
      start_date: '',
      end_date: '',
      is_manual: true,
      state_name: '',
      state_code: '',
      country_code: CountryCodeEnum.US,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      const newValues: typeof values & { date_filed?: string } = { ...values };
      if (values.status === FilingStatus.FILED) {
        newValues.date_filed = values.end_date;
      }
      mutation.mutate(newValues);
      track('files jurisdiction');
    },
  });

  const handleModalClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <ModalPopup
      size={'lg'}
      isOpen={isOpen}
      onClose={handleModalClose}
      header={'New Filing'}
      footer={
        <Flex gap={4}>
          <Button variant={'outline'} color={'secondary'} onClick={handleModalClose}>
            Cancel
          </Button>
          <Button
            isLoading={mutation.isPending}
            variant={'solid'}
            color={'primary'}
            width={'90px'}
            onClick={() => formik.handleSubmit()}
          >
            Save
          </Button>
        </Flex>
      }
    >
      <form noValidate>
        <Stack gap={'4'}>
          <HStack align={'baseline'} gap={4}>
            <FormControl isRequired>
              <FormLabel htmlFor="company_country_code">Country</FormLabel>
              <Select
                isDisabled={true}
                name="company_country_code"
                placeholder="Select Country"
                onChange={formik.handleChange}
                value={'US'}
              >
                <option value="US">US</option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="registration_id">Jurisdiction</FormLabel>
              <Select
                id="registration_id"
                name="registration_id"
                onChange={e => {
                  formik.setFieldValue('registration_id', e.target.value);
                  const registration = registrationStates?.find(el => el.registration_id === e.target.value);
                  formik.setFieldValue('state_name', registration?.state_name || '');
                  formik.setFieldValue('state_code', registration?.state_code || '');
                  formik.setFieldValue('start_date', registration?.registration_date || '');
                  formik.setFieldValue('end_date', '');
                }}
              >
                <option value={''} disabled>
                  Select
                </option>
                {registrationStates?.map((option: FilingStateOption) => (
                  <option key={option.registration_id} value={option.registration_id}>
                    {option.state_name}
                  </option>
                ))}
              </Select>
              {formik.errors.registration_id && formik.touched.registration_id && (
                <Text color={'red.500'}>{formik.errors.registration_id}</Text>
              )}
            </FormControl>
          </HStack>
          <HStack align={'baseline'} gap={4}>
            <FormControl isRequired>
              <FormLabel htmlFor="start_date">Start Date</FormLabel>
              <DatePicker
                minDate={
                  new Date(
                    registrationStates?.find(el => el.registration_id === formik.values.registration_id)
                      ?.registration_date || ''
                  )
                }
                maxDate={
                  isValidDate(new Date(formik.values.end_date)) ? subDays(new Date(formik.values.end_date), 1) : null
                }
                selected={formik.values.start_date}
                onChange={date => {
                  formik.setFieldValue('start_date', date);
                }}
                renderDayContents={(day, date) => {
                  const registrationDate = registrationStates?.find(
                    el => el.registration_id === formik.values.registration_id
                  )?.registration_date;

                  return (
                    <div>
                      <Tooltip
                        label={
                          registrationDate && date && new Date(date) < new Date(registrationDate)
                            ? 'Please select a date after the registration date for this jurisdiction.'
                            : ''
                        }
                        shouldWrapChildren
                      >
                        <span>{day}</span>
                      </Tooltip>
                    </div>
                  );
                }}
              />
              {formik.errors.start_date && formik.touched.start_date && (
                <Text color={'red.500'}>{formik.errors.start_date}</Text>
              )}
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="end_date">End Date</FormLabel>
              <DatePicker
                minDate={new Date(formik.values.start_date)}
                selected={formik.values.end_date}
                onChange={date => {
                  formik.setFieldValue('end_date', date);
                }}
              />
              {formik.errors.end_date && formik.touched.end_date && (
                <Text color={'red.500'}>{formik.errors.end_date}</Text>
              )}
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="status">Status</FormLabel>
              <Select id="status" name="status" onChange={formik.handleChange}>
                {Object.values(FilingStatus).map((option: string) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
              {formik.errors.status && formik.touched.status && <Text color={'red.500'}>{formik.errors.status}</Text>}
            </FormControl>
          </HStack>
        </Stack>
      </form>
    </ModalPopup>
  );
};
