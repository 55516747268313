import { PropsWithChildren } from 'react';

import { useACL } from './acl';

export const ACLCheck = ({ children, role }: PropsWithChildren<{ role: string }>) => {
  const { isAtLeastRole } = useACL();
  if (isAtLeastRole(role)) {
    return <>{children}</>;
  }
  return null;
};
