import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({});

const variantFilter = definePartsStyle({
  control: defineStyle({
    boxSize: 4,
  }),
  label: defineStyle({
    fontSize: '14px',
  }),
});

const variants = {
  filter: variantFilter,
};

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
});
