import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllOrgs } from 'apis/organizations-apis';

export const fetchOrgs = createAsyncThunk('orgs/fetchOrgs', async () => {
  const response = await getAllOrgs();
  return response.data;
});

const orgSlice = createSlice({
  name: 'orgs',
  initialState: {
    orgList: [],
    loading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchOrgs.pending, state => {
        state.loading = 'loading';
      })
      .addCase(fetchOrgs.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.orgList = action.payload;
      })
      .addCase(fetchOrgs.rejected, state => {
        state.loading = 'failed';
      });
  },
});

export default orgSlice.reducer;
