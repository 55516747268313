import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

export const tableTheme = defineMultiStyleConfig({
  variants: {
    unstyled: {
      container: {
        border: '1px solid var(--gray-50, #EFEFF3)',
        borderRadius: '4px',
        boxShadow: 'none',
      },
      th: {
        color: '#262B47',
        borderBottom: '1px solid var(--gray-50, #EFEFF3)',
        '&[data-is-numeric=true]': {
          textAlign: 'right !important',
          paddingRight: '38px',
        },
      },
      tr: {
        borderBottom: '1px solid var(--gray-50, #EFEFF3)',
      },
      td: {
        '&[data-is-numeric=true]': {
          textAlign: 'right !important',
          paddingRight: '38px',
        },
      },
    },
  },
  sizes: {
    md: {
      th: {
        padding: '12px 8px',
        lineHeight: '22px',
      },
      td: {
        padding: '12px 8px',
        lineHeight: '22px',
      },
    },
  },
  defaultProps: {
    variant: 'unstyled',
    size: 'md',
  },
});
