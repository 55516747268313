import { Popover, PopoverProps } from '@chakra-ui/react';

const KS_Popover = ({ children, ...rest }: PopoverProps) => {
  return (
    <Popover isLazy={true} {...rest}>
      {children}
    </Popover>
  );
};

export default KS_Popover;
