import { HStack } from '@chakra-ui/react';
import { CustomerPopover } from 'pages/Customers/components/customer-filter/customer-popover';
import { CustomerSearch } from 'pages/Customers/components/CustomerSearch';

export const CustomerTrackingToolbar = () => {
  return (
    <HStack>
      <CustomerSearch />
      <CustomerPopover />
    </HStack>
  );
};
