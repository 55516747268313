import { FilterPopover } from 'components/filter';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { TransactionFilter } from './transaction-filter';

export const TransactionPopover = () => {
  const hasFilter = useSelector(({ transactions: { filter } }: RootState) => Object.values(filter.query).some(Boolean));
  const filters = useSelector(({ transactions }: RootState) => transactions.filter.query);

  return <FilterPopover hasFilter={hasFilter} filters={filters} content={TransactionFilter}></FilterPopover>;
};
