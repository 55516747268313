import Button from 'component-library/components/ButtonTmp/button';
import { NavLink } from 'component-library/components/nav-link';
import { MdKeyboardArrowRight } from 'react-icons/md';

export const DashboardHeaderToolbar = () => {
  return (
    <Button color="secondary" as={NavLink} to="/taxdetails" variant={'outline'} rightIcon={<MdKeyboardArrowRight />}>
      View Tax Details
    </Button>
  );
};
