import { Box, SimpleGrid, Skeleton, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getRegistrationForId, REGISTRATION_STATE_KEY } from 'apis/registration-apis';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Text from 'component-library/components/Text';
import { useOrg } from 'hooks/useOrg';
import React from 'react';
import { getFilingFreq } from 'utils/enum-helpers';

type RegistrationDetailsProps = {
  registrationId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const RegistrationDetails: React.FC<RegistrationDetailsProps> = ({ registrationId, isOpen, onClose }) => {
  const { orgId } = useOrg();

  const {
    isLoading,
    data: registration,
    isError,
  } = useQuery({
    queryKey: [REGISTRATION_STATE_KEY, registrationId],
    queryFn: async () => {
      const { data } = await getRegistrationForId(orgId, registrationId);
      return data;
    },
    enabled: isOpen,
  });

  if (!registrationId || isError) {
    return 'Failed to load registration details.';
  }

  return (
    <ModalPopup isOpen={isOpen} onClose={onClose} header="Registration Details">
      {isLoading ? (
        <VStack spacing={4}>
          {Array.from({ length: 3 }, (_, index) => (
            <Skeleton key={index} height="30px" width="full" />
          ))}
        </VStack>
      ) : (
        <SimpleGrid columns={2} gap={4}>
          <Box>
            <Text fontWeight={500} fontSize="0.875rem">
              Country
            </Text>
            <Text fontWeight={400} fontSize="0.875rem">
              {registration.country_code}
            </Text>
          </Box>
          <Box>
            <Text fontWeight={500} fontSize="0.875rem">
              Jurisdiction
            </Text>
            <Text fontWeight={400} fontSize="0.875rem">
              {registration.state_name}
            </Text>
          </Box>
          <Box>
            <Text fontWeight={500} fontSize="0.875rem">
              Registration Date
            </Text>
            <Text fontWeight={400} fontSize="0.875rem">
              {registration.registration_date}
            </Text>
          </Box>
          <Box>
            <Text fontWeight={500} fontSize="0.875rem">
              Registration Email
            </Text>
            <Text fontWeight={400} fontSize="0.875rem">
              {registration.registration_email}
            </Text>
          </Box>
          <Box>
            <Text fontWeight={500} fontSize="0.875rem">
              Jurisdiction Login Username
            </Text>
            <Text fontWeight={400} fontSize="0.875rem">
              N/A
            </Text>
          </Box>
          <Box>
            <Text fontWeight={500} fontSize="0.875rem">
              Filling Frequency
            </Text>
            <Text fontWeight={400} fontSize="0.875rem">
              {getFilingFreq(registration.filing_frequency)}
            </Text>
          </Box>
        </SimpleGrid>
      )}
    </ModalPopup>
  );
};

export default RegistrationDetails;
