import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const transactionsAdapter = createEntityAdapter<any>();

const initialState = transactionsAdapter.getInitialState({
  loading: false,
  search_query: '',
  activePage: {
    pages: 0,
  },
  filter: {
    query: {},
    resultIds: [],
  },
  order_by: {
    order_by: '-date,state',
  },
});

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: initialState,
  reducers: {
    storeFilter(state, action): any {
      state.filter.query = action.payload;
    },
    storeOrder(state, action): any {
      state.order_by = action.payload;
    },
    storeQuery(state, action): void {
      state.search_query = action.payload;
    },
  },
});

export const { storeFilter, storeOrder, storeQuery } = transactionsSlice.actions;
export default transactionsSlice.reducer;
