import { Button, Icon, Text, useDisclosure } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { createApiDeckConnection, updateApiDeckConnections } from 'apis/connections';
import { getOrgs } from 'apis/organizations-apis';
import { useAppLoaderWrapper } from 'app/app-loader-wrapper';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import KSearchInput from 'components/SearchInput';
import { storeJWT } from 'cookie-handler';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';
import { Organization, setOrg, useOrg } from 'hooks/useOrg';
import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { MdOutlineSyncAlt } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { storeFilter as storeAddressFilter } from 'redux/slices/addresses';
import { storeFilter as storeCustomerFilter } from 'redux/slices/customers';
import { storeFilter as storeExemptionFilter } from 'redux/slices/exemptions';
import { storeFilter as storeProductFilter } from 'redux/slices/products';
import { storeFilter as storeTransactionFilter } from 'redux/slices/transactions';
import { markKintsugiAppAsInstalled } from 'utils/rippling-oauth';

import { OrganizationSelectorModalBody } from './org-selector-modal-body';

type OrganizationSelectorV2Props = {
  isExpanded: boolean;
};

export const OrganizationSelector = ({ isExpanded }: OrganizationSelectorV2Props) => {
  const { orgId, externalId, name } = useOrg();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tokens, userClass, isImpersonating } = useAuthInfo();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showLoader, hideLoader } = useAppLoaderWrapper();

  const [{ page, size }, setPagination] = useState<{ page: number; size: number }>({
    page: 1,
    size: 10,
  });

  const [query, setQuery] = useState('');
  const { handleFailNotification } = useHandleNotification();

  useHotkeys('ctrl+o', () => onOpen(), [isOpen]);

  const switchOrg = searchParams.get('switchOrg');
  const archiveOrgId = searchParams.get('archiveOrg');
  const serviceId = searchParams.get('service_id');
  const code = searchParams.get('code');
  const redirectUri = searchParams.get('redirect_uri');

  //for shopify
  const switchOrgForShopifyURL = searchParams.get('shop');
  const shopId = switchOrgForShopifyURL?.split('.')[0] || '';

  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['orgs', page, size, query],
    queryFn: async () => {
      try {
        return await getOrgs(orgId, page, size, query);
      } catch (error) {
        return { data: { items: [], pages: 1, total: 0 } };
      }
    },
    select: data => {
      return {
        items: data.data.items.filter((org: any) => org.external_id !== archiveOrgId),
        pages: data.data.pages,
        total: data.data.total,
      };
    },
    enabled: !!orgId, // should be the last resort for modification
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
  const organizations = data?.items || [];
  const pages = data?.pages || 1;

  const renderButton = orgId ? (
    <Text noOfLines={1} color="#fff" title={name}>
      {name}
    </Text>
  ) : (
    'Select Organization'
  );

  const handleClearFilters = () => {
    dispatch(storeAddressFilter({}));
    dispatch(storeCustomerFilter({}));
    dispatch(storeExemptionFilter({}));
    dispatch(storeProductFilter({}));
    dispatch(storeTransactionFilter({}));
  };

  const setAccessToken = async (externalId: string) => {
    const { accessToken, error } = await tokens.getAccessTokenForOrg(externalId);
    if (accessToken) {
      storeJWT(accessToken);
    } else {
      console.error('[ACCESS_TOKEN] error getting access token:', error);
      handleFailNotification({
        message: 'Failed to get access token for the organization.',
      });
    }
  };

  const handleRedirection = async (orgId: string) => {
    try {
      if (serviceId === 'rippling' && code && redirectUri) {
        await handleHrisRedirection(orgId);
      } else if (switchOrgForShopifyURL && shopId) {
        navigateToShopify();
      } else {
        handleFallbackNavigation();
      }
    } catch (error) {
      console.error('Error during redirection:', error);
      handleFailNotification({
        message: `An error occurred during redirection.`,
      });
    }
  };

  const handleHrisRedirection = async (orgId: string) => {
    if (!code || !serviceId || !redirectUri) {
      return;
    }
    showLoader();
    const apideck = await createApiDeckConnection(orgId, serviceId, {});
    if (apideck.data) {
      await markKintsugiAppAsInstalled(code);
      await updateApiDeckConnections(apideck.data.connection_id, orgId, 'ACTIVE');
      window.location.href = decodeURIComponent(redirectUri);
    } else {
      handleFailNotification({
        message: `Failed to connect to the ${serviceId} service.`,
      });
    }
  };

  const navigateToShopify = () => {
    const path = `/connect/shopify?shopURL=${switchOrgForShopifyURL}`;
    navigate(path);
  };

  const handleFallbackNavigation = () => {
    handleClearFilters(); // Temporary; will clean up when Redux is replaced
    navigate('/', { replace: true });
  };

  const handleOrgSwitch = async (selectedOrg: Organization) => {
    if (orgId === selectedOrg.orgId) {
      handleClose();
      await handleHrisRedirection(orgId);
      return;
    }
    handleClose();
    showLoader();
    const { accessToken, error } = await tokens.getAccessTokenForOrg(selectedOrg.externalId);
    if (accessToken) {
      storeJWT(accessToken);
      setOrg(selectedOrg);
      await handleRedirection(selectedOrg.orgId);
    } else {
      console.error('[SWITCH_ORG] error getting access token', error);
      handleFailNotification({
        message: 'Failed to get access token for the organization.',
      });
    }
    hideLoader();
  };

  const handlePagination = async (page: number, size: number) => {
    setPagination({ page, size });
    if (!orgId) {
      // Wait for the state change to take effect
      // Ref: https://github.com/TanStack/query/discussions/5720#discussioncomment-6467668
      await new Promise(r => setTimeout(r, 0));
      refetch();
    }
  };

  const handleAccessTokenAndOrganizationSwitching = async (orgExternalId: string) => {
    showLoader();
    try {
      await setAccessToken(orgExternalId);
      const { data, error } = await refetch();
      if (error) {
        console.error('An error occurred:', error);
        hideLoader();
        return;
      }
      if (archiveOrgId && data?.items.length === 0 && !orgId) {
        setSearchParams(params => {
          const newParams = new URLSearchParams(params);
          newParams.delete('archiveOrg');
          return newParams.toString();
        });
        navigate('/supports', {
          replace: true,
          state: {
            archiveOrgId,
          },
        });
      } else if (data?.items.length === 1 && !orgId) {
        const activeOrg = data?.items[0];
        handleOrgSwitch({
          orgId: activeOrg.id,
          externalId: activeOrg.external_id,
          name: activeOrg.name,
          isTest: activeOrg.is_test,
        });
      } else if (data?.items.length > 1 && !orgId) {
        onOpen();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      hideLoader();
    }
  };

  const handleClose = () => {
    setQuery('');
    setSearchParams({});
    setPagination({ page: 1, size: 10 });
    onClose();
    if (switchOrgForShopifyURL && shopId) {
      const path = `/connect/shopify?shopURL=${switchOrgForShopifyURL}`;
      navigate(path);
    }
  };

  useEffect(() => {
    const switchOrganization = (orgExternalId: string) => handleAccessTokenAndOrganizationSwitching(orgExternalId);

    const firstOrg = userClass?.getOrgs()[0];

    if (isImpersonating && firstOrg) {
      switchOrganization(firstOrg.orgId);
    } else if (orgId) {
      switchOrganization(externalId);
    } else if (archiveOrgId) {
      switchOrganization(archiveOrgId);
    } else if (firstOrg) {
      switchOrganization(firstOrg.orgId);
    }
  }, []);

  useDidUpdateEffect(() => {
    if (isImpersonating) {
      const selectedOrg = userClass?.getOrgs().find(org => org.orgId === externalId);
      if (selectedOrg) {
        setAccessToken(selectedOrg.orgId);
      }
    } else if (orgId) {
      setAccessToken(externalId);
    }
  }, [tokens]);

  useEffect(() => {
    if (!isOpen && (switchOrg || (switchOrgForShopifyURL && shopId) || serviceId)) {
      onOpen();
    }
  }, [switchOrg, switchOrgForShopifyURL, shopId, serviceId]);

  useEffect(() => {
    if (query && !orgId) {
      refetch(); //very first time login when orgId is null and query is not empty
    }
  }, [query]);

  const selectedOrg = organizations.find((org: { id: string }) => org.id === orgId);
  return (
    <>
      <Button
        isLoading={isLoading || isRefetching}
        variant="nav"
        role="button"
        cursor="pointer"
        onClick={() => {
          if (!selectedOrg) {
            refetch();
          }
          onOpen();
        }}
        border={'1px'}
        borderColor={'#333854'}
        px={'8px'}
        rightIcon={<Icon ml={'5px'} fontSize={'16px'} as={MdOutlineSyncAlt} />}
        justifyContent="space-between"
        style={isExpanded ? {} : { width: 'unset' }}
      >
        {isExpanded ? renderButton : null}
      </Button>
      {isOpen && (
        <ModalPopup
          closeOnOverlayClick={false}
          isCentered
          header={'Select Organization to Connect'}
          isOpen={isOpen}
          onClose={handleClose}
          blockScrollOnMount={false}
          scrollBehavior="inside"
          size="lg"
        >
          {(data?.total >= 10 || !!query) && <KSearchInput query={query} setQuery={setQuery} />}

          <OrganizationSelectorModalBody
            isLoading={isLoading || isRefetching}
            orgId={orgId}
            query={query}
            organizations={organizations}
            onOrgSwitch={handleOrgSwitch}
            pagination={{ pages, page, size, onPaginate: handlePagination }}
          />
        </ModalPopup>
      )}
    </>
  );
};
