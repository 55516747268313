import { defineStyleConfig } from '@chakra-ui/react';

export const ProgressStyles = defineStyleConfig({
  baseStyle: {
    filledTrack: {
      bg: '#A0A4B2',
    },
  },
  variants: {
    nexus_met: {
      filledTrack: {
        bg: '#FC8181',
      },
    },
    upload_status: {
      filledTrack: {
        bg: '#4285F4',
        borderRadius: '90px',
      },
    },
  },
});
