import { boolean, object, string } from 'yup';

export type ProductStatus = 'APPROVED' | 'PARTIALLY_APPROVED' | 'PENDING';

export enum ProductCategoryEnum {
  PHYSICAL = 'PHYSICAL',
  DIGITAL = 'DIGITAL',
  SERVICE = 'SERVICE',
  UNKNOWN = 'UNKNOWN',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export enum ProductSubCategoryEnum {
  UNKNOWN = 'UNKNOWN',
  SAAS = 'SAAS',
  DIGITAL_GENERAL = 'DIGITAL_GENERAL',
  B2B_SAAS = 'B2B_SAAS',
  SOFTWARE_ON_PERSONAL_PROPERTY = 'SOFTWARE_ON_PERSONAL_PROPERTY',
  SOFTWARE_DOWNLOADED = 'SOFTWARE_DOWNLOADED',
  CUSTOM_SOFTWARE_ON_PERSONAL_PROPERTY = 'CUSTOM_SOFTWARE_ON_PERSONAL_PROPERTY',
  CUSTOM_SOFTWARE_DOWNLOADED = 'CUSTOM_SOFTWARE_DOWNLOADED',
  CUSTOMIZATION_OF_SOFTWARE = 'CUSTOMIZATION_OF_SOFTWARE',
  B2C_SAAS = 'B2C_SAAS',
  SERVICE_GENERAL = 'SERVICE_GENERAL',
  PROFESSIONAL_SERVICE = 'PROFESSIONAL_SERVICE',
  TANGIBLE_PROPERTY_SERVICE = 'TANGIBLE_PROPERTY_SERVICE',
  REAL_PROPERTY_SERVICE = 'REAL_PROPERTY_SERVICE',
  BUSINESS_SERVICE = 'BUSINESS_SERVICE',
  PERSONAL_SERVICE = 'PERSONAL_SERVICE',
  AMUSEMENT_SERVICE = 'AMUSEMENT_SERVICE',
  MEDICAL_SERVICES = 'MEDICAL_SERVICES',
  PHYSICAL_GENERAL = 'PHYSICAL_GENERAL',
  GENERAL_CLOTHING = 'GENERAL_CLOTHING',
  CATERING = 'CATERING',
  GROCERY_FOOD = 'GROCERY_FOOD',
  LEASES_AND_RENTALS_MOTOR_VEHICLES = 'LEASES_AND_RENTALS_MOTOR_VEHICLES',
  LEASES_AND_RENTALS_TANGIBLE_MEDIA_PROPERTY = 'LEASES_AND_RENTALS_TANGIBLE_MEDIA_PROPERTY',
  MACHINERY = 'MACHINERY',
  RAW_MATERIALS = 'RAW_MATERIALS',
  UTILITIES_FUEL = 'UTILITIES_FUEL',
  MEDICAL_DEVICES = 'MEDICAL_DEVICES',
  MEDICINES = 'MEDICINES',
  NEWSPAPERS = 'NEWSPAPERS',
  PERIODICALS = 'PERIODICALS',
  GENERAL_OCCASIONAL_SALES = 'GENERAL_OCCASIONAL_SALES',
  MOTOR_VEHICLES_OCCASIONAL_SALES = 'MOTOR_VEHICLES_OCCASIONAL_SALES',
  GENERAL_OPTIONAL_MAINTENANCE_CONTRACTS = 'GENERAL_OPTIONAL_MAINTENANCE_CONTRACTS',
  PARTS_PURCHASED_OPTIONAL_MAINTENANCE_CONTRACTS = 'PARTS_PURCHASED_OPTIONAL_MAINTENANCE_CONTRACTS',
  GENERAL_POLLUTION_CONTROL_EQUIPMENT = 'GENERAL_POLLUTION_CONTROL_EQUIPMENT',
  GENERAL_TRADE_INS = 'GENERAL_TRADE_INS',
  FOOD_VENDING_MACHINE = 'FOOD_VENDING_MACHINE',
  MERCHANDISE_VENDING_MACHINE = 'MERCHANDISE_VENDING_MACHINE',
  SUPPLEMENTS = 'SUPPLEMENTS',
  SHIPPING = 'SHIPPING',
  GIFT_CARD = 'GIFT_CARD',
  CREDIT_CARD_SURCHARGES = 'CREDIT_CARD_SURCHARGES',
  CREDIT_CARD_FEES = 'CREDIT_CARD_FEES',
  MISCELLANEOUS_EXEMPT = 'MISCELLANEOUS_EXEMPT',
  DISCOUNT = 'DISCOUNT',
}

export type ProductStatusVariant = {
  variant: 'approved' | 'pending' | 'partial';
  title: string;
};

export const ProductValidationSchema = object().shape({
  external_id: string().required('ID is required'),
  name: string().required('Name is required'),
  product_category: string()
    .required('Category is required')
    .matches(/^(?!UNKNOWN).*$/g, 'Category can not be UNKNOWN'),
  product_subcategory: string()
    .required('Subcategory is required')
    .matches(/^(?!UNKNOWN).*$/g, 'Subcategory can not be UNKNOWN'),
  tax_exempt: boolean().optional(),
});

export const UpdateProductValidationSchema = object().shape({
  external_id: string().optional(),
  name: string().required('Name is required'),
  product_category: string()
    .required('Category is required')
    .matches(/^(?!UNKNOWN).*$/g, 'Category can not be UNKNOWN'),
  product_subcategory: string()
    .required('Subcategory is required')
    .matches(/^(?!UNKNOWN).*$/g, 'Subcategory can not be UNKNOWN'),
  tax_exempt: boolean().optional(),
});

/**
 * ProductSubCategoryRead
 * @property {string} name
 * @property {string} description
 * @property {string} example
 */
export type ProductSubCategoryRead = {
  name: ProductSubCategoryEnum;
  description: string;
  example: string;
};

export type ProductSubCategoryReadWithTitle = ProductSubCategoryRead & { title: string };

/**
 * ProductCategoryRead
 * @property {string} name
 * @property {string} description
 * @property {ProductSubCategoryRead[]} subcategories
 */
export type ProductCategoryRead = {
  name: ProductCategoryEnum;
  description: string;
  subcategories: ProductSubCategoryRead[];
};

export type ProductCategoryReadWithTitle = ProductCategoryRead & { title: string };
