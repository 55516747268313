import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({});

export const cardTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    elevated: {
      container: {
        border: '1px',
        borderRadius: '8px',
        color: '#EFEFF3',
        boxShadow: 'none',
      },
    },
  },
  sizes: {
    md: {
      container: {
        padding: '16px',
      },
    },
  },
});
