import { UseToastOptions } from '@chakra-ui/toast';
import useToast from 'component-library/hooks/useToast';
import React, { createContext, ReactElement, ReactNode, useContext } from 'react';

type NotificationProviderType = {
  notify: ({ type, title, message, duration, options }: notifyType) => void;
};

type notifyType = {
  type: 'success' | 'error' | 'warning' | 'info';
  title?: string;
  message: string;
  duration?: number;
  options?: UseToastOptions;
};

const defaultContext = {} as NotificationProviderType;

const NotificationContext = createContext<NotificationProviderType>(defaultContext);

export const useNotification = (): NotificationProviderType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within an ErrorProvider');
  }
  return context;
};

export const NotificationProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const toast = useToast();

  const notify = ({ type, title = '', message, duration = 5000, options }: notifyType) => {
    toast({
      title,
      description: message,
      status: type,
      duration,
      isClosable: true,
      ...options,
    });
  };

  return <NotificationContext.Provider value={{ notify }}>{children}</NotificationContext.Provider>;
};
