import { Box, BoxProps } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';

interface TableContainerProps extends BoxProps {
  children?: React.ReactNode;
  isStickyHeader?: boolean;
}

const StyledTableContainer = styled(Box, { shouldForwardProp: prop => !['isStickyHeader'].includes(prop) })(
  ({ isStickyHeader }: { isStickyHeader: boolean }) => ({
    ...(isStickyHeader && {
      '& thead th': {
        position: 'sticky',
        top: '-1px',
        backgroundColor: '#fff',
        zIndex: 1,
      },
    }),
  })
);

const TableContainer: React.FC<TableContainerProps> = ({ children, isStickyHeader = true, ...rest }) => {
  const defaultConfig: BoxProps = {
    border: '1px solid var(--gray-50, #EFEFF3)',
    borderRadius: '4px',
    px: '12px',
    overflowX: { sm: 'scroll', xl: 'hidden' },
    ...(isStickyHeader && {
      height: '100%',
    }),
  };

  return (
    <StyledTableContainer {...defaultConfig} isStickyHeader={isStickyHeader} {...rest}>
      {children}
    </StyledTableContainer>
  );
};

export default TableContainer;
