import api from './api';

const askTaxGpt = (orgId: string, question: string): Promise<any> => {
  return api.get(`/v1/taxgpt`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      text: question,
    },
  });
};

export { askTaxGpt };
