import { useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getDashboardOnboardingStepStatus, ONBOARDING_STATE_KEY } from 'apis/dashboard-apis';
import { usePaywall } from 'app/acl/paywall';
import Button from 'component-library/components/ButtonTmp/button';
import KSTooltip from 'component-library/components/Tooltiptmp/tooltip';
import { useOrg } from 'hooks/useOrg';
import { OnBoardingStepStatus } from 'types/onboarding';

import { OrgDetailPrompt } from './orgdetail-prompt';
import { RegistrationForm } from './registration-form';

export const CreateRegistration = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { orgId } = useOrg();
  const { isPaidUser } = usePaywall();

  const { data, isPending } = useQuery({
    queryKey: [ONBOARDING_STATE_KEY, 'steps', 'status', orgId],
    queryFn: async () => {
      const response = await getDashboardOnboardingStepStatus(orgId);
      return response.data as OnBoardingStepStatus;
    },
  });

  return (
    <>
      <KSTooltip
        placement="bottom-start"
        label={'Update your billing plan to enable import registration.'}
        isDisabled={isPaidUser}
      >
        <Button
          isDisabled={!isPaidUser}
          isLoading={isPending}
          variant={'solid'}
          color={'primary'}
          width={'100px'}
          onClick={onOpen}
        >
          Import
        </Button>
      </KSTooltip>
      {!isPending &&
        (data?.onboarding_steps_status ? (
          <RegistrationForm isOpen={isOpen} onClose={onClose} />
        ) : (
          <OrgDetailPrompt isOpen={isOpen} onClose={onClose} data={data} />
        ))}
    </>
  );
};
