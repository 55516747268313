import { FormLabel, FormLabelProps } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

const KS_FormLabel = ({ children, ...rest }: FormLabelProps, ref: any) => {
  return (
    <FormLabel ref={ref} {...rest}>
      {children}
    </FormLabel>
  );
};

export default forwardRef(KS_FormLabel);
