import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getInvalidAddresses } from 'apis/addressses';
import { FilingInstance } from 'types/shared-types';

const addressessAdapter = createEntityAdapter<FilingInstance>();

const initialState = addressessAdapter.getInitialState({
  loading: false,
  activePage: {
    pages: 0,
  },
  filter: {
    query: {},
    resultIds: [],
  },
});

export const fetchFilings = createAsyncThunk('addresses/fetchAddresses', async (params: any) => {
  const response = await getInvalidAddresses(params.orgId, params.page, params.size, params.filter);
  return response?.data;
});

const addressessSlice = createSlice({
  name: 'addresses',
  initialState: initialState,
  reducers: {
    storeFilter(state, action): any {
      state.filter.query = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchFilings.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (Array.isArray(payload.items)) {
        addressessAdapter.upsertMany(state, payload.items);
        state.activePage = { ...payload };
      }
    });
  },
});

export const { storeFilter } = addressessSlice.actions;
export default addressessSlice.reducer;
