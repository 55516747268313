import { isEmpty } from 'lodash';
import { RegistrationInstance, RegistrationStatus } from 'types/shared-types';

import api from './api';

const getRegistrations = (orgId: string, page: number, size: number) => {
  return api.get(`/v1/registrations`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      page: page,
      size: size,
    },
  });
};

const getRegistrationForId = (orgId: string, registrationId: string) => {
  return api.get(`/v1/registrations/${registrationId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateRegistrationApi = (registration_id: string, orgId: string, payload: Partial<RegistrationInstance>) => {
  return api.put(`/v1/registrations/${registration_id}`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};
const deRegisterApi = (orgId: string, registrationId: string) => {
  return api.put(
    `/v1/registrations/${registrationId}`,
    { status: RegistrationStatus.DEREGISTERING },
    {
      headers: {
        'x-organization-id': orgId,
      },
    }
  );
};

const createRegistrationApi = (orgId: string, payload: any) => {
  return api.post(`/v1/registrations`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const sendRegistrationPassword = (orgId: string, registration_id: string) => {
  return api.post(`/v1/registrations/${registration_id}/send_password`, '', {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      organization_id: orgId,
    },
  });
};

const getAllRegistrations = async ({
  orgId,
  size = 100,
  filter = {},
}: {
  orgId: string;
  size?: number;
  filter?: {
    status?: RegistrationStatus[];
  };
}) => {
  let totalPages = 1;
  let results: RegistrationInstance[] = [];
  let page = 1;

  while (page <= totalPages) {
    const params = isEmpty(filter)
      ? {
          page: page,
          size: size,
        }
      : {
          page: page,
          size: size,
          status__in: filter.status?.join(','),
        };
    try {
      const { data } = await api.get(`/v1/registrations`, {
        headers: {
          'x-organization-id': orgId,
        },
        params: {
          ...params,
        },
      });
      results = [...results, ...data.items];
      totalPages = data.pages;
      page++;
    } catch (error) {
      break; // Terminate the loop on error
    }
  }
  return results;
};

const REGISTRATION_STATE_KEY = 'registration';

export {
  createRegistrationApi,
  deRegisterApi,
  getAllRegistrations,
  getRegistrationForId,
  getRegistrations,
  REGISTRATION_STATE_KEY,
  sendRegistrationPassword,
  updateRegistrationApi,
};
