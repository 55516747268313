import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { deletePhysicalNexus, getPhysicalNexus } from 'apis/physical-nexus-apis';
import { PhysicalNexusInstance } from 'types/shared-types';

const physicalNexusAdapter = createEntityAdapter<PhysicalNexusInstance>();

const initialState = physicalNexusAdapter.getInitialState({
  loading: false,
  activePage: {
    pages: 0,
  },
});

export const getPhysicalNexusApi = createAsyncThunk('orgs/getPhysicalNexus', async (params: any) => {
  const response = await getPhysicalNexus(params.orgId, params.page, params.size);
  return response.data;
});

export const deletePhysicalNexusApi = createAsyncThunk('orgs/deletePhysicalNexus', async (params: any) => {
  const response = await deletePhysicalNexus(params.orgId, params.physicalNexusId);
  return response.data;
});

const physicalNexus = createSlice({
  name: 'physicalNexus',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPhysicalNexusApi.fulfilled, (state, { payload }) => {
        if (payload) {
          physicalNexusAdapter.upsertMany(state, payload);
        }
      })
      .addCase(deletePhysicalNexusApi.fulfilled, (state, { meta }) => {
        if (meta.arg) {
          physicalNexusAdapter.removeOne(state, meta.arg.physicalNexusId);
        }
      });
  },
});

export default physicalNexus.reducer;
