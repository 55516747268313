import { Flex, List, ListItem, Text } from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import { CheckCircle } from 'components/icons';
import { useNavigate } from 'react-router-dom';
import { OnBoardingStepStatus } from 'types/onboarding';

type OrgDetailPromptProps = {
  isOpen: boolean;
  onClose: () => void;
  data?: OnBoardingStepStatus;
};

export const OrgDetailPrompt = ({ isOpen, onClose, data }: OrgDetailPromptProps) => {
  const navigate = useNavigate();

  const { physical_nexus_status, organization_details_status, bank_details_status } = data || {
    physical_nexus_status: false,
    organization_details_status: false,
    bank_details_status: false,
  };

  const started = organization_details_status || physical_nexus_status || bank_details_status;

  const handleContinue = () => {
    if (!organization_details_status) {
      navigate('/configurations/organization-setup');
    } else if (!bank_details_status) {
      navigate('/configurations/bank-details');
    } else {
      navigate('/configurations');
    }
    onClose();
  };
  return (
    <ModalPopup
      size={'lg'}
      isOpen={isOpen}
      onClose={onClose}
      header={started ? 'Please provide additional details for registration' : 'Required information unfilled'}
      footer={
        <Flex gap={2}>
          <Button variant={'outline'} color={'secondary'} onClick={onClose}>
            Cancel
          </Button>
          <Button variant={'solid'} color={'primary'} width={'90px'} onClick={handleContinue}>
            Continue
          </Button>
        </Flex>
      }
    >
      {started ? (
        <List spacing={'5px'}>
          <ListItem style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <CheckCircle fill={organization_details_status ? '#38A169' : '#CFD0D8'} />
            <Text>Organization Details</Text>
          </ListItem>
          <ListItem style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <CheckCircle fill={physical_nexus_status ? '#38A169' : '#CFD0D8'} />
            <Text>Physical Nexus</Text>
          </ListItem>
          <ListItem style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <CheckCircle fill={bank_details_status ? '#38A169' : '#CFD0D8'} />
            <Text>Bank Details</Text>
          </ListItem>
        </List>
      ) : (
        <Text>
          To register, please fill in &apos;Organization Setup,&apos; &apos;Bank Details,&apos; and &apos;Billing
          Details&apos; under &apos;Settings.&apos;
        </Text>
      )}
    </ModalPopup>
  );
};
