import { useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { UseTableFilters } from '../types/shared-types';

export const useTableFilters = (
  {
    page: initialActivePage = 1,
    size: initialPageSize = 25,
    status__in: statusIn,
    start_date: startDate,
    end_date: endDate,
    date_filed__gte: dateFiledGte,
    date_filed__lte: dateFiledLte,
    order_by: orderBy,
    state_code: stateCode,
    country_code: countryCode,
    state_name: stateName,
  }: UseTableFilters = { page: 1, size: 1 }
) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // page
  const pageParam = searchParams.get('page');
  const parsedPage = pageParam ? parseInt(pageParam) : initialActivePage;
  const validPage = isNaN(parsedPage) ? initialActivePage : parsedPage;

  // size
  const sizeParam = searchParams.get('size');
  const parsedSize = sizeParam ? parseInt(sizeParam) : initialPageSize;
  const validSize = isNaN(parsedSize) ? initialPageSize : parsedSize;

  // status__in
  const statusInParam = searchParams.get('status__in');
  const statusInString = Array.isArray(statusIn) ? statusIn.join(',') : undefined;
  const validStatusIn = statusInParam ? statusInParam : statusInString;

  // start_date
  const startDateParam = searchParams.get('start_date');
  const validStartDate = startDateParam ? startDateParam : startDate;

  // end_date
  const endDateParam = searchParams.get('end_date');
  const validEndDate = endDateParam ? endDateParam : endDate;

  // date_filed__gte
  const dateFiledGteParam = searchParams.get('date_filed__gte');
  const validDateFiledGte = dateFiledGteParam ? dateFiledGteParam : dateFiledGte;

  // date_filed__lte
  const dateFiledLteParam = searchParams.get('date_filed__lte');
  const validDateFiledLte = dateFiledLteParam ? dateFiledLteParam : dateFiledLte;

  // order_by
  const orderByParam = searchParams.get('order_by');
  const orderByString = Array.isArray(orderBy) ? orderBy.join(',') : undefined;
  const validOrderBy = orderByParam ? orderByParam : orderByString;

  // state_code
  const stateCodeParam = searchParams.get('state_code');
  const validStateCode = stateCodeParam ? stateCodeParam : stateCode;

  // country_code
  const countryCodeParam = searchParams.get('country_code');
  const validCountryCode = countryCodeParam ? countryCodeParam : countryCode;

  // state_name
  const stateNameParam = searchParams.get('state_name');
  const validStateName = stateNameParam ? stateNameParam : stateName;

  const setFilters = useCallback(
    (
      {
        page = 1,
        size = 25,
        status__in,
        start_date,
        end_date,
        date_filed__gte,
        date_filed__lte,
        order_by,
        state_code,
        country_code,
        state_name,
      }: UseTableFilters = { page: 1, size: 25 }
    ) => {
      setSearchParams(params => {
        const setPageAndSize = (pageString: string, sizeString: string) => {
          params.set('page', pageString);
          params.set('size', sizeString);
        };

        setPageAndSize(page.toString(), size.toString());

        if (status__in && Array.isArray(status__in)) {
          params.set('status__in', status__in.join(','));
          setPageAndSize('1', '25');
        } else {
          params.delete('status__in');
        }

        if (start_date) {
          params.set('start_date', start_date);
          setPageAndSize('1', '25');
        } else {
          params.delete('start_date');
        }

        if (end_date) {
          params.set('end_date', end_date);
          setPageAndSize('1', '25');
        } else {
          params.delete('end_date');
        }

        if (date_filed__gte) {
          params.set('date_filed__gte', date_filed__gte);
          setPageAndSize('1', '25');
        } else {
          params.delete('date_filed__gte');
        }

        if (date_filed__lte) {
          params.set('date_filed__lte', date_filed__lte);
          setPageAndSize('1', '25');
        } else {
          params.delete('date_filed__lte');
        }

        if (order_by && Array.isArray(order_by)) {
          params.set('order_by', order_by.join(','));
          setPageAndSize('1', '25');
        } else {
          params.delete('order_by');
        }

        if (state_code) {
          params.set('state_code', state_code);
          setPageAndSize('1', '25');
        } else {
          params.delete('state_code');
        }

        if (country_code) {
          params.set('country_code', country_code);
          setPageAndSize('1', '25');
        } else {
          params.delete('country_code');
        }

        if (state_name) {
          params.set('state_name', state_name);
          setPageAndSize('1', '25');
        } else {
          params.delete('state_name');
        }

        return params;
      });
    },
    [location]
  );

  const resetFilters = useCallback(() => {
    setSearchParams(params => {
      params.delete('page');
      params.delete('size');
      params.delete('status__in');
      params.delete('start_date');
      params.delete('end_date');
      params.delete('date_filed__gte');
      params.delete('date_filed__lte');
      params.delete('order_by');
      params.delete('state_code');
      params.delete('country_code');
      params.delete('state_name');
      return params;
    });
  }, [location]);

  return {
    page: validPage,
    size: validSize,
    status__in: validStatusIn,
    start_date: validStartDate,
    end_date: validEndDate,
    date_filed__gte: validDateFiledGte,
    date_filed__lte: validDateFiledLte,
    order_by: validOrderBy,
    state_code: validStateCode,
    country_code: validCountryCode,
    state_name: validStateName,
    setFilters,
    resetFilters,
  };
};

export type UseTableFiltersType = ReturnType<typeof useTableFilters>;
