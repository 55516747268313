import { askTaxGpt } from 'apis/tax-gpt';
import { useOrg } from 'hooks/useOrg';
import React from 'react';
import { createCustomMessage } from 'react-chatbot-kit';
import { IMessage } from 'react-chatbot-kit/build/src/interfaces/IMessages';

import Loader from './components/bot-msg-loader';

// library is not well typed so need to create a type for it
type ChatActionHandlerTypes = {
  createChatBotMessage: (p: any, b?: any) => void;
  setState: (p: any) => void;
  children: any;
};

const ChatActionHandler = ({ createChatBotMessage, setState, children }: ChatActionHandlerTypes) => {
  const { orgId } = useOrg();
  const handleQuestion = async (question: string) => {
    setState((prev: { messages: IMessage[] }) => ({
      messages: [...prev.messages, createChatBotMessage(<Loader />)],
    }));

    const inputRef = document.getElementsByClassName('react-chatbot-kit-chat-input')[0];
    const sendBtn = document.getElementsByClassName('react-chatbot-kit-chat-btn-send')[0];
    try {
      inputRef.classList.add('disabled');
      sendBtn.classList.add('disabled');
      const response = await askTaxGpt(orgId, question);
      if (typeof response?.data === 'string') {
        const botMessage = createCustomMessage(response.data, 'answer', {
          loading: false,
          delay: 0,
          payload: {
            message: response.data,
            type: 'answer',
            id: 'answer',
          },
        });

        // TO DO: use 'typed.js' for type effect
        setState((prev: { messages: IMessage[] }) => ({
          messages: [...prev.messages.slice(0, -1), botMessage],
        }));
      } else {
        const botMessage = createChatBotMessage('Chat bot failed to respond, Please try again Or contact support', {
          withAvatar: true,
          delay: 0,
        });

        setState((prev: { messages: IMessage[] }) => ({
          messages: [...prev.messages.slice(0, -1), botMessage],
        }));
      }
    } catch (err) {
      const botMessage = createChatBotMessage('Chat bot failed to respond, Please try again Or contact support', {
        withAvatar: true,
        delay: 0,
      });

      setState((prev: { messages: IMessage[] }) => ({
        messages: [...prev.messages.slice(0, -1), botMessage],
      }));
    }
    inputRef.classList.remove('disabled');
    sendBtn.classList.remove('disabled');
  };

  const handleEmptyMessage = () => {
    setState((prev: any) => ({
      messages: [
        ...prev.messages,
        createChatBotMessage('Type something to start the conversation', { withAvatar: true, delay: 0 }),
      ],
    }));
  };

  return React.Children.map(children, child => {
    return React.cloneElement(child, {
      actions: {
        handleQuestion,
        handleEmptyMessage,
      },
    });
  });
};

export default ChatActionHandler;
